export const appRelease = {
    customReleasOrgDtls: {},
    interReleaseLoad: false,
    orgDetailsSearchLoad: false,
};

export const appReleaseReducer = (state = appRelease, action = {}) => {
    switch (action.type) {
        case 'DO_APP_RELEASE_INITIAL': {
            return {
                ...state,
                ...appRelease,
            };
        }
        case 'DO_INTERNAL_RELEASE_LOAD': {
            return {
                ...state,
                interReleaseLoad: action.interReleaseLoad
            };
        }
        case 'DO_SEARCH_ORG_DETAILS_LOAD': {
            return {
                ...state,
                orgDetailsSearchLoad: action.orgDetailsSearchLoad
            };
        }
        case 'DO_STORE_CUTOM_RELEASE_ORG_DETAILS': {
            return {
                ...state,
                customReleasOrgDtls: action.customReleasOrgDtls
            };
        }
        default:
            return {
                ...state
            };
    }
};
