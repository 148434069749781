export const initAddCus = {
    addcusIsload: false,
};

export const addCusReducer = (state = initAddCus, action = {}) => {
    switch (action.type) {
        case 'DO_ADD_CUS_INITIAL': {
            return {
                ...state,
                ...initAddCus,
            };
        }
        case 'DO_LOADING_PROFILE_IMAGE': {
            return {
                ...state,
                addcusIsload: action.loading
            };
        }
        default:
            return {
                ...state
            };
    }
};
