import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { IconDropdownDark } from "../../assets/images";
import { ALL_DATE_FILTER_VALUE, BILLING_CYCLE_TEXT, BILLING_CYCLE_VALUE, months as monthList } from "../../const/Constants";
import { DecryptLogInDetails } from "../../helper/Encypt";
import { isPaidPlan } from "../../components/MainContent/Dashboard/dashBordCommon";

const DayToYearDropDownListdatas = (props = {}) => {
  const cusDate = "Custom date";
  const defaultDate = "Last 30 days";
  const {
    DropDown = false,
    overflow = true,
    SelectedValue = "",
    enableMonthPicker = false,
    _handleSelect = () => { },
    _handleMonthSelect = () => { },
    planTypeId = 0
  } = props;
  const userDetails = DecryptLogInDetails() || {};

  const { t } = useTranslation();
  const [months, setMonths] = useState([]);
  const currentMonth = new Date().getMonth() + 1; // Current month
  const location = document?.location?.pathname || ''; // Get current location path

  useEffect(() => {
    const monthLists = monthList.map((month) => {
      month.enable = currentMonth < month.id ? false : true; /** Disable future months */
      month.active = SelectedValue === month.name ? true : false; /** Show active month */
      return month;
    });
    setMonths(monthLists);
  }, [SelectedValue]);

  return (
    <CSSTransition
      in={DropDown}
      timeout={300}
      classNames="fadeInUp"
      unmountOnExit
    >
      <div style={{ overflow: !overflow ? " visible " : " " }}>
        {enableMonthPicker ?
          <ul>
            <li
              value="1"
              id={"JestDropValuePick"}
              className={` ${SelectedValue === "This month" ? " active " : " "}`}
              data-value="This month"
              onClick={(event) => _handleSelect(event)}
            >
              {t("This month")}
            </li>
            <li
              value="2"
              id={"JestDropValuePick"}
              className={` ${SelectedValue === "Last month" ? " active " : " "}`}
              data-value="Last month"
              onClick={(event) => _handleSelect(event)}
            >
              {t("Last month")}
            </li>
            <li
              value="3"
              data-value={cusDate}
              id={"JestDropValuePick"}
              onClick={(event) => _handleSelect(event)}
            >
              {t("TABLE.CUSTOM_DAY")}
            </li>
          </ul>
          :
          <ul>
            {(userDetails?.data?.userRoleId === 1 && location !== '/payments' && location !== '/profile' && location !== '/call-usage') ?
              <li
                value={ALL_DATE_FILTER_VALUE}
                className={`${parseInt(SelectedValue) === ALL_DATE_FILTER_VALUE ? " active " : " "}`}
                data-value={ALL_DATE_FILTER_VALUE}
                id={"JestDropValuePick"}
                onClick={(event) => _handleSelect(event)}
              >
                All
              </li> : null
            }
            {(location !== '/call-usage-info') ?

              <li
                value="0"
                className={` ${SelectedValue === "Today" ? " active " : " "}`}
                data-value="Today"
                id={"JestDropValuePick"}
                onClick={(event) => _handleSelect(event)}
              >
                Today
              </li>
              : null}
            <li
              value="3"
              id={"JestDropValuePick"}
              className={` has_menu ${months.find((m) => m.active === true) ? " active " : " "}`}
            >
              {t("Monthly")}
              <i className="dropIcon"><IconDropdownDark /></i>
              <div className="hover_option">
                <div className="month_wraper">
                  {months.map((month) => (
                    <button key={month.id} onClick={month.enable ? () => _handleMonthSelect(month) : () => { }}
                      className={`${month.active ? 'active' : ''} ${!month.enable ? 'inactive' : ''}`}
                      type="button">{month.code || ''}</button>
                  ))}
                </div>
              </div>
            </li>

            {(location !== '/payments' && location !== '/call-usage' && location !== '/call-usage-info') ?
              <li
                value="1"
                id={"JestDropValuePick"}
                className={` ${SelectedValue === "Last 7 days" ? " active " : " "}`}
                data-value="Last 7 days"
                onClick={(event) => _handleSelect(event)}
              >
                {t("TABLE.SEVEN_DAY")}
              </li>
              : null}
            {(location !== '/call-usage-info') ?
              <li
                value="2"
                id={"JestDropValuePick"}
                className={` ${SelectedValue === "Last 30 days" ? " active " : " "}`}
                data-value={defaultDate}
                onClick={(event) => _handleSelect(event)}
              >
                {t("TABLE.THIRTY_DAY")}
              </li>
              : null}
            {(location !== '/call-usage-info') ?

              <li
                value="3"
                id={"JestDropValuePick"}
                className={` ${SelectedValue === "Last 90 days" ? " active " : " "}`}
                data-value="Last 90 days"
                onClick={(event) => _handleSelect(event)}
              >
                {t("TABLE.NINETY_DAY")}
              </li>
              : null}
            <li
              value="4"
              data-value={cusDate}
              className={` ${SelectedValue === "Custom date" ? " active " : " "}`}
              id={"JestDropValuePick"}
              onClick={(event) => _handleSelect(event)}
            >
              {t("TABLE.CUSTOM_DAY")}
            </li>

            {isPaidPlan(planTypeId) && (
              <li
                value={BILLING_CYCLE_VALUE}
                data-value={BILLING_CYCLE_VALUE}
                className={` ${SelectedValue === BILLING_CYCLE_TEXT ? " active " : " "}`}
                id={"JestDropValuePick"}
                onClick={(event) => _handleSelect(event)}
              >
                {BILLING_CYCLE_TEXT}
              </li>
            )}
          </ul>
        }
      </div>
    </CSSTransition>
  );
};
export default React.memo(DayToYearDropDownListdatas);
