import History from "../../common/History";
import { payMentEncrypt } from "../../helper/Encypt";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { failToast, serverNotRespond, } from "../../helper/ApiToast";
import * as stripePaymentTypes from '../actionTypes/stripePaymentTypes';
import { planAmtCalculation } from "../../components/MainContent/Settings/CustomerBillingHistory/CustomerBillingHistoryCommon";
import {
    paymentInitCallService,
    billingInitCallService,
} from '../../services/stripePaymentServices';

export function* paymentInitialCall(obj = {}) {
    try {
        const paymentInitResponse = yield call(paymentInitCallService, obj);
        const { data = {} } = paymentInitResponse || {};
        const { status = 0 } = data;
        payMentEncrypt(data);//store in session
        if (status === 200) {
            History.push({ pathname: "/payment-succeeded", state: { data: data } });
            yield all([
                put({ type: "DO_LOADING_PAYMENT_INITIAL_LOADER", stripeLoad: false }),//lodering on / off
            ]);
        } else {
            History.push({ pathname: "/payment-fail", state: { data: data } });
            yield all([
                put({ type: "DO_LOADING_PAYMENT_INITIAL_LOADER", stripeLoad: false }),//lodering on / off
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "DO_LOADING_PAYMENT_INITIAL_LOADER", stripeLoad: false }),//lodering on / off
        ]);
    }
}

const commonLoop = (datas = {}) => {
    const { data = {} } = datas;
    const { billinghistory = [] } = data;
    if (billinghistory) {
        const newArr = billinghistory.map((ele) => {
            ele.amt = planAmtCalculation(ele);
            return ele;
        });
        return {
            ...datas,
            data: {
                ...data,
                billinghistory: newArr
            }
        };
    }
    return datas;
};

export function* billingHistoryInitialSaga(obj = {}) {
    try {
        const billingHistoryResponse = yield call(billingInitCallService, obj);
        const { data = {} } = billingHistoryResponse || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            const dataResponse = commonLoop(data);
            yield all([
                put({ type: "DO_LOADING_BILLING_HISTORY_DATA", billingHistoryData: dataResponse }),//lodering on / off
                put({ type: "DO_LOADING_BILLING_HISTORY_LOAD", billingHistoryLoad: false }),//lodering on / off
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: "DO_LOADING_BILLING_HISTORY_LOAD", billingHistoryLoad: false }),//lodering on / off
            ]);
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "DO_LOADING_BILLING_HISTORY_LOAD", billingHistoryLoad: false }),//lodering on / off
        ]);
    }
}

export default function* rootstripPaymentPostSaga() {
    yield takeLatest(stripePaymentTypes.PAYMENT_INITIAL, paymentInitialCall);
    yield takeLatest(stripePaymentTypes.BILLIN_HISTORY_INITIAL, billingHistoryInitialSaga);
}
