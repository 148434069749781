export const paymentInvoiceInitial = {
    paymentViewData: {},//data
    paymentViewLoad: false,//loader
    moveToPaymentFromDashBoard: "",
};

export const paymentInvoiceView = (state = paymentInvoiceInitial, action = {}) => {

    switch (action.type) {
        case 'INVOICEVIEW_INITIAL': {//initial call
            return {
                ...state,
                ...paymentInvoiceInitial
            };
        }

        case 'INVOICEVIEW_DATA': {
            return {
                ...state,
                paymentViewData: action.paymentViewData
            };
        }

        case 'INVOICEVIEW_LOAD': {
            return {
                ...state,
                paymentViewLoad: action.paymentViewLoad
            };
        }

        case 'NAVIGATE_TO_PAYMENT_PAGE_FROM_DASH_BOARD': {//dashBoard to paymeny filed page
            return {
                ...state,
                moveToPaymentFromDashBoard: action.moveToPaymentFromDashBoard
            };
        }

        default:
            return {
                ...state
            };
    }
};
