import store from "../../../../store";
import _isEqual from "lodash/isEqual";
import { mediaGet } from "../../../../common/httpRestServices";
import { apiUrl, appVersion } from "../../../../helper/ApiUrl";
import { convertNumToCurrency } from "../../../../helper/PaymentMonckData";
import { billingHistoryInitialAction } from "../../../../store/action/stripePaymentAction";
import { convertToLowerCase, nullToObject, taxCollectionDeCode } from "../../../../helper/Validation";

export const billingHistoryInitial = (data = {}, sort = {}) => {
    const apiData = { ...data, ...sort };
    store.dispatch({ type: "DO_LOADING_BILLING_HISTORY_LOAD", billingHistoryLoad: true });//billing history initial call
    store.dispatch(billingHistoryInitialAction(apiData));//billing history initial call
};

export const onPageLeaveCusBillHistory = () => {
    store.dispatch({ type: "DO_LOADING_BILLING_HISTORY_INITIAL" });//billing history initial call
};

export const planAmtCalculation = (element = {}) => {
    const { taxCollections = "", planTypeAmount } = nullToObject(element);
    if (taxCollections) {
        const jsonParseData = taxCollectionDeCode(taxCollections);
        const { currency = "", taxAmount = "" } = nullToObject(jsonParseData);
        if (convertToLowerCase(currency) === convertToLowerCase("inr")) {
            const totalAmt = ((+taxAmount) + (+planTypeAmount)).toFixed();
            return "₹" + convertNumToCurrency(totalAmt);
        }
        return "$" + convertNumToCurrency(planTypeAmount);
    }
    return "$" + convertNumToCurrency(planTypeAmount);
};

export const resetFieldDefault = () => {
    return ({
        size: 10,
        page: 1,
        status: "",
        invoice: "",
        searchTerm: "",
        planTypeId: "",
        createdatEnd: "",
        createdatStart: "",
    });
};

export const resetFieldCheck = (searchInputData = {}) => {
    const {
        organisationId,
        ...restCondition
    } = searchInputData;
    return _isEqual(restCondition, resetFieldDefault());
};

export const resetSortFieldCheck = (searchInputData = {}) => {
    const newObj = {
        sortByInvoiceAt: "",
        sortByAmountAt: "",
        sortByCreatedAt: "desc",
    };
    return _isEqual(searchInputData, newObj);
};

/**
 * @param  {object} obj,
 * download invoice PDF
 */
export const downloadInvoicePDF = (obj = {}) => {
    const { pdfToken = "" } = obj || {};
    const respose = mediaGet(`${apiUrl}${appVersion.mediaDownloadUrl}${pdfToken}`, true);
    const { data = {}, status = 0 } = respose || {};
    if (status === 200) {
        const headerval = respose.headers['content-disposition'];
        const filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document && document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document && document.body.appendChild(link);
        link.click();
    }
};
