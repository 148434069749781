import * as appVerionTypes from '../actionTypes/appVerionTypes';

/**
 * @param  {object} data={}
 * download SDK and Doc url
 */
export const releaseInternal = (data = {}) => {
    return {
        type: appVerionTypes.APP_INTERNAL_RELEASE,
        data,
    };
};

/**
 * @param  {object} data={}
 * custom Release application org details
 */
export const customReleaseOrgDetails = (data = {}) => {
    return {
        type: appVerionTypes.APP_CUSTOM_RELEASE_ORG_DETAILS,
        data,
    };
};
