import { createStore, applyMiddleware } from 'redux';
import rootSaga from './sagas';
import reduxSaga from 'redux-saga';
import rootReducer from './reducer';
import { logger } from 'redux-logger';
import { envPath } from "../helper/ApiUrl";

const reduxSagaMiddleware = reduxSaga();
let storeConfig = [reduxSagaMiddleware];
if (envPath === "dev" || envPath === "qa") { //if "dev" environment logger enable
    storeConfig = [...storeConfig, logger];
}
const store = createStore(rootReducer, applyMiddleware(...storeConfig));
reduxSagaMiddleware.run(rootSaga);
export default store;
