import moment from "moment";
import _get from "lodash/get";
import { refreshPage } from ".";
import { getWithOutToken } from "../common/httpRestServices";
import { envPath, loacteFineUrl, userLoaction } from "../helper/ApiUrl";
import { isPossiblePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';

const md5 = require('md5');

export const devEnvironment = () => {
    return envPath === "dev" ? true : false;
};

/**
 * @param  {string} number
 * date to millisec conevrt
 */
export const convertDateToMillisec = (value = "") => {
    try {
        return moment(value).valueOf();
    } catch {
        return 0;
    }
};

/**
 * convert lower case
 * @param string
 */
export const spaceRemoveLowerCase = (convertValue = "") => {
    return (convertValue || "").toString().toLowerCase().split(' ').join('');
};

/**
 * convert lower case
 * @param string
 */
export const convertToLowerCase = (convertValue = "") => {
    return (convertValue || "").toString().toLowerCase();
};

/**
 * convert Upper case
 * @param string
 */
export const convertToUpperCase = (convertValue = "") => {
    return (convertValue || "").toString().toUpperCase();
};

export const getLocate = async () => {
    const getLoactionResponse = await getWithOutToken(loacteFineUrl);
    const { status: getLoactionStatus = 0, data: getLoactionData = {} } = getLoactionResponse || {};
    if (getLoactionStatus === 200) {
        return {
            IPv4: _get(getLoactionData, "ip", "")
        };
    } else {
        return {
            IPv4: userLoaction,//may be ip not get javascript use get user location
        };
    }
};

/**
 * pass cookies name
 * get from cookiees value
 * @param {string} cookieName
 */
export const getCookieByName = (cookieName = "") => {
    if (document && document.cookie) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${cookieName}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    return "";
};
/**
 * convert Password md5 formate
 * @param {string} value;
 */
export const passwordEncrypt = (value = "") => {
    return md5(value);
};

export const imageValidate = (value = "") => {
    const allowFormet = ['jpeg', 'png', 'jpg', 'PNG'];
    const extension = value.split('.').pop();
    return allowFormet.includes(extension);
};

export const imageSize = (value = 0) => {
    return value <= 1e6;
};

/**
 * @param  {string} pnum=""
 * @param  {string} countryCode=""
 * @returns {boolean}
 * phone num validation
 */
export const PhoneNumValidate = (pnum = "", countryCode = "") => {
    if (convertToLowerCase(countryCode) === convertToLowerCase("in")) {
        return convertToLowerCase(pnum).length === 10 ? true : false;
    } else {
        if (isNaN(Number(countryCode))) {
            return isPossiblePhoneNumber(`+${getCountryCallingCode(countryCode)}${pnum}`);
        }
        return false;
    }
};

export const EmailValidate = (email = "") => {
    const reg = /^\s*([\w+-]+\.)*[\w+]+@([\w+-]+\.)*([\w+-]+\.[a-zA-Z]{2,6})+\s*$/;
    return reg.test(email);
};

export const publicEmailValidate = (email = "") => {
    const bannedDomains = ["gmail.com", "yahoo.com", "hotmail.com"];
    const domain = email.split('@')[1] || "";
    return !bannedDomains.some((ele) => convertToLowerCase(ele) === convertToLowerCase(domain));
};

/**
 * @param  {string} password
 * password allow user stories format
 * 1 Cap char
 * 1 Small Char
 * 1 Number
 * 8 digit Must
 */
export const passwordValidate = (password = "") => {
    const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return reg.test(password);
};

/**
 * find number
 * @param {object} objDatas
 */
export const filterCountGet = (objDatas = {}) => {
    let valueCount = 0;
    Object.values(objDatas).forEach((val) => {
        if (convertToLowerCase(val) !== convertToLowerCase("all") && convertToLowerCase(val) !== "") {
            valueCount = valueCount + 1;
        }
    });
    return valueCount;
};

export const validateForm = (errors = {}) => {
    let valid = true;
    Object.values(errors).forEach((val) => {
        if (val !== true) {
            valid = false;
        }
    });
    return valid;
};

export const validateForm2 = (values = {}) => {
    const error = {};
    if (values.androidFcmProjectId === "" && values.bundleIdAndroid !== "") {
        error['androidFcmProjectId'] = "Project ID - Android is mandatory if Android Package Name is provided";
    } else if (values.androidFcmProjectId !== "" && values.bundleIdAndroid === "") {
        error['bundleIdAndroid'] = "Android Package Name is mandatory if Firebase key is provided";
    } else if (values.firebaseKeyIos === "" && values.bundleIdIos !== "") {
        error['iosFcmProjectId'] = "Project ID - iOS is mandatory if iOS Bundle Id is provided";
    } else if (values.iosFcmProjectId !== "" && values.bundleIdIos === "") {
        error['bundleIdIos'] = "iOS Bundle Id is mandatory if Firebase key - iOS is provided";
    }
    return error;
};

//create and resetpassword page
export const passwordMatchCheck = ({ newPassword = "", conpassword = "" }) => {
    return newPassword === conpassword;
};

//if input field non-empty
export const emptyCheck = (value = "") => {
    return convertToLowerCase(value) !== "";
};

export const ticketSubject = (value = "") => {
    return _get(value, "length", 0) <= 250 && _get(value, "length", 0) >= 1;
};

//customer page and memberPage  name validate
export const nameValidate = (value = "") => {
    return _get(value, "length", 0) <= 50 && _get(value, "length", 0) >= 1;
};

//Platform name validate
export const productNameValidate = (value = "") => {
    return _get(value, "length", 0) <= 15 && _get(value, "length", 0) >= 1;
};

//appversion page Details char limit
export const detailsValidate = (value = "") => {
    return _get(value, "length", 0) <= 500 && _get(value, "length", 0) >= 1;
};

export const ticketDiscription = (value = "") => {
    return _get(value, "length", 0) <= 2000 && _get(value, "length", 0) >= 1;
};

export const addressValidate = (value = "") => {
    return _get(value, "length", 0) <= 100 && _get(value, "length", 0) >= 1;
};

export const pincodeValidate = (value = "") => {
    return _get(value, "length", 0) <= 7 && _get(value, "length", 0) >= 4;
};

export const locationValidate = (value = "") => {
    return _get(value, "length", 0) <= 50;
};

//add cus page total member allow only 5 digit
export const totalUserValidate = (value = "") => {
    return !!(convertToLowerCase(value).length <= 5 && convertToLowerCase(value).length >= 1);
};

export const checkWhiteSpaces = (value = "") => {
    if (typeof value !== "string") {  // check if the string variable is some type other than string
        return false;
    } else {
        return value.trim().length > 0;
    }
};

/**
 * @param  {string} value
 * validate if value is not "0"
 */
export const appversionPalnType = (value = "") => {
    return value !== "0";
};

/**
 * @param  {string} value
 * add app version SDK format check
*/
export const sdkValidate = (value = "") => {
    const allowFormet = ['zip', 'rar', 'tar', 'gz', 'tgz'];
    const extension = value.split('.').pop();
    return allowFormet.includes(extension);
};

export const sdkSizeValidate = (value = "") => {
    return value > 1e8;
};

export const docSizeValidate = (docsValue = "") => {
    return docsValue > 1e6;
};

/**
 * @param  {string} value
 * add app version doc format check
 */
export const docValidate = (value = "") => {
    const allowFormet = ['doc', 'docx', 'pdf', 'odt', 'txt'];
    const extension = value.split('.').pop();
    return allowFormet.includes(extension);
};

export const validateFormEmpty = (errors = {}) => {
    let valid = false;
    Object.values(errors).forEach((val) => {
        if (val !== "") {
            valid = true;
        }
    });
    return valid;
};

export const emptyCheckAllData = (obj = {}) => {
    let valid = true;
    Object.values(obj).forEach((val) => {
        if (val === "") {
            valid = false;
        }
    });
    return valid;
};

/**
 * @param  {string} value=""
 * enter value has been change upperCase
*/
export const webDomainValidate = (value = "") => {
    const reg = /^(https?:\/\/)?[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/i;
    return reg.test(value);
};

export const janusUrlValidate = (value = "") => {
    const protocol = "(https?|wss)";
    const regExp = new RegExp(`^(${protocol}://)?[a-z\\d]+([-.][a-z\\d]+)*\\.[a-z]{2,5}(:\\d{1,5})?(/.*)?$`, "i");
    return regExp.test(value);
};

export const androidBundleValidate = (value = "") => {
    if (!value) return true;
    if (value.split('.').length === 1) return false;
    // Replace character classes with the characters themselves
    const r = /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/gm;
    return r.test(value);
};

export const iosBundleValidate = (value = "") => {
    if (!value) return true;
    const r = /^[a-zA-Z0-9.-]+$/gm;
    return r.test(value);
};

export const webApiBaseUrlValidate = (value = "") => {
    const reg = /^(https?:\/\/)?[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?\/api\/v1$/i;
    return reg.test(value);
};

/**
 * @param  {string} value=""
 * white space check
 */
export const hasWhiteSpaces = (value = "") => {
    return !(value === null || value.trim() === '');
};

export const ageOfDeviceCalculation = (expireDate = "") => {
    const currenDate = moment(new Date()).format("DD/MM/YYYY");
    const nowDate = moment(currenDate, "DD/MM/YYYY");
    const expire = moment(expireDate).format("YYYY-MM-DD");
    const end = moment(expire);
    try {
        return end.diff(nowDate, "days") + 1;
    }
    catch {
        return "";
    }
};

/**
 * ipaddress validate
 * @param {string} e
 */
export const validateIPaddress = (e = "") => {
    const ip = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
    return ip.test(e);
};

/**
 * Version validate
 * @param {string} e
 */
export const validateVersion = (e = "") => {
    const version = /[^a-zA-Z0-9\.]/;
    return version.test(e);
};

/**
 *  Validate Platform Name
 * Only allows space and dots (.)
 * @param {string} e
 */
export const validatePlatformProductName = (e = "") => {
    const version = /^[a-zA-Z\d\.\s]+$/;
    return version.test(e);
};

export const convertUTCDateToLocalDate = (date = "") => {
    const numberConvert = +date;
    return moment(numberConvert).format("YYYY-MM-DD HH:mm:ss");
};

export const nullToObject = (obj = null) => {
    return obj || {};
};

/**
 * User logOut
 * clear sessionStorage
*/
export const _userLogout = () => {
    window.sessionStorage.clear();
    refreshPage();//after logOut reload page
};

export const taxCollectionDeCode = (taxCollections = "") => {
    if (taxCollections) {
        const stringData = decodeURI(taxCollections);
        return stringData ? JSON.parse(stringData) : {};
    }
    return {};
};
