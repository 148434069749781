import React, { useState, useEffect } from 'react';
import "./Registration.scss";
import _get from "lodash/get";
import { FormInput } from '../../common';
import { useTranslation } from 'react-i18next';
import { isHTML, isOffline } from '../../common/helper';
import RegisterPageRole from './RegisterPageRole';
import { serverNotRespond } from '../../helper/ApiToast';
import { numOnlyAllow, processInput } from '../../helper';
import RegisterPageUserCount from './RegisterPageUserCount';
import RegiterPageSignUpButton from './RegiterPageSignUpButton';
import PasswordPolicyValidation from './PasswordPolicyValidation';
import RegisterPageApplicationType from './RegisterPageApplicationType';
import {
    continueButtonhideHandle, handleInputError, nameValidate,
    orgValidate, passwordPolicyUpdate
} from './registerValidate';
import CheckComponent from './CheckComponent';
import {
    IconPasswordViewnewicon, IconPasswordHidenewicon, ImgLogo, IconLoader
} from '../../assets/images';
import {
    checkWhiteSpaces, EmailValidate, emptyCheck, nullToObject, passwordValidate, PhoneNumValidate,
} from '../../helper/Validation';
import AppStatus from './AppStatus';

const ContactFrom = (props = {}) => {

    const {
        search = '',
        errData = {},
        globalStore = {},
        stepView = false,
        inputHandle = {},
        inputHandleEmpty = {},
        resetSso = false,
        handleSuccess = () => { },
        getStepViewState = () => { },
        handleInputValue = () => { },//click function
    } = props || {};
    const { t } = useTranslation();
    const {
        name: nameEmpty = false,
        role: roleEmpty = false,
        appStatus: appStatusEmpty = false,
        appType: appTypeEmpty = false,
        emailID: emailIDEmpty = false,
        password: passwordEmpty = false,
        phoneNum: phoneNumEmpty = false,
        userCount: userCountEmpty = false,
        organisationName: organisationNameEmpty = false,
    } = inputHandleEmpty;

    const { commonData = {}, loginPage: { registerLoad = false } = {} } = globalStore;
    const { ssoResponseDetails = {} } = commonData;
    const { name: ssoUserName = "", ssoresponse = false, email: ssoEmail = "" } = ssoResponseDetails;
    const [getSsoLoginLoader, setSsoLoginLoader] = useState(false);
    const [getSsoresponse, setSsoresponse] = useState(false);
    const [getSsoResetSso, setSsoResetSso] = useState(false);
    const [orgError, setOrgError] = useState(false);
    const [emailErr, serEmailError] = useState(false);
    const [ErrorPnum, SetErrorPnum] = useState(false);
    const [ErrorFname, SetErrorFname] = useState(false);
    const [appTypeOpen, setAppTypeOpen] = useState(false);
    const [passwordType, SetPasswordType] = useState(false);
    const [passwordHint, setPasswordHint] = useState(false);
    const [roleDropDown, setRoleDropDown] = useState(false);
    const [chatCheckBox, setChatCheckBox] = useState(false);
    const [appStatusDropDown, setAppStatusDropDown] = useState(false);
    const [ErrorPassword, SetErrorPassword] = useState(false);
    const [userCountOpen, setUserCountOpen] = useState(false);
    const [phoneNumFiled, SetphoneNumFiled] = useState(inputHandle.phoneNum);
    const [bussinessAddressError, setBussinessAddressError] = useState(false);
    const [getValidateStep2, setValidateStep2] = useState(true);

    const [passwordPolicy, setPasswordPolicy] = useState({
        lowerCase: false,
        upperCase: false,
        specialCase: false,
        numberCase: false,
        eightDigitCase: false,
    });
    const allSelectCheck = {
        chat: true,
        videoCall: true,
        voiceCall: true,
        all: true
    };
    const [getLookingFor, setLookingFor] = useState({
        chat: true,
        videoCall: false,
        voiceCall: false,
        all: false
    });
    const [getLookingForItem, setLookingForItem] = useState('');
    const [getTechStackItem, setTechStackItem] = useState('');

    const [getTechStack, setTechStack] = useState({
        ReactNative: false,
        Flutter: false,
        Unity: false,
        Angular: false,
        Javascript: false,
        ReactJS: false,
        Native: false,
        Others: false
    });

    const { ReactNative, Flutter, Unity, Angular, Javascript, ReactJS, Native, Others } = getTechStack;

    const { chat, videoCall, voiceCall, all } = getLookingFor;

    const [stateManage, setStateManage] = useState({
        roleError: false,//role
        appTypeError: false,//app type
        userCountError: false,//user count
        emailLength: false,//emil
        orgEmptySpace: false,//org
        nameEmptySpace: false,//name
        emailEmptySpace: false,//emil
        countryEmptySpace: false,//coutry
        passwordWhiteSpace: false,//password
        applicationNameEmptySpace: false,//application,
        techStackError: false,
        bussinessAddressEmptySpace: false,
        userLookingModuleError: false,
        // html error
        nameHtmlError: false,
        bussinessNameHtmlError: false,
        applicationNameHtmlError: false,
    });

    const [errConInfo, setErrConInfo] = useState({
        roleError: false,//role
        appTypeError: false,//application type
        userCountError: false,//estimate user count
        appStatusError: false,
        nameError: false,//full name
        emailError: false,//email
        emailLenErr: false,//email
        countryError: true,
        orgNameError: false,//bussiness name
        orgwhitespace: false,//bussiness name
        phoneNumError: false,//phone number
        techStackError: true,
        passwordError: false,//phone number
        namewhitespace: false,//full name
        emailWhiteSpace: false,//email
        countryWhiteSpace: true,
        passwordWhiteSpace: false,//password
        applicationNameError: true,
        userLookingModuleError: true,
        bussinessWhiteuEmptySpace: true,
        applicationNameEmptySpace: true,
        termsAndConditionsCheckbox: true,
        bussinessAddresslengthError: true,

        nameHtmlError: false,
        bussinessNameHtmlError: false,
        termsAndConditionsError: false,
        applicationNameHtmlError: true,
    });

    //dropdown open and closes
    const _handleRoleDropDown = (event = {}) => {
        event.preventDefault && event.preventDefault();
        setRoleDropDown(!roleDropDown);
    };

    //dropdown open and closes
    const _handleAppStatusDropDown = (event = {}) => {
        event.preventDefault && event.preventDefault();
        setAppStatusDropDown(!appStatusDropDown);
    };

    //outside close
    const _handleOnOutsideClick2 = () => {
        setRoleDropDown(false);
    };

    //outside close
    const _handleAppStatusDropOutside = () => {
        setAppStatusDropDown(false);
    };

    //dropdown open and closes
    const _handleAppType = (event = {}) => {
        event.preventDefault && event.preventDefault();
        setAppTypeOpen(!appTypeOpen);
    };

    //outside close
    const _handleOnOutsideClick3 = () => {
        setAppTypeOpen(false);
    };

    //dropdown open and closes
    const _handleUserCount = (event = {}) => {
        event.preventDefault && event.preventDefault();
        setUserCountOpen(!userCountOpen);
    };

    // setUserCountOpenoutside close
    const _handleOnOutsideClick4 = () => {
        setUserCountOpen(false);
    };

    const callCheckBoxHandle = (name = "", selectedItem = "") => {
        switch (name) {
            case "LookingFor":
                setLookingFor({
                    ...getLookingFor,
                    all: false,
                    [selectedItem]: !getLookingFor[selectedItem]
                });
                break;
            case "TechStack":
                setTechStack({ ...getTechStack, [selectedItem]: !getTechStack[selectedItem] });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (chat && videoCall && voiceCall) {
            setLookingFor({
                ...getLookingFor,
                all: true,
            });
        }
        else {
            setLookingFor({
                ...getLookingFor,
                all: false,
            });
        }
        if (all === true) {
            setLookingFor(allSelectCheck);
        }
    }, [chat, videoCall, voiceCall, all]);

    /**
     * onType value
     * validsation
    */
    const swFieldValidation = (name = "", value = "", optionValue = "") => {
        const errors = errConInfo;
        switch (name) {
            case 'name':
                errors.namewhitespace = checkWhiteSpaces(value);
                errors.nameError = nameValidate(value);
                errors.nameHtmlError = !isHTML(value);
                break;
            case 'role':
                errors.roleError = emptyCheck(value);
                break;
            case 'appStatus':
                errors.appStatusError = emptyCheck(value);
                break;
            case 'appType':
                errors.appTypeError = emptyCheck(value);
                break;
            case 'userCount':
                errors.userCountError = emptyCheck(value);
                break;
            case 'password':
                setPasswordPolicy(passwordPolicyUpdate(value));
                errors.passwordError = passwordValidate(value);
                errors.passwordWhiteSpace = checkWhiteSpaces(value);
                break;
            case 'chatCheckBox':
                setChatCheckBox(!chatCheckBox);
                errors.termsAndConditionsError = (value === 'true');
                break;
            case 'organisationName':
                errors.orgNameError = orgValidate(value);
                errors.orgwhitespace = checkWhiteSpaces(value);
                errors.bussinessNameHtmlError = !isHTML(value);
                break;
            case 'applicationName':
                errors.applicationNameHtmlError = value ? !isHTML(value) : true;
                errors.applicationNameError = value ? nameValidate(value) : true;
                errors.applicationNameEmptySpace = value ? checkWhiteSpaces(value) : true;
                break;
            case 'emailID':
                errors.emailWhiteSpace = checkWhiteSpaces(value);
                errors.emailLenErr = nameValidate(value);
                errors.emailError = EmailValidate(value);
                break;
            case 'phoneNum':
                errors.phoneNumError = PhoneNumValidate(value ? value : optionValue, inputHandle.countryCode);
                break;
            case 'countryCode':
                errors.phoneNumError = PhoneNumValidate(optionValue ? optionValue : phoneNumFiled, value);
                break;
            case 'country':
                errors.countryWhiteSpace = checkWhiteSpaces(value);
                errors.countryError = nameValidate(value);
                break;
            case 'userLookingModule':
                errors.userLookingModuleError = emptyCheck(value);
                break;
            case 'techStack':
                errors.techStackError = emptyCheck(value);
                break;
            case 'bussinessAddress':
                errors.bussinessWhiteuEmptySpace = checkWhiteSpaces(value);
                errors.bussinessAddresslengthError = orgValidate(value);
                break;
            default:
                break;
        }
        setErrConInfo(errors);
        const {
            nameError = false,
            roleError = false,
            phoneNumError = false,
            emailError = false,
            orgNameError = false,
            emailLenErr = false,
            orgwhitespace = false,
            techStackError = false,
            namewhitespace = false,
            countryWhiteSpace = false,
            emailWhiteSpace = false,
            bussinessWhiteuEmptySpace = false,
            bussinessAddresslengthError = false,
            userLookingModuleError = false,
            passwordError = false,
            passwordWhiteSpace = false,
            applicationNameEmptySpace = false,
            // html error handle
            nameHtmlError = false,
            bussinessNameHtmlError = false,
            applicationNameHtmlError = false,
        } = errConInfo;

        phoneNumError && SetErrorPnum(false);//phone num error
        nameError && SetErrorFname(false);//full name length error
        orgNameError && setOrgError(false);//full name white space error
        bussinessWhiteuEmptySpace && setBussinessAddressError(false);//BussinessAddressError
        bussinessAddressError && setBussinessAddressError(false);
        emailError && serEmailError(false);//email
        passwordError && SetErrorPassword(false);//password

        bussinessAddresslengthError &&
            setStateManage({
                ...stateManage,
                bussinessAddressEmptySpace: false
            });

        //role
        roleError &&
            setStateManage({
                ...stateManage,
                roleError: false
            });

        emailLenErr &&
            setStateManage({
                ...stateManage,
                emailLength: false
            });

        // name html
        nameHtmlError &&
            setStateManage({
                ...stateManage,
                nameHtmlError: false
            });
        // bussiness name html
        bussinessNameHtmlError &&
            setStateManage({
                ...stateManage,
                bussinessNameHtmlError: false
            });
        //application name html
        applicationNameHtmlError &&
            setStateManage({
                ...stateManage,
                applicationNameHtmlError: false
            });

        orgwhitespace &&
            setStateManage({
                ...stateManage,
                orgEmptySpace: false,
            });

        applicationNameEmptySpace &&
            setStateManage({
                ...stateManage,
                applicationNameEmptySpace: false,
            });

        namewhitespace &&
            setStateManage({
                ...stateManage,
                nameEmptySpace: false,
            });

        passwordWhiteSpace &&
            setStateManage({
                ...stateManage,
                passwordWhiteSpace: false,
            });

        countryWhiteSpace &&
            setStateManage({
                ...stateManage,
                countryEmptySpace: false,
            });

        userLookingModuleError &&
            setStateManage({
                ...stateManage,
                userLookingModuleError: false,
            });
        techStackError &&
            setStateManage({
                ...stateManage,
                techStackError: false,
            });
        emailWhiteSpace &&
            setStateManage({
                ...stateManage,
                emailEmptySpace: false,
            });

        handleInputValue({
            target: {
                name: name,
                value: value
            }
        }, errors);
    };

    /**
     * sign in button validate
    */

    const isDisabled = () => {
        if ((stepView && errConInfo.nameError && errConInfo.passwordError &&
            errConInfo.orgNameError && errConInfo.phoneNumError &&
            errConInfo.emailError && errConInfo.termsAndConditionsCheckbox)) {
            return false;
        }
    };

    /**
     * @param  {object} event=""
     * append value
    */
    const handleInputValueChange = (event = {}) => {
        const { name = "", value = "" } = _get(event, "target", {});
        swFieldValidation(name, value, "");
        isDisabled();
    };

    /**
     * onBlur validate
    */
    const errValidate = (event = {}) => {
        const {
            nameError = false,
            emailError = false,
            emailLenErr = false,
            orgNameError = false,
            orgwhitespace = false,
            phoneNumError = false,
            namewhitespace = false,
            emailWhiteSpace = false,
            countryWhiteSpace = false,

            passwordError = false,
            passwordWhiteSpace = false,
            applicationNameEmptySpace = false,
            bussinessWhiteuEmptySpace = false,
            bussinessAddresslengthError = false,
            // html error handle
            nameHtmlError = false,
            bussinessNameHtmlError = false,
            applicationNameHtmlError = false,
        } = errConInfo;
        setBussinessAddressError(inputHandle.bussinessAddress ? !bussinessAddresslengthError : false);//country error
        SetErrorFname(inputHandle.name ? !nameError : false);//full name length error
        serEmailError(inputHandle.emailID ? !emailError : false);//orgNameError length error
        SetErrorPassword(inputHandle.password ? !passwordError : false);//password validate

        setOrgError(inputHandle.organisationName ? !orgNameError : false);//organisationName error
        SetErrorPnum(inputHandle.phoneNum ? !phoneNumError : false);//phoneNum error
        const newObj = {
            ...stateManage,
            emailLength: inputHandle.emailID ? !emailLenErr : false,
            nameHtmlError: inputHandle.name ? !nameHtmlError : false,
            bussinessNameHtmlError: inputHandle.organisationName ? !bussinessNameHtmlError : false,
            applicationNameHtmlError: inputHandle.applicationName ? !applicationNameHtmlError : false,
            nameEmptySpace: inputHandle.name ? !namewhitespace : false,
            emailEmptySpace: inputHandle.emailID ? !emailWhiteSpace : false,//email
            countryEmptySpace: inputHandle.country ? !countryWhiteSpace : false,
            orgEmptySpace: inputHandle.organisationName ? !orgwhitespace : false,
            applicationNameEmptySpace: inputHandle.applicationName ? !applicationNameEmptySpace : false,
            passwordWhiteSpace: inputHandle.password ? !passwordWhiteSpace : false,//password
            bussinessAddressEmptySpace: inputHandle.bussinessAddress ? !bussinessWhiteuEmptySpace : false,
        };
        setStateManage(newObj);
        // indCodeFunction(inputHandle.countryCode === "IN" ? true : false, name, inputHandle.phoneNum,);//phone num validate
    };

    const handleStepView = (state = false) => {
        if (isOffline()) {
            serverNotRespond("Please check your internet connection");
            return;
        }
        getStepViewState(state);
    };

    const handleSubmit = (event = {}) => {
        event.preventDefault();
        if (ssoresponse) {
            handleSuccess();
        } else {
            if (stepView) { handleStepView(false); } else { handleSuccess(); }
        }
    };

    useEffect(() => {
        if (Object.keys(nullToObject(ssoResponseDetails)).length > 0) {
            if (ssoresponse) { //sso login success
                ssoEmail && swFieldValidation("emailID", ssoEmail);
                ssoUserName && swFieldValidation("name", ssoUserName);
            }
        }
    }, [ssoResponseDetails]);

    useEffect(() => {
        const errorValidationData = ssoresponse ?
            { ...errData, passwordError : true, passwordWhiteSpace : true } : errData;
        const formValidation = Object.values(errorValidationData).filter((value) => value === false);
        setValidateStep2(formValidation.length === 0);
    }, [errData, stateManage]);

    const handleLookingForName = (key) => {
        switch (key) {
            case 'chat': return "Chat";
            case 'videoCall': return "Video Call";
            case 'voiceCall': return "Voice Call";
            default: break;
        }
    };
    const handleTechStackName = (key) => {
        switch (key) {
            case 'ReactNative': return "React Native";
            case 'Native': return "Native (iOS/Android)";
            case 'ReactJS': return "React JS";
            default: return key;
        }
    };

    useEffect(() => {
        const LookingForSelectedItems = Object.keys(getLookingFor).filter((key) => key !== "all" && getLookingFor[key] === true);
        const SelectedItems = LookingForSelectedItems.map((key) => handleLookingForName(key));
        setLookingForItem(String(SelectedItems));
    }, [getLookingFor]);

    useEffect(() => {
        const TechStackSeletedItems = Object.keys(getTechStack).filter((key) => getTechStack[key] === true);
        const SelectedItems = TechStackSeletedItems.map((key) => handleTechStackName(key));
        setTechStackItem(String(SelectedItems));
    }, [getTechStack]);

    useEffect(() => {
        const event = { target: { name: "userLookingModule", value: getLookingForItem } };
        handleInputValueChange(event);
    }, [getLookingForItem]);

    useEffect(() => {
        const event = { target: { name: "techStack", value: getTechStackItem } };
        handleInputValueChange(event);
    }, [getTechStackItem]);

    useEffect(() => {
        setSsoLoginLoader(registerLoad);
    }, [registerLoad]);

    useEffect(() => {
        SetphoneNumFiled(inputHandle.phoneNum ? inputHandle.phoneNum : "");
    }, [inputHandle.phoneNum]);

    useEffect(() => {
        setSsoresponse(ssoresponse);
    }, [ssoresponse]);

    useEffect(() => {
        setSsoResetSso(resetSso);
    }, [resetSso]);

    useEffect(() => {
        setSsoResetSso(resetSso);
        if (search) {
            if (EmailValidate(search.replace("?email=", ''))) {
                const event = { target: { name: "emailID", value: search.replace("?email=", '') } };
                handleInputValueChange(event);
            }
            else {
                window.history.pushState({}, null, "/register");
            }
        }
    }, []);

    React.useEffect(() => {
        if (!stepView) {
            document &&
                document.querySelector("#Registration_wraper") &&
                document.querySelector("#Registration_wraper").scrollTo &&
                document.querySelector("#Registration_wraper").scrollTo({
                    top: 0,
                });
        }

    }, [stepView]);

    const hasError = (err1 = '', err2 = '', err3 = '', err4 = '') => {
        return (err1 || err2 || err3 || err4) ? ' error ' : '';
    };
    const getPhoneNumError = () => {
        return ErrorPnum ? t("ERROR.NUMBER") : "";
    };

    return (
        <React.Fragment>
            {registerLoad || getSsoLoginLoader ? <div className={`pageLoader fixed overlay`}> <i><IconLoader /></i></div> : null}
            <div style={{paddingBottom: !stepView ? " 44px " : ""}} className={`right-side`}>
                <form action={(e) => handleSubmit(e)}>
                    <div className='heading-info'>
                        {stepView ?
                            <>
                                <h2>Create Your Account</h2>
                                <div className="mobile_show heading_contents">
                                    <img src={ImgLogo} alt="logo" className='logo' />
                                    <div className="heading_content_title">
                                        <strong>Video</strong><i></i>
                                        <strong>Audio</strong><i></i>
                                        <strong>Chat</strong>
                                    </div>
                                    <p className="heading_content_highlight"> Integrate in
                                        <strong> {"<"} 30 mins </strong> with
                                        <strong> Lifetime Free </strong> Chat SDK

                                    </p>
                                </div>
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div style={{ display: `${!stepView ? 'none' : ''}` }}>
                        <div className={`form-group name-row left ${hasError(ErrorFname, stateManage.nameHtmlError, stateManage.nameEmptySpace, nameEmpty)}`}>
                            <FormInput
                                id={"name"}
                                name={"name"}
                                required={false}
                                autoFocus={true}
                                _onBlur={errValidate}
                                readOnly={ssoresponse}
                                value={inputHandle.name}
                                palceholder={"Full Name"}
                                headingPlaceholder={false}
                                floatingPlaceholder={true}
                                customClass=" outline "
                                _onchange={handleInputValueChange}
                                error={handleInputError("name", {err1 : nameEmpty, err2 : stateManage.nameEmptySpace, err3 : ErrorFname, err4 : stateManage.nameHtmlError})}
                                />
                        </div>
                        <div
                            className={`form-group ${hasError(organisationNameEmpty, stateManage.bussinessNameHtmlError, stateManage.orgEmptySpace, orgError)}`}>
                            <FormInput
                                required={false}
                                _onBlur={errValidate}
                                id={"organisationName"}
                                name={"organisationName"}
                                headingPlaceholder={false}
                                floatingPlaceholder={true}
                                label={"Organisation Name"}
                                palceholder={"Business Name"}
                                customClass=" outline "
                                value={inputHandle.organisationName}
                                _onchange={handleInputValueChange}
                                error={handleInputError("organisationName", {err1 : organisationNameEmpty, err2 : stateManage.orgEmptySpace,
                                    err3 : orgError, err4 : stateManage.bussinessNameHtmlError})}
                            />
                        </div>

                        <div className={`form-group ${hasError(emailErr, emailIDEmpty, stateManage.emailEmptySpace, stateManage.emailLength)}`}>
                            <FormInput
                                required={false}
                                id={"emailID"}
                                name={"emailID"}
                                readOnly={ssoresponse}
                                label={"Business id"}
                                _onBlur={errValidate}
                                palceholder={"Work Email"}
                                headingPlaceholder={false}
                                floatingPlaceholder={true}
                                customClass=" outline "
                                value={inputHandle.emailID}
                                _onchange={handleInputValueChange}
                                error={handleInputError("emailID", {err1 : emailIDEmpty, err2 : stateManage.emailEmptySpace,
                                    err3 : emailErr, err4 : stateManage.emailLength})}
                            />
                        </div>
                        <div className={`form-group Mobile  ${hasError(ErrorPnum, phoneNumEmpty) ? "" : " selectedval"}`}>
                            <FormInput
                                type="text"
                                id={"phoneNum"}
                                required={false}
                                name={"phoneNum"}
                                _onBlur={errValidate}
                                countryDropDown={true}
                                label={"Phone Number"}
                                countryId={"countryDrop"}
                                headingPlaceholder={false}
                                floatingPlaceholder={true}
                                value={inputHandle.phoneNum}
                                palceholder={"Phone Number"}
                                customClass=" outline "
                                countryCode={inputHandle.countryCode}
                                _maxLength={inputHandle.countryCode !== "IN" ? 14 : 10}
                                onInput={(e) => processInput(e)}
                                _onchange={(event) => handleInputValueChange(event)}
                                _onKeyPress={(event) => numOnlyAllow(event)}
                                error={
                                    phoneNumEmpty ? t("ERROR.PHONE_NUMBER_ERR_EMPTY") : getPhoneNumError() }
                            />
                        </div>

                        {!ssoresponse &&
                            <div className={`form-group password ${passwordHint ? " focus " : ''}
                            ${hasError(passwordEmpty, stateManage.passwordWhiteSpace, ErrorPassword)}`}>
                                <FormInput
                                    required={false}
                                    name={"password"}
                                    id={"password"}
                                    palceholder={"Password"}
                                    customClass={" password outline "}
                                    headingPlaceholder={false}
                                    floatingPlaceholder={true}
                                    value={inputHandle.password}
                                    autoComplete={"new-password"}
                                    onFocus={() => setPasswordHint(true)}
                                    type={!passwordType ? "password" : "text"}
                                    _onchange={(event) => handleInputValueChange(event)}
                                    _onBlur={() => { errValidate(); setPasswordHint(false); }}
                                    error={handleInputError("password", {err1 : passwordEmpty, err2 : stateManage.passwordWhiteSpace, err3 : ErrorPassword})}
                                >
                                    <PasswordPolicyValidation
                                        passwordPolicy={passwordPolicy}
                                    />
                                </FormInput>
                                <span className="hideView active">
                                    <i className="IconPasswordView">
                                        {passwordType ?
                                            <span
                                                onClick={() => { SetPasswordType(false); }}
                                            >
                                                <IconPasswordViewnewicon />
                                            </span> :
                                            <span
                                                onClick={() => { SetPasswordType(true); }}
                                            >
                                                <IconPasswordHidenewicon />
                                            </span>}
                                    </i>
                                </span>
                            </div>
                        }
                    </div>

                    <div style={{ display: `${(stepView && !ssoresponse) ? 'none' : ''}` }}>
                        <div className='' style={{ display: `${stepView && !ssoresponse && !resetSso ? 'none' : ''}` }}>
                            <div style={{ display: `${stepView && ssoresponse && getSsoResetSso ? 'none' : ''}` }} className="mobile_show heading_contents">
                                <img src={ImgLogo} alt="logo" className='logo' />
                                <h2>Welcome to MirrorFly, <strong>{inputHandle.name}</strong></h2>
                            </div>
                            <div className='check_option_wraper'>
                                <h3>What Are You Looking For?</h3>
                                <div className='check_group'>
                                    <CheckComponent
                                        label={"Chat"}
                                        name="chat"
                                        checkVal={chat}
                                        onChange={(e) => callCheckBoxHandle("LookingFor", "chat")}
                                    />
                                    <CheckComponent
                                        label={"Video Call"}
                                        name="videoCall"
                                        checkVal={videoCall}
                                        onChange={(e) => callCheckBoxHandle("LookingFor", "videoCall")}
                                    />
                                    <CheckComponent
                                        label={"Voice Call"}
                                        name="voiceCall"
                                        checkVal={voiceCall}
                                        onChange={(e) => callCheckBoxHandle("LookingFor", "voiceCall")}
                                    />
                                    <CheckComponent
                                        disabled={all}
                                        label={"All Above"}
                                        name="all"
                                        checkVal={all}
                                        onChange={all ? () => { } : (e) => callCheckBoxHandle("LookingFor", "all")}
                                    />
                                </div>
                            </div>

                            <RegisterPageRole
                                roleEmpty={roleEmpty}
                                inputHandle={inputHandle}
                                roleDropDown={roleDropDown}
                                _handleRoleDropDown={_handleRoleDropDown}
                                handleInputValueChange={handleInputValueChange}
                                _handleOnOutsideClick2={_handleOnOutsideClick2}
                            />

                            <AppStatus
                                appStatusEmpty={appStatusEmpty}
                                openDropDown={appStatusDropDown}
                                inputHandle={inputHandle}
                                _handleDropDown={_handleAppStatusDropDown}
                                handleInputValueChange={handleInputValueChange}
                                _handleOnOutsideClick2={_handleAppStatusDropOutside}
                            />

                            {/* application type */}
                            <RegisterPageApplicationType
                                inputHandle={inputHandle}
                                appTypeOpen={appTypeOpen}
                                appTypeEmpty={appTypeEmpty}
                                _handleAppType={_handleAppType}
                                handleInputValueChange={handleInputValueChange}
                                _handleOnOutsideClick3={_handleOnOutsideClick3}
                            />

                            {/* user count */}
                            <RegisterPageUserCount
                                inputHandle={inputHandle}
                                userCountOpen={userCountOpen}
                                userCountEmpty={userCountEmpty}
                                _handleUserCount={_handleUserCount}
                                handleInputValueChange={handleInputValueChange}
                                _handleOnOutsideClick4={_handleOnOutsideClick4}
                            />
                            <div className='check_option_wraper TechStack'>
                                <h3>Tech Stack</h3>
                                <div className='check_group'>
                                    <CheckComponent
                                        name="ReactNative"
                                        label={"React Native"}
                                        checkVal={ReactNative}
                                        CheckComponentClass="col1"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "ReactNative")}
                                    />
                                    <CheckComponent
                                        name="Flutter"
                                        label={"Flutter"}
                                        checkVal={Flutter}
                                        CheckComponentClass="col2"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "Flutter")}
                                    />
                                    <CheckComponent
                                        name="Unity"
                                        label={"Unity"}
                                        checkVal={Unity}
                                        CheckComponentClass="col3"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "Unity")}
                                    />
                                    <CheckComponent
                                        name="Angular"
                                        label={"Angular"}
                                        checkVal={Angular}
                                        CheckComponentClass="col4"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "Angular")}
                                    />
                                    <CheckComponent
                                        name="Javascript"
                                        label={"Javascript"}
                                        checkVal={Javascript}
                                        CheckComponentClass="col1"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "Javascript")}
                                    />
                                    <CheckComponent
                                        label={"React JS"}
                                        name="ReactJS"
                                        checkVal={ReactJS}
                                        CheckComponentClass="col2"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "ReactJS")}
                                    />
                                    <CheckComponent
                                        label={"Native (Android/iOS)"}
                                        name="Native"
                                        checkVal={Native}
                                        CheckComponentClass="col"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "Native")}
                                    />
                                    <CheckComponent
                                        label={"Others"}
                                        name="Others"
                                        checkVal={Others}
                                        CheckComponentClass="col2"
                                        onChange={(e) => callCheckBoxHandle("TechStack", "Others")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <RegiterPageSignUpButton
                        chatCheckBox={chatCheckBox}
                        ssoresponse={getSsoresponse}
                        stepView={stepView}
                        onChange={handleInputValueChange}
                        handleSubmit={handleSubmit}
                        isDisabled={(stepView && !getSsoresponse) ? !continueButtonhideHandle(errConInfo, ssoresponse) : !getValidateStep2}
                    />
                </form>
            </div >
        </React.Fragment >
    );
};
export default React.memo(ContactFrom);
