import { Get } from "../common/httpRestServices";
import { encodeGetParams } from "../helper/Encypt";
import { apiUrl, activityLog } from "../helper/ApiUrl";

/**
 * @param  {} obj={}
 * get group list
 */
export const getActivitylogService = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : null;
    return Get(`${apiUrl}${activityLog.adminActivities}?${queryString}`, true);
};
export const getActivitylogFilterService = () => {
    return Get(`${apiUrl}${activityLog.adminActivityFilter}`, true);
};
