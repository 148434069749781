export const initialState = {
    activityLogLoader: false,
    activityLogList: {},
    activityLogSearchData: null,
    activityLogFilter: {},
};
export const activityLogReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'DO_ACTIVITY_LOG_INITIAL': {
            return {
                ...state,
                ...initialState,
            };
        }

        case 'USER_ACTIVITY_LOG_LOADER': {
            return {
                ...state,
                activityLogLoader: action.activityLogLoader
            };
        }
        //user
        case 'USER_ACTIVITY_LOG_LIST': {
            return {
                ...state,
                activityLogList: action.activityLogList
            };
        }
        case 'USER_ACTIVITY_LOG_SEARCH': {
            return {
                ...state,
                activityLogSearchData: action.activityLogSearchData
            };
        }
        case 'USER_ACTIVITY_LOG_FILTER': {
            return {
                ...state,
                activityLogFilter: action.activityLogFilter
            };
        }
        default:
            return {
                ...state
            };
    }
};
