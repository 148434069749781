import store from "../../store";
import * as customerTypes from '../actionTypes/customerTypes';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { failToast, succToast, serverNotRespond } from "../../helper/ApiToast";
import { getcusTeamsList, updateCusLicenseService } from "../../services/cusTeamsServices";

/**
 * @param  {object} obj={}
 * get customer Teams details
 */
function* getCusTeamsList(obj = {}) { //GetMember Data
    try {
        const getCusteamlist = yield call(getcusTeamsList, obj);
        const { data = {} } = getCusteamlist || {};
        const { status = 0 } = data || {};
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "CUS_TEAMS_LIST_DATA", cusTeamsData: data }),//custemer teams user list
                put({ type: "CUS_TEAMS_LIST_LOADER", cusTeamLoad: false })//loder on and off
            ]);
        } else {
            yield all([
                put({ type: "CUS_TEAMS_LIST_LOADER", cusTeamLoad: false })//loder on and off
            ]);
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "CUS_TEAMS_LIST_LOADER", cusTeamLoad: false })//loder on and off
        ]);
    }
}

const HookloaderConst = () => {//common updateCuslicenseSaga call
    store.dispatch({ type: "UPDATE_DOMAIN_HOOK_LOADER", Hookloader: false });
};

/**
 * @param  {object} obj={}
 * cus domain and hook url update
 * domain and hook url pass
 */
function* updateCuslicenseSaga(obj = {}) { //GetMember Data
    try {
        const updateLicenseKry = yield call(updateCusLicenseService, obj);
        const { data: { status = 0, message = "" } = {} } = updateLicenseKry || {};
        if (status === 200) {
            succToast(message);
            HookloaderConst();//loader off
            yield all([
                put({ type: "HOOK_UPDATE_RE_LOAD_PROFILE", hookReload: true })//loder on and off
            ]);
        } else {
            failToast(message);
            HookloaderConst();//loader off
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        HookloaderConst();//loader off
    }
}

export default function* cusTeamsSaga() {
    yield takeLatest(customerTypes.GET_CUS_PAGE_TEAMS_LIST, getCusTeamsList);
    yield takeLatest(customerTypes.UPDATE_CUS_LICENSE, updateCuslicenseSaga);
}
