export const constantValue = {
    "SPACERROR": "This field cannot be empty.",
    "EMAIL_ID_ERROR": "Please enter valid email id",
    "CHAR_ERROR_15": "Maximum 15 characters allowed.",
    "CHAR_ERROR_50": "Maximum 50 characters allowed.",
    "CHAR_ERROR_100": "Maximum 100 characters allowed",
    "CHAR_ERROR_500": "Maximum 500 characters allowed.",
    "CHAR_ERROR_250": "Maximum 250 characters allowed.",
    "CHAR_ERROR_2000": "Maximum 2000 characters allowed.",
    "PRODUCT_EMPTY_ERROR":"Please enter the product name.",
    "INTERNET_ERROR": "Please check your internet connection",
    "PRODUCT_ALREADY_EXIST_ERROR": "Product name already exist.",
    "COPY_PASTE_ERROR": "For security reasons, we don't allow Copy/Paste",
    "SERVER_ERROR": "The server is not responding. Please try again later",
    "VALID_URL_ERROR":"Please enter valid URL",
    "VALID_PRODUCT_NAME_ERROR":"Please enter valid name",
    "VALID_APIBASE_URL_ERROR":'Please append URL with "/api/v1"',
};
