import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { encodeGetParams } from "../helper/Encypt";
import { Delete, Get, Put, Post } from "../common/httpRestServices";
import { apiUrl, UpdateProfile } from "../helper/ApiUrl";

export const updateProfleService = (obj = {}) => {
    const {
        data: {
            fullName = "",
            countryCode = "",
            phoneNumber = "",
            profileImage = {},
            countryCodeShort = "",
        } = {}
    } = obj;
    const newObj = {
        fullName: fullName,
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        countryCodeShort: countryCodeShort,
        profileImage: profileImage
    };
    const { name = "" } = profileImage;
    const formData = new FormData();
    formData.append("profileImage", name ? profileImage : "");
    const queryString = !_isEmpty(newObj) ? encodeGetParams(newObj) : "";
    return Put(`${apiUrl}${UpdateProfile.profileUpdate}${queryString}`, formData, true);
};

/**
 * @param  {} obj={}
 * get server details
 */
export const getServerDetailsServeics = (obj = {}) => {
    const { data: { userId = 0 } = {} } = obj;
    return Get(`${apiUrl}${UpdateProfile.getServerConfigPath}userId=${userId}`, true);
};
export const revokeServerDetailsService = (userId = '') => {
    return Put(`${apiUrl}${UpdateProfile.revokeApiCredential}userId=${userId}`, true);
};

/**
 * @param  {} obj={}
 * update customer admin server configuration
 */
export const updateServerConfigDetailsServeics = (obj = {}) => {
    const { data = {} } = obj;
    const { callCertificateData = {}, chatCertificateData, firebaseKeyJsonfile,firebaseKeyIosJsonfile, ...restdata } = data;
    const formData = new FormData();
    formData.append("calls", _get(callCertificateData, "fileData.name", "") !== "" ? callCertificateData.fileData : "");
    formData.append("chats", _get(chatCertificateData, "fileData.name", "") !== "" ? chatCertificateData.fileData : "");
    formData.append("androidFirebase", _get(firebaseKeyJsonfile, "fileData.name", "") !== "" ? firebaseKeyJsonfile.fileData : "");
    formData.append("iosFcm", _get(firebaseKeyIosJsonfile, "fileData.name", "") !== "" ? firebaseKeyIosJsonfile.fileData : "");
    const queryString = !_isEmpty(restdata) ? encodeGetParams(restdata) : "";
    return Put(`${apiUrl}${UpdateProfile.updateCustomerServerConfigPath}${queryString}`, formData, true);
};

/**
 * @param  {} obj={}
 * update super admin server configuration
 */
export const updateServerConfigDetailssuperAdminServeics = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = !_isEmpty(data) ? encodeGetParams(data) : "";
    return Put(`${apiUrl}${UpdateProfile.updateSuperAdminServerConfigPath}${queryString}`, true);
};

/**
 * @param  {} obj={}
 * update super admin server configuration
 */
export const saveOrEditServerConfigService = (obj = {}) => {
    const { data = {} } = obj;
    const { callCertificateData = {}, chatCertificateData,firebaseKeyJsonfile,firebaseKeyIosJsonfile, ...restdata } = data;
    const formData = new FormData();
    formData.append("calls", _get(callCertificateData, "fileData.name", "") !== "" && restdata.isCalls ? callCertificateData.fileData : "");
    formData.append("chats", _get(chatCertificateData, "fileData.name", "") !== "" && restdata.isChats ? chatCertificateData.fileData : "");
    formData.append("androidFcm", _get(firebaseKeyJsonfile, "fileData.name", "") !== "" && restdata.isAndroidFcm ? firebaseKeyJsonfile.fileData : "");
    formData.append("iosFcm", _get(firebaseKeyIosJsonfile, "fileData.name", "") !== "" && restdata.isIosFcm ? firebaseKeyIosJsonfile.fileData : "");
    const queryString = !_isEmpty(restdata) ? encodeGetParams(restdata) : "";
    return Post(`${apiUrl}${UpdateProfile.saveOrEditServerConfigPath}${queryString}`, formData, true);
};

/**
 * @param  {} obj={}
 * update super admin server configuration
 */
export const deleteCertificateIOSservice = (obj = {}) => {
    const { data: { fileType = "", userId = "" } = {} } = obj;
    const newObj = {
        "fileType": fileType,
        "userId": userId
    };
    const queryString = !_isEmpty(newObj) ? encodeGetParams(newObj) : "";
    return Delete(`${apiUrl}${UpdateProfile.deleteCertificate}${queryString}`, true);
};

/**
 * @param  {} obj={}
 * Delete request for client admin
 */
export const deleteRequestService = (obj = {}) => {
    const { data: { reasonForDelete = "", immediateAction = "", userId = "" } = {} } = obj;
    const newObj = {
        "immediateAction": immediateAction,
        "reasonForDelete": reasonForDelete,
        "userId": userId,
    };
    const queryString = !_isEmpty(newObj) ? encodeGetParams(newObj) : "";
    return Put(`${apiUrl}${UpdateProfile.createAccountDeleteRequest}${queryString}`, {}, true);
};

/**
 * @param  {} obj={}
 * Cancel subscription for client admin
 */

export const cancelSubscriptionRequestService = (obj = {}) => {
    const { data: { reason = "", userId = "" } = {} } = obj;
    const newObj = { cancellationReason: reason, userId: userId };
    const queryString = !_isEmpty(newObj) ? encodeGetParams(newObj) : "";
    return Put(`${apiUrl}${UpdateProfile.cancelSubscriptionRequest}${queryString}`, {}, true);
};

/**
 * @param  {} obj={}
 * Cancel subscription for client admin
 */

export const cancelSubscriptionRequestStatusService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = !_isEmpty(data) ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${UpdateProfile.cancelSubscriptionRequestStatus}${queryString}`, true);
};

/**
 * @param  {} obj={}
 * Cancel subscription for client admin
 */

export const subscriptionCancellationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = !_isEmpty(data) ? encodeGetParams(data) : "";
    return Put(`${apiUrl}${UpdateProfile.subscriptionCancellation}${queryString}`, {}, true);
};

/**
 * @param  {} obj={}
 * Cancel subscription for client admin
 */

export const sendEmptyMailToDevOpsService = (userId = 0) => {
    return Get(`${apiUrl}${UpdateProfile.sendEmailToDevopsTeam}userId=${userId}`, true);
};
