import { apiUrl, payMent } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";
import { Put, Get } from "../common/httpRestServices";

/**
 * @param  {object} obj={}
 * payMent process
 * planId is choosed plan Id
 * cusId is email
 * organisation is organisation id
 * paymentMethodId is stripe will give every time
 */
export const paymentInitCallService = (data = {}) => {
    const queryString = data ? encodeGetParams(data) : "";
    return Put(`${apiUrl}${payMent.paymentInitCall}${queryString}`, true);
};

/**
 * @param  {object} obj={}
 * 3d verfication done pass payment intent data
 */
export const paymentAuthvarificationCall = (data = {}) => {
    const queryString = data ? encodeGetParams(data) : "";
    return Put(`${apiUrl}${payMent.paymentIntentAuth}${queryString}`, true);
};

/**
 * @param  {object} obj={}
 */
export const billingInitCallService = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${payMent.billingHistory}${queryString}`, true);
};
