import "./FeatureHighlights.scss";
import React, { memo } from 'react';
import { IconGreenChecked, IconHeadingArrow, IconHeadingLine, ImgSignupBrands } from '../../../assets/images';

const FeatureHighlights = (props = {}) => {
    const { stepView = false, name = "" } = props;
    return (
        <>
            {stepView ?
                <div className={` BrandPart `}>
                    <div className='feature_highlight_wraper'>
                        <div className="feature_banner_content">
                            <h2>Complete Sign-up To Integrate <strong>Video, Voice & Chat SDKs</strong> Instantly
                                <div className="dot"></div>
                            </h2>
                            <ul>
                                <li><IconGreenChecked /><p><strong>Lifetime Free</strong> Chat SDK</p></li>
                                <li><IconGreenChecked /><p>21-days Free Trial for <strong>Video & Voice Calls</strong></p></li>
                                <li><IconGreenChecked /><p>Integrate SDKs in <strong>{"<"} 30 Mins</strong></p></li>
                                <li><IconGreenChecked /><p>Pre-built<strong> Chat UI Kit</strong></p></li>
                                <li><IconGreenChecked /><p><strong>Zero Set-up</strong> Fee </p></li>
                            </ul>
                            <div className='note'>
                                <p><strong><sup>*</sup>No Credit Card Required</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className={`BrandPart`}>
                        <div className='feature_highlight_wraper'>
                            <div className="feature_banner_content">
                                <h3>Welcome to MirrorFly, {name ? <><strong>{name}
                                    <div className="dot dot2">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </strong>  </> : null}
                                <div className="heading_arrow">
                                        <IconHeadingArrow/>
                                    </div>
                                </h3>
                                <div className="heading_line">
                                    <IconHeadingLine />
                                </div>
                                <p className='reg_content'>You are just one step away from building amazing in-app chat & calling experiences.
                                    <strong> Complete your registration now!</strong>
                                </p>
                                <div className='leading_brand'>
                                    <strong>World's Leading Brands <span style={{ color: "#0341F0" }}>Trust Us!</span></strong>
                                    <img width="374px" height="217px" src={ImgSignupBrands} alt="brands" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }</>
    );
};

export default memo(FeatureHighlights);
