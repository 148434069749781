import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { Link } from "react-router-dom";
import { ssoGmailClientId } from '../../helper/ApiUrl';
import SSOLogin from '../Login/SSOLogin';
import TermsAndPolicy from '../TermsAndPolicy';

const RegiterPageSignUpButton = (props = {}) => {

    const {
        ssoresponse = false,
        isDisabled = true,
        stepView = false,
        handleSubmit = () => { },
        onChange = () => { },
        chatCheckBox = false
    } = props || {};

    return (
        <React.Fragment>
            {stepView && <TermsAndPolicy chatCheckBox={chatCheckBox} onChange={onChange} />}
            <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                id={stepView ? "" : "registerHome"}
                disabled={isDisabled ? true : false}
                className={` ${isDisabled ? " disabled " : " "} submit btn blue`}
            >
                {stepView && !ssoresponse ? "Create Account" : "Create App"}
            </button>
            {!ssoresponse && stepView ?
                <GoogleOAuthProvider clientId={ssoGmailClientId}>
                    <SSOLogin dividerOnTop={false} />
                </GoogleOAuthProvider>
                :
                null
            }
            {!ssoresponse && stepView &&
                <>
                    <span className='signinInfo'>
                        Already have an account?
                        <Link to="/">
                            Sign In
                        </Link>
                    </span>
                </>
            }

        </React.Fragment >
    );
};
export default React.memo(RegiterPageSignUpButton);
