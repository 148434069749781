export const initAddAppVerion = {
    dataLoad: false,
    isNewListLoad: true,
    addSdkDocFile: false,
    appAddVersionData: [],
    stateAddVersion: false,
    addAppversionLoad: false,
};

export const addAppVerionReducer = (state = initAddAppVerion, action = {}) => {
    switch (action.type) {
        case 'DO_ADD_APP_VERSION_INITIAL': {
            return {
                ...state,
                ...initAddAppVerion,
            };
        }
        case 'APP_ADD_VERSION_API_LOAD': {
            return {
                ...state,
                addAppversionLoad: action.addAppversionLoad,
            };
        }
        case 'APP_ADD_VERSION_LIST': {
            return {
                ...state,
                appAddVersionData: action.appAddVersionData
            };
        }
        case 'ADD_NEW_VERSION_APP': {
            return {
                ...state,
                isNewListLoad: action.isNewListLoad
            };
        }
        case 'ADD_NEW_VERSION_APP_UPLOAD': {
            return {
                ...state,
                dataLoad: action.dataLoad
            };
        }
        case 'APP_VERSION_POPUP': {
            return {
                ...state,
                stateAddVersion: action.statePopup
            };
        }
        case 'ADD_SDK_DOC_FILE': {
            return {
                ...state,
                addSdkDocFile: action.addSdkDocFile
            };
        }
        default:
            return {
                ...state
            };
    }
};
