export const AnalyticView = {
    analyticData: {},//data
    analyticLoad: false,//loadr
    cloudUasgeLoader:false,//loadr
    recentCustomers : [],
    recentCustomersList : {}
};

export const dashBoardanalyticView = (state = AnalyticView, action = {}) => {

    switch (action.type) {
        case 'ANALYTIC_INITIAL': {//initial call
            return {
                ...state,
                ...AnalyticView
            };
        }
        case 'ANALYTIC_DATA': {//dashBoard quickover view data
            return {
                ...state,
                analyticData: action.analyticData
            };
        }
        case 'ANALYTIC_LOAD': {//loader
            return {
                ...state,
                analyticLoad: action.analyticLoad
            };
        }
        case 'CLOUD_USAGE_LOAD': {//loader
            return {
                ...state,
                cloudUasgeLoader: action.cloudUasgeLoader
            };
        }
        case 'RECENT_ACTIVE_CUSTOMERS': {
            return {
                ...state,
                recentCustomers: action.recentCustomers
            };
        }
        case 'RECENT_ACTIVE_CUSTOMERS_LIST': {
            return {
                ...state,
                recentCustomersList: action.recentCustomersList
            };
        }
        default:
            return {
                ...state
            };
    }
};
