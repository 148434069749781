import { Delete, Post } from "../common/httpRestServices";
import { apiUrl, loginApi } from "../helper/ApiUrl";

export const forgetPassReques = (obj = {}) => {
    const { data: { email = "" } = {} } = obj;
    const forgetParams = {
        "email": email
    };
    return Post(`${apiUrl}${loginApi.forgetPass}`, forgetParams);
};

/**
 *
 * @param {*} obj
 * @returns null
 * This is common delete method
 */
export const commonDeleteAPIService = (obj = {}) => {
    return Delete(obj?.url, {}, true);
};
