/** All constant values/ Objects/ Arrays export form here and use anywere in project  */

/** Month list
 * Used in Payments scrren filter |^|
 */
export const months = [
    {
        id : 1,
        code : 'Jan',
        name : 'January',
        active : false,
        enable : true
    },
    {
        id : 2,
        code : 'Feb',
        name : 'February',
        active : false,
        enable : true
    },
    {
        id : 3,
        code : 'Mar',
        name : 'March',
        active : false,
        enable : true
    },
    {
        id : 4,
        code : 'Apr',
        name : 'April',
        active : false,
        enable : true
    },
    {
        id : 5,
        code : 'May',
        name : 'May',
        active : false,
        enable : true
    },
    {
        id : 6,
        code : 'Jun',
        name : 'June',
        active : false,
        enable : true
    },
    {
        id : 7,
        code : 'Jul',
        name : 'July',
        active : false,
        enable : true
    },
    {
        id : 8,
        code : 'Aug',
        name : 'August',
        active : false,
        enable : true
    },
    {
        id : 9,
        code : 'Sep',
        name : 'September',
        active : false,
        enable : true
    },
    {
        id : 10,
        code : 'Oct',
        name : 'October',
        active : false,
        enable : true
    },
    {
        id : 11,
        code : 'Nov',
        name : 'November',
        active : false,
        enable : true
    },
    {
        id : 12,
        code : 'Dec',
        name : 'December',
        active : false,
        enable : true
    }
];

/**
 * Grace period value for API
 */
export const customDateFilterValue = 101;
export const GRACE_PERIOD_VALUE    = 'GRACE_PERIOD';
export const BILLING_CYCLE_VALUE   = 102;
export const BILLING_CYCLE_TEXT    = 'Billing Cycle';
export const ALL_DATE_FILTER_VALUE = 103;
