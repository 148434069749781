export const initAppVerion = {
    appDtls: {}, //appFull information stored
    isLoader: false, //info loader
    isListLoad: true, //loader
    popupStatus: false, //app information popUp close open
    appVersionData: {}, //listData
    initaialRender: true, //InitialValue append avoid
    getLatestVersionSDKData: {},//get latestverion data
    getLatestVersionSDKLoader: false,//get latestverion api loader
};

export const appVersionReducer = (state = initAppVerion, action = {}) => {
    switch (action.type) {
        case 'DO_APP_VERSION_INITIAL':
            {
                return {
                    ...state,
                    ...initAppVerion,
                };
            }
        case 'APP_VERSION_API_LOAD':
            {
                return {
                    ...state,
                    isListLoad: action.isListLoad,
                };
            }
        case 'APP_VERSION_API_LOAD_INITIAL':
            {
                return {
                    ...state,
                    initaialRender: action.initaialRender
                };
            }
        //
        case 'APP_VERSION_LIST':
            {
                return {
                    ...state,
                    appVersionData: action.appVersionData
                };
            }
        case 'getLatestVersionSDKLoader':
            {
                return {
                    ...state,
                    getLatestVersionSDKLoader: action.getLatestVersionSDKLoader
                };
            }
        case 'getLatestVersionSDKData':
            {
                return {
                    ...state,
                    getLatestVersionSDKData: action.getLatestVersionSDKData
                };
            }
        default:
            return {
                ...state
            };
    }
};
