export const isOffline = () => {
  return navigator.onLine ? false : true;
};

/**
 * string is html or not find out
 * @param {string} str
 */
export const isHTML = (str = "") => {
  return str.includes("</");
};

export const handleDropFilters = (ele = "") => {
  const handleDrop = ele && ele?.target?.querySelector(".custom-dropdown");
  return handleDrop?.click();
};
export const handleDropFiltersIcon = (ele = "") => {
  const handleDropIcon = ele && ele?.target?.closest(".dropFilter");
  return handleDropIcon?.click();
};

export const isEllipsisActive = (element) => {
  return element.offsetWidth < element.scrollWidth;
};
export const handleEllipsis = (SelectorAll) => {
  Array.from(document.querySelectorAll(SelectorAll)).forEach((element) => {
    if (isEllipsisActive(element)) {
      element.title = element.innerText;
    }
  });
};

// In this function we can remove all filtered values from the local storage except given value
export const clearFilterLocalStorageExcept = (exception = '') => {
  const localFilters = ['callUsageFilterData', 'paymentFilter', 'deleteRequestFilters', 'canceledListFilters', 'recentFilterData'];
  localFilters.forEach((key) => {
    if (key !== exception) localStorage.removeItem(key);
  });
};
