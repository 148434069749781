import { serverNotRespond, succToast } from "../../helper/ApiToast";
import * as paymentTypes from "../actionTypes/paymentsTypes";
import { convertToLowerCase } from "../../helper/Validation";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getPaymentInvoiceList, upgradeUserPlanTrialToEasy } from "../../services/paymentServices";
import { planAmtCalculation } from "../../components/MainContent/Settings/CustomerBillingHistory/CustomerBillingHistoryCommon";
import { sendMailToDevOps } from "../../components/MainContent/Settings/ServerConfigPage/commonServerCongig";

export const commonIndianAmtAddedLoop = (arr = []) => {
    return arr.map((ele) => {
        ele.amt = planAmtCalculation(ele);
        return ele;
    });
};

const getResponseData = (listType = "", resData = {}) => {
    const { data: { paymenthistory = [], failedpayment = [], defaulters = [], } = {} } = resData;
    switch (convertToLowerCase(listType)) {
        case convertToLowerCase("paymentlist")://paymentlist
            if (paymenthistory) {
                const newArray = commonIndianAmtAddedLoop(paymenthistory);
                return {
                    ...resData,
                    paymenthistory: newArray
                };
            }
            return resData;
        case convertToLowerCase("Defaulters")://Defaulters
            if (defaulters) {
                const newArray = commonIndianAmtAddedLoop(defaulters);
                return {
                    ...resData,
                    defaulters: newArray
                };
            }
            return resData;
        default://failedpayment
            if (failedpayment) {
                const newArray = commonIndianAmtAddedLoop(failedpayment);
                return {
                    ...resData,
                    failedpayment: newArray
                };
            }
            return resData;
    }
};

/**
 * @param {object} obj={}
 * invoiceOverviewSaga
 */
export function* invoiceViewSaga(obj = {}) {//invoiceViewSaga method
    const { data: { listType = "" } = {}
    } = obj;
    try {
        const invoiceViewServiceDetails = yield call(getPaymentInvoiceList, obj);
        const { data = {} } = invoiceViewServiceDetails || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            const responseData = getResponseData(listType, data);
            yield all([
                put({ type: "INVOICEVIEW_DATA", paymentViewData: responseData }),//getInvoiceOverview call and store
                put({ type: 'INVOICEVIEW_LOAD', paymentViewLoad: false }),//loader false
            ]);
        } else {
            serverNotRespond(message);//server is not responding call this toast
            yield all([
                put({ type: 'INVOICEVIEW_LOAD', paymentViewLoad: false }),//loader false
            ]);
        }
    } catch (error) {
        yield all([
            put({ type: 'INVOICEVIEW_LOAD', paymentViewLoad: false }),
        ]);
    }
}

export function* upgradeUserPlan() {
    try {
        yield put({ type: 'INVOICEVIEW_LOAD', paymentViewLoad: true }); // Enable Loader
        const response = yield call(upgradeUserPlanTrialToEasy);
        const { data: { status = 0, data = "" } } = response || {};

        if (status === 200) {
            succToast(data);
            yield sendMailToDevOps(true); // Trigger empty mail to DevOps team for server configuration process
        } else serverNotRespond(data);
        yield put({ type: 'INVOICEVIEW_LOAD', paymentViewLoad: false });
    } catch (error) {
        yield put({ type: 'INVOICEVIEW_LOAD', paymentViewLoad: false });
    }
}

export default function* rootPaymentPostSaga() {
    yield takeLatest(paymentTypes.GET_PAYMENT_INVOICE_OVERVIEW, invoiceViewSaga);
    yield takeLatest(paymentTypes.UPGRADE_USER_PLAN_TYPE, upgradeUserPlan);
}
