import * as appVerionTypes from '../actionTypes/appVerionTypes';

/**
 * @param  {array} customerIds
 * selected userId
 */
export const getAppVersionlist = (data = {}) => {
    return {
        type: appVerionTypes.GET_APP_VERSION_DETAILS,
        data,
    };
};

/**
 * @param  {array} customerIds
 * selected userId
 */
export const addNewAppVersionSDK = (data = {}) => {
    return {
        type: appVerionTypes.ADD_APP_VERSION_NEW,
        data,
    };
};

/**
 * @param  {array} customerIds
 * selected userId
 */
export const addNewAppVersionDOC = (data = {}) => {
    return {
        type: appVerionTypes.ADD_APP_VERSION_NEW_DCOC,
        data,
    };
};

/**
 * @param  {object} data={}
 * new app version has been added
 * pass new app verion information
 */
export const addNewApp = (data = {}) => {
    return {
        type: appVerionTypes.ADD_NEW_VERSION,
        data,
    };
};

/**
 * @param  {object} data={}
 * download SDK and Doc url
 */
export const downloadSDKandDOC = (data = {}) => {
    return {
        type: appVerionTypes.DOWNLOAD_SDK_COC,
        data,
    };
};

/**
 * @param  {object} data={}
 * get latest SDK and Doc url
 */
export const getLatestVersionSDKAction = (data = {}) => {
    return {
        type: appVerionTypes.GET_LATEST_VERSION_SDK_DOWNLOAD,
        data,
    };
};

/**
 * @param  {number} data=""
 * pass org id and get call duration details
 *
 */
export const getOrgIdBasedCallDurationCreateAction = (orgId = "") => {
    return {
        type: appVerionTypes.GET_CALL_DURATION_PASSED_ORG_ID_BASED,
        orgId,
    };
};

/**
 * @param  {object} data={}
 * download latest SDK and Doc url
 */
export const downLoadLatestVersionSDKAction = (data = []) => {
    return {
        type: appVerionTypes.DOWNLOAD_LATEST_VERSION_SDK_DOWNLOAD,
        data,
    };
};

export const downLoadLatestVersionServerConfigAction = (data = {}) => {
    return {
        type: appVerionTypes.DOWNLOAD_LATEST_VERSION_SERVER_CONFIG_DOWNLOAD,
        data,
    };
};
