import * as profileUpdateTypes from "../actionTypes/profileUpdateTypes";

/**
 * @param  {object} data={}
 */
export const profileUpdateActionCreate = (data = {}) => {
    return {
        type: profileUpdateTypes.USER_PROFILE_UPDATE,
        data,
    };
};

/**
 * @param  {object} data={}
 */
export const deleteRequestActionCreate = (data = {}) => {
    return {
        type: profileUpdateTypes.USER_ACCOUNT_DELETE_REQUEST,
        data,
    };
};

/**
 * @param  {object} data={}
 * get user server configuration
 */
export const getUserConfigurationDetails = (data = {}) => {
    return {
        type: profileUpdateTypes.GET_SERVER_CONFIGURATION,
        data,
    };
};

/**
 * @param  {object} data={}
 * update cus admin details
 */
export const updateCustomerConfigurationDetails = (data = {}) => {
    return {
        type: profileUpdateTypes.UPDATE_SERVER_CONFIGURATION,
        data,
    };
};

/**
 * @param  {object} data={}
 * update super admin
 */
export const updateSuperConfigurationDetails = (data = {}) => {
    return {
        type: profileUpdateTypes.UPDATE_SERVER_SUPER_ADMIN_CONFIGURATION,
        data,
    };
};
/**
 * @param  {object} data={}
 * update super admin
 */
export const saveOrEditSuperConfigurationDetails = (data = {}) => {
    return {
        type: profileUpdateTypes.SAVEOREDIT_SERVER_SUPER_ADMIN_CONFIGURATION,
        data,
    };
};

/**
 * @param  {object} data={}
 * delete certificate details
 */
export const deleteCertificateDetails = (data = {}) => {
    return {
        type: profileUpdateTypes.DELETE_CERTIFICATE_DETAILS,
        data,
    };
};

/**
 * @param  {object} data={}
 */
export const revokedataDetails = (data = {}) => {
    return {
        type: profileUpdateTypes.REVOKE_PROFILE,
        data,
    };
};

/**
 * @param  {object} data={}
 */
export const cancelSubscriptionActionCreate = (data = {}) => {
    return {
        type: profileUpdateTypes.USER_ACCOUNT_CANCEL_SUBSCRIPTION,
        data,
    };
};
