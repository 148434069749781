import * as stripePaymentTypes from '../actionTypes/stripePaymentTypes';

/**
 * passPayment method id
 * name,email,orgId
 */
export const paymentInitial = (data = {}) => {
    return {
        type: stripePaymentTypes.PAYMENT_INITIAL,
        data,
    };
};

/**
 * billingHistory apiCall
 */
export const billingHistoryInitialAction = (data = {}) => {
    return {
        type: stripePaymentTypes.BILLIN_HISTORY_INITIAL,
        data,
    };
};
