import * as dashBoardTypes from '../actionTypes/dashBoardTypes';

/**
 * @param  {object} data={}
 * get details quickView data
 */
export const quickViewDataGetAction = (data = {}) => {
    return {
        type: dashBoardTypes.QUICK_VIEW_GET,
        data,
    };
};

export const analyticViewDataGetAction = (data = {}) => {
    return {
        type: dashBoardTypes.ANALTTICAL_OVER_VIEW,
        data,
    };
};

export const cloudUsageAction = (data = {}) => {
    return {
        type: dashBoardTypes.CLOUD_USAGE_CREATE,
        data,
    };
};
