export const envPath = process.env.REACT_APP_ENV;//env
export const apiUrl = process.env.REACT_APP_API_URL;// base path url
export const siteUrl = process.env.REACT_APP_SITE_URL;//site urll
export const gudshoTutorials = "https://www.gudsho.com/app/mirrorfly";//docs page
export const docsPage = "https://www.mirrorfly.com/docs/";//docs page
export const secretKEY = process.env.REACT_APP_SECRET_KEY;//encrpt and decrypt key
export const recaptchaKey = process.env.REACT_APP_RECAPTCHA;//recaptch key
export const stripePkId = process.env.REACT_APP_STRIPE_PK_ID;//frondEnd id
export const uiKitBasePath = process.env.REACT_APP_UI_KIT_URL;//uikit basePath
export const googleTagManagerKey = process.env.REACT_APP_GTM_KEY;
export const socketHost = process.env.REACT_APP_XMPP_SOCKET_HOST;//env
export const loacteFineUrl = "https://ipapi.co/json/";//alter location find url
export const locationFindUrl = "https://geolocation-db.com/json/";//find loging user location
export const ssoGmailClientId = process.env.REACT_APP_SSO_GMAIL_CLIENT_ID;//sso gmail client id
export const linkedInClientId = process.env.REACT_APP_SSO_LINKEDIN_CLIENT_ID;//sso gmail client id
export const userLoaction = Intl.DateTimeFormat().resolvedOptions().timeZone;//user location
export const contactForSalse = "https://www.mirrorfly.com/contact-sales.php";//contact support team
export const ticketRaise = "https://support.mirrorfly.com/portal/en/newticket";//raise ticket url
export const contactForSalseParams = "https://www.mirrorfly.com/cloud-contact-sales.php?";//contact support team
export const UIKitUrl = "https://s3.ap-south-1.amazonaws.com/app.mirrorfly.com/UIkit.zip";//uikit download
export const docusPageMove = "https://www.mirrorfly.com/docs/#option-1-just-build-a-sample-app";
export const SDKUrl = "https://s3.ap-south-1.amazonaws.com/app.mirrorfly.com/SDK_FILES.zip";//sdkUrl
export const uiKitWeb = "https://s3.ap-south-1.amazonaws.com/app.mirrorfly.com/webSample-app.zip";
export const uiKitIos = "https://s3.ap-south-1.amazonaws.com/app.mirrorfly.com/iosSample-app.zip";
export const SampleAppUrl = "https://s3.ap-south-1.amazonaws.com/app.mirrorfly.com/Sample-app.zip";//sample app downloadUrl-samp
export const fireBaseKeyGenerate = "https://firebase.google.com/docs/cloud-messaging/android/client";
export const fireBaseKeyGenerateIOS = "https://firebase.google.com/docs/cloud-messaging/android/client";
export const uiKitAndroid = "https://s3.ap-south-1.amazonaws.com/app.mirrorfly.com/androidSample-app.zip";
export const iosVoipCertificateMake = "https://developer.apple.com/help/account/create-certificates/create-voip-services-certificates/";
export const iosApnsCertificateMake = "https://developer.apple.com/documentation/usernotifications/setting_up_a_remote_notification_server";

export const loginApi = {
    logIn: "api/login", //Login
    resetPass: "api/password/resetPassword", //resetPassword and create password
    forgetPass: "api/password/resetPassword", //forget assword email trigger
};

/**customer and Teams page user list */
export const customerApi = {
    PalnUrl: "api/customer/plans", //planDetails
    getCusList: "api/customer/list?", //getUser Details
    deleteCus: "api/customer/delete?", //delete user
    updateCus: "api/customer/update?", //userupdate
    createCus: "api/customer/create?", //new userCreate
    customerPlanChange: "api/customer/change/plan", //plan move to freemium
    getCustomerDetailUrl: "api/customer/details?", //getuser single details
    mediaDownloadUrl: "media/download/", //media download
    SDKMediaURL: "api/version/media/download/", //media download
    updateCusDomainHook: "api/customer/license/update", //update cus domain and hooks
    deleteRequestCount: "api/customer/Account/requestCount", //Delete request count
    cancelSubscriptionRequestCount: "api/customer/Subscription/requestCount", //Cancel Subscription request count
    deleteReqestList: "api/customer/Account/requestList?",//Delete request list
    deleteFromRequest: "api/customer/Account/delete?",//Delete action in delete request
    deletedList: "api/customer/Account/list?",//Deleted list
    cancelSubscriptionRequestList: "api/customer/Subscription/requestList?", //Cancel Subscription request List
    cancelledSubscriptiontList: "api/customer/Subscription/list?", //Cancel Subscription request List
    approveCanceleSubscriptionRequest: "api/customer/Subscription/cancel?", //Cancel Subscription request List
    getRoleAccess: "api/metric/roleAccess/details?",
    saveAndEditRoleAccess: "api/metric/roleAccess/createOrUpdate"
};

export const appVersion = {
    addNewVersion: "api/version/release/new", //add new app
    getAppVerionList: "api/version/release/list?", //get app version list
    getAppVerionInfo: "api/version/release/list/", //get App version Information
    getLatestVersion: "api/version/release/latest",//get latest vertion release
    mediaDownloadUrl: "media/download/", //SDK and DOC Media download url
    addNewAppVersionMedia: "api/version/media/upload", //upload sdk and doc
    getAppversionReleseHistory: "api/version/release/history?", //get app version list

};

/**sso login details */
export const ssoLoginDetails = {
    ssoLoginCheck: "api/trialUser/signup/sso",
    ssoDetaisGetGoogle: "getGoogleUserCredentials",
    ssoDetaislinkedin: "getLinkedinUserCredentials",
};

/**App release */
export const appRelease = {
    appInternalRelease: "api/version/release/create?", //App Release manage URL
    getOrgDetails: "api/customer/organisations?",//get org details
    getPlatformsList: "api/customer/sdk/list?", //get platform details
};

/** dashBoard */
export const dashBoard = {
    quickView: "api/metric/quickOverView?",//Quick view
    analyticView: "api/metric/analyticsOverView?",//analytic view
    recentActivityCustomers: "api/metric/recentActivityCustomers?",//analytic view
    getCallDuration: "api/metric/getCallsDuration",//get call duration passed org id
    getCallUsageDetailsUrl: "api/metric/getTotalCallUsage",//get call useage details
};

/** payMent */
export const payMent = {
    invoiceView: "api/payment/payment/viewdetails",//view invoice details
    paymentStatus: "api/payment/strip/payment/process?",//after payment done pass status
    getsessionId: "api/payment/strip/payment/getSession?",//pass meta data details and get session id
    paymentInitCall: "api/payment/payment/paymentprocess?",//payMent
    billingHistory: "api/payment/payment/billinghistory?",//billingHistory
    paymentIntentAuth: "api/payment/payment/paymentauthentication?",//payMent 3d auth done call
    paymentInvoiceOverview: "api/payment/payment/invoiceoverview?",//payment invoice overiew
    updateUserPlanTrialToEasy: "api/customer/update/easy",//Update user plan trial to easy
    invoiceDownload: "api/payment/payment/media/download/",//Update user plan trial to easy
};

/** setting page SDK Action */
export const SDK = {
    deleteSDK: "api/version/platform/delete",//deleteSDK
    getSDKlist: "api/version/platform/list?", // get platforms list API
    createSDK: "api/version/platform/create?", //POST create NEW Platform
    editSDKlist: "api/version/platform/update?",// PUT update platform,
};

export const passwordApi = {//change password apis
    changePwd: "api/customer/changePassword?"//change password
};

export const UpdateProfile = {//change password apis
    profileUpdate: "api/customer/updateProfile?",//change password
    deleteCertificate: "api/customer/Configuration/delete?",//delete ios certificate
    getServerConfigPath: "api/customer/Configuration/details?",//get server configuration details
    updateCustomerServerConfigPath: "api/customer/Configuration/create?",//update server config
    updateSuperAdminServerConfigPath: "api/customer/Configuration/update?",//update server config
    saveOrEditServerConfigPath: "api/customer/Configuration/saveoredit?",//save or edit server config
    revokeApiCredential: "api/customer/Configuration/updateApiPassword?",
    cancelSubscriptionRequest: "api/customer/Subscription/request?",
    createAccountDeleteRequest: "api/customer/Account/request?",
    deleteRequestStatus: "api/customer/Account/requestStatus?",
    cancelSubscriptionRequestStatus: "api/customer/Subscription/requestStatus?",
    denyDeleteRequest: "api/customer/Account/denyRequest?",
    denySubscriptionCancelRequest: "api/customer/Subscription/denyRequest?",
    subscriptionCancellation: "api/customer/Subscription/update?",
    sendEmailToDevopsTeam: "api/customer/sendEmailToDevopsTeam?",
    upgradeUsersPlan: "api/payment/payment/upgradePlan?",
    upgradeManualPayment: "api/payment/strip/payment/manualPayment/update?",
    updateEmail: "api/payment/strip/payment/emailValidation?",
    sendPasswordLink: "api/customer/sendPasswordLink?"
};

export const registerApiUrl = {//register api call
    trialUser: "api/trialUser",
    userValidate: "api/password/validateEmail",//trail user validate
    signupStepOne: "api/trialUser/signup/stepone",
};

export const createTicket = {//raise ticket
    createTicket: "api/customer/submitTicket?"
};

export const moderationApi = {//moderation
    user: "api/moderation/moderation/userList",//user list
    group: "api/moderation/moderation/groupList",//group list
    report: "api/moderation/moderation/reportList",//report list
    blockUserGroup: "api/moderation/moderation/block",//block user or group
    userCredential: "api/moderation/validateAPICredentials",//user credential
    login: "api/moderation/validateAPILogin",//login
    createUser: "api/moderation/validateCreateUserAPIAsAdmin",//createUser
    resetPassword: "api/moderation/resetPassword",//resetPassword
    sendMessage: "api/moderation/validateSendMessageAPIAsUser",//sendMessage
    chatHistory: "api/moderation/validateChatHistoryAPIAsUser",//chatHistory
    deleteUserAccount: "api/moderation/clearValidationData"//deleteUserAccount
};

export const notificationApi = {//notification
    notificationHistory: "api/metric/transactionHistory",
    notificationHistoryCount: "api/metric/transactionHistoryCount",
    notificationFilter: "api/metric/transactionType",
    notificationUpdateCount: "api/metric/updateReadNotification"
};

export const activityLog = {//notification
    adminActivities: "api/metric/getAdminActivities",
    adminActivityFilter: "api/metric/getActivityType"
};

export const taxApiHandle = {//tax id handle
    getTaxId: "api/customer/taxId/listTaxId",//get
    updateTax: "api/customer/taxId/updateTaxId",//update
};
