import React, { useState, useEffect } from "react";
import moment from 'moment';
import _get from "lodash/get";
import DynamicDropList from "./DynamicDropList";
import MonthPicker from '../DatePicker/MonthPicker';
import { IconCalendar } from "../DatePicker/assets";
import StartToEndPicker from "../DatePicker/StartToendPicker";
import OutsideClickHandler from "react-outside-click-handler";
import { IconDropdownCc, IconClose } from '../../assets/images';
import DayToYearDropDownListdatas from "./DayToYearDropDownListdatas";
import { DateTimeFormeterEnd, DateTimeFormeterStart, utcTolocalConvert } from "../../helper";
import { analyticsFilterDropdownLable } from "../../components/MainContent/Dashboard/DashBoardAnalyticsOverview/AnalyticsCommon";
import { useSelector } from "react-redux";
import { ALL_DATE_FILTER_VALUE, BILLING_CYCLE_TEXT, BILLING_CYCLE_VALUE } from "../../const/Constants";

const DayToYearDropDown = (props = {}) => {
    const {
        name = "",
        overflow = true,
        classValue = "",
        dropDownData = [],
        parentClass = "",
        dashBordMonth = false,
        selectedDate = () => { },
        dynamicOptiondata = false,
        enableMonthPicker = false,
        onChangeHandle = () => { },
        onChangeDropDown = () => {},
        stateManageOrg = {}, // Used billing cycle dates
        planTypeId = 0, // Plan type id used for dashbord filter
        DefaultDate = (enableMonthPicker || dynamicOptiondata) ? "This Month" : "Last 30 days",
    } = props;

    const gelobalStore = useSelector(s => s);
    const { selectedValue = '' } = gelobalStore.commonData || {};
    const cusDate = "Custom date";
    const defaultDate = DefaultDate || "";
    const [focusedInputData, setfocusedInputData] = useState(null);
    const [DropDown, setDropDown] = useState(false);
    const [CustomDate, setCustomDate] = useState(false);
    const [calendarActive, setCalenderActive] = useState(false);
    /**
    * @param  {object} {startDate} -moment object - convert into DateTimeFormeterStart
    * @param  {object} {endDate} - moment object - convert into DateTimeFormeterEnd
    **/
    const changeDateGroup = ({ startDate = "", endDate = "" }) => {
        const fromDate = DateTimeFormeterStart(startDate);
        const toDate = DateTimeFormeterEnd(endDate);
        if (fromDate && !endDate) {//if start date choose first end date is null
            onChangeHandle({
                startDate: moment(fromDate),
                endDate: null
            });
        } else if (toDate && !startDate) {//if end date choose first start date is null
            onChangeHandle({
                startDate: null,
                endDate: moment(toDate)
            });
        } else {
            onChangeHandle({
                startDate: moment(fromDate),
                endDate: moment(toDate)
            });
        }
    };

    /** Custom date clear **/
    const dateClewr = () => {
        onChangeHandle({
            startDate: null,
            endDate: null,
        });
    };

    /**
     * @param  {object} {val} - Get selected dropDown value
    **/
    const _handleSelect = (event = {}) => {
        dateClewr();
        const val = _get(event, "target.dataset.value", "");
        setDropDown(false);
        analyticsFilterDropdownLable(parseInt(val) === BILLING_CYCLE_VALUE ? BILLING_CYCLE_TEXT : val);
        selectedDate(val);
        if (val === cusDate) {
            setCustomDate(true);
            setfocusedInputData('startDate');
        } else {
            setCustomDate(false);
            onChangeDropDown({
                target: {
                    name,
                    value: val === "Last Month" ? "lastmonth" : "currentmonth"
                 }
            });
        }
    };
    const _handleMonthSelect = (month = {}) => {
        setDropDown(false);
        analyticsFilterDropdownLable(month.name);
        const selectedMonth = new Date().getFullYear() + '-' + month.id + '-01';
        const firstDay = moment(selectedMonth).startOf('month').format('YYYY-MM-DDT00:00');
        const lastDay = moment(selectedMonth).endOf('month').format('YYYY-MM-DDTh:m');
        onChangeHandle({
            startDate: moment(firstDay),
            endDate: moment(lastDay),
            monthName : month.name
        });
        setCustomDate(false);
    };

    const _handleDropDown = (event = {}) => {
        event.preventDefault();
        setDropDown(!DropDown);
    };

    const _handleOnOutsideClick = () => {
        setDropDown(false);
    };
    const dateClearPlac = () => {
        return (
            <React.Fragment>
                {props.endDate || props.startDate ?
                    <i id="#jestIconReset"
                        className="IconReset" onClick={dateClewr}>
                        <IconClose />
                    </i> : null
                }
            </React.Fragment>
        );
    };

    const changeDropIcon = () => {
        if (DropDown) {
            return "open";
        } return "";
    };
    useEffect(() => {
        if (props.startDate) {
            setfocusedInputData('endDate');
        }
        if (props.endDate && props.startDate) {
            setfocusedInputData(null);
            setTimeout(() => {
                const findDropEle = document.querySelector('.CalendarDay__selected_end');
                findDropEle && findDropEle.click();
            }, 500);
        }
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        if (CustomDate) {
            setfocusedInputData('startDate');
        }
        return (() => {
            setfocusedInputData(null);
        });

    }, [CustomDate]);

    const DropActive = (value) => setCalenderActive(value === true);
    return (
        <React.Fragment>
            <div data-calendar="daytoyearDrop" className={`${parentClass || " "} daytoyearDrop`}>
                <OutsideClickHandler
                    onOutsideClick={() => { _handleOnOutsideClick(); }}
                >
                    <label
                        htmlFor={classValue}
                        onClick={selectedValue !== cusDate ? (event) => _handleDropDown(event) : null}
                        className={`custom-dropdown custom-dropdown-2 ${selectedValue !== defaultDate ? " active " : ""}
                        ${selectedValue === cusDate ? " dateActive " : ""} `}
                    >
                        <i>
                            <IconCalendar />
                        </i>
                        {CustomDate ?
                            <React.Fragment>
                                <span className={`datepicker   ${calendarActive ? " active" : ""}`}>
                                    {enableMonthPicker ?
                                        <MonthPicker
                                            DropActive={(e) => DropActive(e)}
                                        />
                                        :
                                        <StartToEndPicker
                                            startDate={props.startDate}
                                            endDate={props.endDate}
                                            onChange={changeDateGroup}
                                            focusedInput={focusedInputData}
                                            dashBordMonth={dashBordMonth}
                                            onCalendarHandle={true}
                                        />
                                    }
                                </span>
                                {dateClearPlac()}
                            </React.Fragment>
                        :
                            <span>
                                {parseInt(selectedValue) === ALL_DATE_FILTER_VALUE ? 'All' : selectedValue}
                            </span>
                        }
                        <input
                            className=""
                            id={classValue}
                            type="checkbox"
                            onChange={() => { }}
                        />
                        <i
                            className={`dropIcon ${changeDropIcon()}`}
                            onClick={selectedValue === cusDate ? (event) => _handleDropDown(event) : null}
                        >
                            <IconDropdownCc />
                        </i>
                    </label>

                    {/* dropDown values */}
                    {dynamicOptiondata ?
                        <DynamicDropList
                            name={name}
                            DropDown={DropDown}
                            dropDownData={dropDownData}
                            _handleSelect={_handleSelect}
                        />
                        :
                        <DayToYearDropDownListdatas
                            overflow={overflow}
                            SelectedValue={selectedValue}
                            DropDown={DropDown}
                            _handleSelect={_handleSelect}
                            enableMonthPicker={enableMonthPicker}
                            _handleMonthSelect={_handleMonthSelect}
                            planTypeId={planTypeId}
                        />
                    }

                </OutsideClickHandler>

            {/* Showing billing Cyscle date range here */}
            {(planTypeId && selectedValue === BILLING_CYCLE_TEXT) ?
                <span className="BillingCycleDates">{utcTolocalConvert(stateManageOrg.billingStartDate)} - {utcTolocalConvert(stateManageOrg.billingEndDate)} </span> : null}
            </div>
        </React.Fragment>
        );
};
export default React.memo(DayToYearDropDown);
