import React, { useState } from "react";
import _get from "lodash/get";
import { pagesizeData } from "../MonckDataJson";
import { IconDropdownCc} from '../../assets/images';
import OutsideClickHandler from "react-outside-click-handler";

const PageSize = (props = {}) => {
    const {
        pageSize = 10,
        onChange = () => { },//click function
    } = props;
    const [dropOpen, setDropOpen] = useState(false);

    /**
     * @param  {object} event
     * per Page Data showing row render count
     * Default RowCount is 10
     */
    const _handleSelect = (event = {}) => {
        const { value = 10 } = _get(event, "target", {});
        if (pageSize !== value) {
            onChange({
                target: {
                    name: "size",
                    value: value
                }
            });
        }
    };
    const _handleOnOutside = () => {
        setDropOpen(false);
    };

    const openSizedropwn = () => {
        setDropOpen(true);
    };

    return (
        <React.Fragment>
            <label className="custom-dropdown PageSize " onClick={openSizedropwn}>
                <span className="custom-select">{pageSize}</span>
                <input
                    type="checkbox"
                    value={pageSize}
                    onChange={() => { }}
                />
                {dropOpen &&
                    <OutsideClickHandler onOutsideClick={() => { _handleOnOutside(); }}>
                        <ul>
                            {
                                pagesizeData.map((element) =>
                                    <li
                                        key={`key-${element.size}`}
                                        value={element.size}
                                        onClick={(e) => _handleSelect(e)}
                                    >
                                        {element.size}
                                    </li>)
                            }
                        </ul>
                    </OutsideClickHandler>
                }
                <i className="dropIcon"><IconDropdownCc /></i>
            </label>
        </React.Fragment>
    );
};
export default React.memo(PageSize);
