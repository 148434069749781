export const initialState = {
    loaderNotification: false,
    notificationCount: true,
    notificationList: {},
    notificationSearchData: null,
    notificationFilter: {},
    notificationUpdateCount: {},
    notificationHistoryCount: {},
};
export const notificationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'DO_NOTIFICATION_INITIAL': {
            return {
                ...state,
                ...initialState,
            };
        }

        case 'USER_NOTIFICATION_LOADER': {
            return {
                ...state,
                loaderNotification: action.loaderNotification
            };
        }
        //user
        case 'USER_NOTIFICATION_LIST': {
            return {
                ...state,
                notificationList: action.notificationList
            };
        }
        case 'USER_NOTIFICATION_COUNT': {
            return {
                ...state,
                notificationCount: action.notificationCount
            };
        }
        case 'USER_NOTIFICATION_SEARCH': {
            return {
                ...state,
                notificationSearchData: action.notificationSearchData
            };
        }
        case 'USER_NOTIFICATION_FILTER': {
            return {
                ...state,
                notificationFilter: action.notificationFilter
            };
        }
        case 'USER_NOTIFICATION_UPDATE_COUNT': {
            return {
                ...state,
                notificationUpdateCount: action.notificationUpdateCount
            };
        }
        case 'USER_NOTIFICATION_HISTORY_COUNT': {
            return {
                ...state,
                notificationHistoryCount: action.notificationHistoryCount
            };
        }
        default:
            return {
                ...state
            };
    }
};
