import moment from "moment";
import _get from "lodash/get";
import store from "../../../../store";
import { Get } from "../../../../common/httpRestServices";
import { apiUrl, dashBoard } from "../../../../helper/ApiUrl";
import { DecryptLogInDetails, encodeGetParams } from "../../../../helper/Encypt";
import { analyticViewDataGetAction } from "../../../../store/action/dashboardAction";
import { convertToLowerCase, spaceRemoveLowerCase } from "../../../../helper/Validation";
import {
    DateTimeFormeterEnd,
    DateTimeFormeterStart,
    DateTimeFormeterMilliSec,
    leadingAndTrailingspaceAvoid,
} from "../../../../helper";
import { ALL_DATE_FILTER_VALUE, BILLING_CYCLE_TEXT, BILLING_CYCLE_VALUE } from "../../../../const/Constants";

/**
 * @param  {number} orgId=""
 * @param  {number} date=30
 * seleted org id
 * seleted date
 * intialTime enter page render
 * userRoleId <=2 mean superAdmin logIn
 */
export const analyticApiCall = (orgId = "", date = 29, stateDashBoard = {}) => {//analitic view api call
    if (stateDashBoard.analyticsEndDate && stateDashBoard.analyticsStartDate) return; // If filter have any dates, then return and don't do anything
    const userDetails = DecryptLogInDetails() || {};//get local login details store
    const { data: { userRoleId = 0, organisationId = '' } = {} } = userDetails;
    store.dispatch(analyticViewDataGetAction({
        environment: "",
        organisationId: userRoleId <= 2 ? orgId : organisationId,//below userRoleId is 2 pass orgId or empty
        endDate: date === ALL_DATE_FILTER_VALUE ? "" : DateTimeFormeterMilliSec(DateTimeFormeterEnd(moment().format())),
        startDate: date === ALL_DATE_FILTER_VALUE ? "" : DateTimeFormeterMilliSec(DateTimeFormeterStart(moment().subtract(date, 'days'))),
    }));
    store.dispatch({ type: "ANALYTIC_LOAD", analyticLoad: true });
};

/**
 * @param  {number} orgId=""
 * @param  {number} date=30
 * seleted org id
 * seleted date
 * searched organisation api call
 * userRoleId <=2 mean superAdmin logIn
 */
export const customDateSearch = (orgId = "", date = {}) => {//analitic view api call
    const userDetails = DecryptLogInDetails() || {};//get local login details store
    const { data: { userRoleId = 0, organisationId = '' } = {} } = userDetails;

    store.dispatch(analyticViewDataGetAction({
        environment: "",
        endDate: date.endDate,
        startDate: date.startDate,
        organisationId: userRoleId <= 2 ? orgId : organisationId,//below userRoleId is 2 pass orgId or empty
    }));
    store.dispatch({ type: "ANALYTIC_LOAD", analyticLoad: true });
};

/**
 * @param  {string} value=""
 * @param  {string} org=""
 * dropDown seleted date
 */
export const seletedDateBaseCall = (value = "", org = "") => {//analitic view api call
    switch (value) {
        case "All":
            analyticApiCall(org, ALL_DATE_FILTER_VALUE);
            break;
        case "Today":
            analyticApiCall(org, 0);//orgId and date
            break;
        case "Last 30 days":
            analyticApiCall(org, 29);
            break;
        case "Last 7 days":
            analyticApiCall(org, 6);
            break;
        case "Last 90 days":
            analyticApiCall(org, 89);
            break;
        case "Custom date":
            customDateSearch(org, {
                startDate: DateTimeFormeterMilliSec(moment().startOf('month').format('YYYY-MM-DDT00:00')),
                endDate: DateTimeFormeterMilliSec(moment().endOf('month').format('YYYY-MM-DDT23:59')),
            });
            break;
        default:
            break;
    }
};

/**
 * @param  {string} value=""
 * @param  {string} org=""
 * dropDown seleted date
 */
export const seletedDateBaseValue = (value = "") => {//analitic view api call
    const dataArray = [
        {
            id: 1,
            value: "Today",
            date: 0
        },
        {
            id: 2,
            value: "Last 30 days",
            date: 29
        },
        {
            id: 3,
            value: "Last 7 days",
            date: 6
        },
        {
            id: 4,
            value: "Last 90 days",
            date: 89
        },
        {
            id: 5,
            value: "All",
            date: ALL_DATE_FILTER_VALUE
        },
        {
            id: 6,
            value: "Custom date",
            date: 101
        },
        {
            id: 7,
            value: BILLING_CYCLE_VALUE,
            date: BILLING_CYCLE_TEXT
        },
    ];

    const dateReturn = dataArray.find(ele => ele.value === value);
    return _get(dateReturn, "date", 0);
};

/**
 * call usage api call
 */
export const getCalluseageDetailsApiCall = (data = {}, type = "") => {
    const { filter = "", currency = "", searchTerm = "" } = data;

    const getCurrentType = () => {
        return spaceRemoveLowerCase(currency) === spaceRemoveLowerCase("usd ($)") ? "non-inr" : "inr";
    };
    const newObj = {
        ...data,
        searchTerm : leadingAndTrailingspaceAvoid(searchTerm),
        filter     : filter.split(' ').join(''),
        currency   : (convertToLowerCase(type) === "dashBoard" || currency === "All") ? "" : getCurrentType(),
        filterStartDate : DateTimeFormeterStart(data?.filterStartDate),
        filterEndDate : DateTimeFormeterEnd(data?.filterEndDate)
    };
    const queryString = encodeGetParams(newObj);
    return Get(`${apiUrl}${dashBoard.getCallUsageDetailsUrl}?${queryString}`, true);
};

/**
 * call typed baces used extra min
 * @param {object} ele
 */
export const callTypeBasedUsedMin = (ele = {}) => {
    const { callType = "", videoCallsMinis = 0, audioCallsMinis = 0 } = ele;
    if (convertToLowerCase(callType) === convertToLowerCase("Video Call")) {
        return videoCallsMinis > 1 ? `${videoCallsMinis} mins` : `${videoCallsMinis} min`;
    }
    return audioCallsMinis > 1 ? `${audioCallsMinis} mins` : `${audioCallsMinis} min`;
};

export const callTypeBasedFreeMin = (ele = {}) => {
    const { callType = "", videoCallsFreeMinis = 0, audioCallsFreeMinis = 0 } = ele;
    if (convertToLowerCase(callType) === convertToLowerCase("Video Call")) {
        return videoCallsFreeMinis > 1 ? `${videoCallsFreeMinis} mins` : `${videoCallsFreeMinis} min`;
    }
    return audioCallsFreeMinis > 1 ? `${audioCallsFreeMinis} mins` : `${audioCallsFreeMinis} min`;
};
export const callTypeBasedExceedMin = (ele = {}) => {
    const { callType = "", additionalVideoCallsMinis = 0, additionalAudioCallsMinis = 0 } = ele;
    if (convertToLowerCase(callType) === convertToLowerCase("Video Call")) {
        return additionalVideoCallsMinis > 1 ? `${additionalVideoCallsMinis} mins` : `${additionalVideoCallsMinis} min`;
    }
    return additionalAudioCallsMinis > 1 ? `${additionalAudioCallsMinis} mins` : `${additionalAudioCallsMinis} min`;
};
export const amountPayable = (ele = {}) => {
    const { currency = "",callType = "", audioAmountPayable = 0, videoAmountPayable = 0 } = ele;
    if (convertToLowerCase(callType) === convertToLowerCase("audio call")) {
        return convertToLowerCase(currency) === "inr" ? `₹${audioAmountPayable.toFixed(2)}` : `$${audioAmountPayable.toFixed(2)}`;
    }
    return convertToLowerCase(currency) === "inr" ? `₹${videoAmountPayable.toFixed(2)}` : `$${videoAmountPayable.toFixed(2)}`;
};

/**
 * if voice call mean's convert to audio call
 */
export const voiceCallCheck = (ele = {}) => {
    return convertToLowerCase(_get(ele, "callType", "")) ===
        convertToLowerCase("Audio Call") ? "Voice Call" : _get(ele, "callType", "");
};

export const analyticsFilterDropdownLable = (selectedValue = '') => {
    store.dispatch({ type: "ANALYTICS_FILTER_DROPDOWN_LABLE", selectedValue });
};
