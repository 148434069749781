import { serverNotRespond } from "../../helper/ApiToast";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as notificationTypes from '../actionTypes/notificationTypes';
import { getNotificationService,getnotificationFilter,getnotificationRequestCount,
    getnotificationUpdateCountService,getnotificationHistoryCount} from "../../services/notificationServices";

export function* getNotificationListSaga(obj = {}) { //SDK get list
    try {
        const response = yield call(getNotificationService, obj);
        const { data = {} } = response || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//inidial loader
                put({ type: "USER_NOTIFICATION_LIST", notificationList: data }),
            ]);
        } else {
            serverNotRespond(message);
            yield all([
                put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//inidial loader
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//inidial loader
        ]);
    }
}
function* getnotificationFilterSaga() {
    try {
        const response = yield call(getnotificationFilter, {});
        const { data = {} } = response || {};
        const { status = 0} = data;

        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_NOTIFICATION_FILTER", notificationFilter: data }),//Result Store
                put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//Loader open/close
                put({ type: "USER_NOTIFICATION_COUNT", notificationCount: true }),
            ]);
        } else {
            yield all([
                put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//Loader open/close
        ]);
    }
}
function* getnotificationUpdateCountSaga(obj = {}) {
    try {
        const response = yield call(getnotificationUpdateCountService, obj);
        const { data = {} } = response || {};
        const { status = 0} = data;

        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_NOTIFICATION_UPDATE_COUNT", notificationUpdateCount: data }),//Result Store
            ]);
        } else {
            yield all([
                put({ type: "USER_NOTIFICATION_UPDATE_COUNT", notificationUpdateCount: {} }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "USER_NOTIFICATION_UPDATE_COUNT", notificationUpdateCount: {} }),//Loader open/close
        ]);
    }
}
function* getnotificationHistoryCountSaga(obj = {}) {
    try {
        const response = yield call(getnotificationHistoryCount, obj);
        const { data = {} } = response || {};
        const { status = 0} = data;

        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_NOTIFICATION_HISTORY_COUNT", notificationHistoryCount: data }),//Result Store
            ]);
        } else {
            yield all([
                put({ type: "USER_NOTIFICATION_HISTORY_COUNT", notificationHistoryCount: {} }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "USER_NOTIFICATION_HISTORY_COUNT", notificationHistoryCount: {} }),//Loader open/close
        ]);
    }
}
function* getnotificationRequestCountSaga() {
    try {
        const response = yield call(getnotificationRequestCount, {});
        const { data = {} } = response || {};
        const { status = 201, totalRecords = 0 } = data;

        if (status === 200) {
            yield all([
                put({ type: "USER_NOTIFICATION_COUNT", notificationCount: totalRecords }),//Result Store
                put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//Loader open/close
            ]);
        } else {
            yield all([
                put({ type: "USER_NOTIFICATION_COUNT", notificationCount: 0 }),//Result Store
                put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "USER_NOTIFICATION_COUNT", notificationCount: 0 }),//Result Store
            put({ type: "USER_NOTIFICATION_LOADER", loaderNotification: false }),//Loader open/close
        ]);
    }
}
export default function* notificationSaga() {
    yield takeLatest(notificationTypes.GET_NOTIFICATION_LIST, getNotificationListSaga);
    yield takeLatest(notificationTypes.GET_NOTIFICATION_REQUEST_COUNT, getnotificationRequestCountSaga);
    yield takeLatest(notificationTypes.GET_NOTIFICATION_FILTER, getnotificationFilterSaga);
    yield takeLatest(notificationTypes.GET_NOTIFICATION_UPDATE_COUNT, getnotificationUpdateCountSaga);
    yield takeLatest(notificationTypes.GET_NOTIFICATION_HISTORY_COUNT, getnotificationHistoryCountSaga);

}
