import _get from "lodash/get";
import store from "../../../../../store";
import {
    addSDKAction,
    editSDKAction,
    getSDKlistData,
    deleteSDKAction,
} from "../../../../../store/action/addSDKAction";

const statusValuePass = (status = "") => {
    if (status === "All" || !status) {
        return "";
    } return status === "Active" || status === "1" ? "1" : "0";
};

export const initialStateSDK = () => {
    return ({
        page: 1,
        size: 10,
        status: "",
        sdkService: "",
    });
};

/**
 * get SDK list
 * action create
 */
export const getSDKlist = (searchData = {}) => {
    const { page = 1, size = 0, sdkService = "", status = "", deleted = false } = searchData;
    const newObj = {
        page: page,
        size: size,
        deleted: deleted,
        sdkService: sdkService,
        status: statusValuePass(status),
    };
    store.dispatch(getSDKlistData(newObj));
    store.dispatch({ type: "SDK_LOADER", loaderSDK: true });//loader
    store.dispatch({ type: "SEARCH_SDK_LIST", searchData: newObj });//loader
};

/**
 * single delete seleted platFom details
 */
export const deleteSDKservice = (deleteData = {}) => {
    store.dispatch(deleteSDKAction({ sdkIds: [_get(deleteData, "platformId", "")] }));//deleted platForm id
    store.dispatch({ type: "SDK_LOADER", loaderSDK: true });//loader
};

/**
 * bulk seleted platFom details
 */
export const bulkDeleteSDKservice = (deleteData = []) => {
    store.dispatch(deleteSDKAction({ sdkIds: deleteData }));//deleted platForm id
    store.dispatch({ type: "SDK_LOADER", loaderSDK: true });//loader
};

/**
 * edit platForm
 */
export const editSDKserviceCommon = (editID = {}) => {
    store.dispatch(editSDKAction(editID));//deleted platForm id
    store.dispatch({ type: "SDK_LOADER", loaderSDK: true });//loader
};

/**
 * edit platForm
 */
export const createSDKserviceCommon = (data = {}) => {
    const formData = new FormData();
    formData.append("platformLogo", data.imgFile);
    const newObj = {
        platformName: data.sdkService,
        productName: data.sdkProductName.split(',') || [],
        status: "" + data.status === "true" ? 1 : 0,
    };
    store.dispatch(addSDKAction({ newObj, formData }));//create new SDK
    store.dispatch({ type: "SDK_LOADER", loaderSDK: true });//loader
};

/**
 * pageLeave clear data
 */
export const onPageClearSDKstore = () => {
    store.dispatch({ type: "ADD_SDK_INITIAL" });//loader
};
