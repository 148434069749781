import React, { useState, useEffect } from 'react';
import './Login.scss';
import _get from "lodash/get";
import Loader from "../../common/Loader";
import { isOffline } from '../../common/helper';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { doLogin } from '../../store/action/loginAction';
import { leadingAndTrailingspaceAvoid } from '../../helper';
import AccountActiveAlert from './AccountActiveAlert/index';
import { failToast, succToast, serverNotRespond } from '../../helper/ApiToast';
import {
    userLogRoleId,
    loginEmailValidate,
    errorValidateLoginPage,
    emptyInputErrorMsgThrow,
    findEmptyOrNotLoginInput,
} from './loginPageCommon';
import {
    validateForm,
    EmailValidate,
    passwordValidate,
    passwordEncrypt,
} from "../../helper/Validation";
import LoginInputField from './LoginInputField';
import { DecryptLogInDetails } from '../../helper/Encypt';

//Password Show or Hide Function
const passwordEyes = (type = "password") => {
    return type === "password" ? "text" : "password";
};

const Login = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { search = "" } = location;
    const globalStore = useSelector((state) => state || {});//store
    const { CusPage: { customerDtls = {} } } = globalStore;
    const { isServerConfiguration = 0, isEmailVerified = 0 } = customerDtls; const history = useHistory();
    const [stateOfLoginPage, setStateOfLoginPage] = useState({
        accontActive: false,
        emailActiveLoader: false,
    });
    const [ErrorName, setErrorName] = useState(false);
    const [isValidForm, setValidForm] = useState(false);
    const [ErrorPassword, setErrorPassword] = useState(false);
    const [spaceOnlyError, setSpaceOnlyError] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const { loginPage: { isLoading = false } = {} } = globalStore;

    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });
    const [errinputPass, seterrinputPass] = useState({
        email: false,
        password: false,
        onlySpace: true,
    });
    const [onErrinputPass, setonErrinputPass] = useState({
        email: false,
        password: false,
    });

    /**
     * _onLogin submit is make a Login trigger
     * doLogin create Login sagas action
     */
    const _onLogin = (e = {}) => {
        if (isOffline()) {
            serverNotRespond("Please check your internet connection");
        }
        e.preventDefault();
        if (isValidForm) {
            const logData = {
                "password": passwordEncrypt(inputValue.password),
                "email": leadingAndTrailingspaceAvoid(inputValue.email),
            };
            dispatch(doLogin(logData));
            dispatch({ type: "DO_LOADING_LOGIN_PAGE", loading: true });

        } else {
            setonErrinputPass({
                ...onErrinputPass,
                email: emptyInputErrorMsgThrow(inputValue.email),
                password: emptyInputErrorMsgThrow(inputValue.password),
            });
        }
    };

    const onErrorManage = (error = {}) => {
        const { email, password, onlySpace } = error; //on email and password type on field validate
        email && setErrorName(false);
        password && setErrorPassword(false);
        onlySpace && setSpaceOnlyError(false);
    };

    //OnBlur use user enter email or password throw validate
    const errorValidateShow = () => {
        const errors = errinputPass;
        errors.email = EmailValidate(inputValue.email);
        setSpaceOnlyError(false);
        if (!inputValue.email) {
            errors.onlySpace = true;
        }
        errors.password = passwordValidate(inputValue.password);
        seterrinputPass(errors);
        setValidForm(validateForm(errors));
        onErrorManage(errors);
        const { email, password, onlySpace } = errors; //on email and password type on field validate
        setErrorName(errorValidateLoginPage(inputValue.email, email));
        setErrorPassword(errorValidateLoginPage(inputValue.password, password));
        setSpaceOnlyError(errorValidateLoginPage(inputValue.email, onlySpace));
    };

    /**
     * Handle user Email and password value
     * @param  {Object} {event} -
     */
    const onChangeHandler = (event = {}) => {
        event.preventDefault();
        const { name = "", value = "" } = _get(event, "target", {});
        const errors = errinputPass;
        switch (name) {
            case 'email':
                errors.email = EmailValidate(value);
                break;
            case 'password':
                errors.password = passwordValidate(value);
                break;
            default:
                break;
        }
        onErrorManage(errors);
        setInputValue({ ...inputValue, [name]: value });
        setValidForm(validateForm(errors));
        setonErrinputPass({
            ...onErrinputPass,
            email: findEmptyOrNotLoginInput(inputValue.email),
            password: findEmptyOrNotLoginInput(inputValue.password)
        });
    };

    const handleEnterPress = (e = {}) => {
        if (e.code === "Enter") {
            errorValidateShow();
        }
    };

    const passwordEye = () => {
        const passType = passwordEyes(passwordType);
        setPasswordType(passType);
    };

    //active email close
    const handleClose = () => {
        setStateOfLoginPage({
            ...stateOfLoginPage,
            accontActive: false
        });
    };

    /**
     * user api call
     */
    const trailUserValidateCall = async () => {
        if (isOffline()) {
            return;
        }
        setStateOfLoginPage({
            ...stateOfLoginPage,
            emailActiveLoader: true
        });
        const response = await loginEmailValidate(search);
        const { data: { message = "", status = 0 } = {} } = response || {};
        if (status === 200) {
            setStateOfLoginPage({
                ...stateOfLoginPage,
                accontActive: true,
                emailActiveLoader: false,
            });
            succToast(message);
            window.history.pushState({}, null, "/");
        } else {
            if (isOffline()) {
                return;
            }
            setStateOfLoginPage({
                ...stateOfLoginPage,
                accontActive: false,
                emailActiveLoader: false,
            });
            failToast(message);
            window.history.pushState({}, null, "/");
        }
    };

    useEffect(() => {
        if (isOffline()) {
            return '';
        }
        if (search) {
            trailUserValidateCall();
        }

    }, [search]);

    /**
    * User login successfully redirect To DashBoard Page or dynamic redirectPage
    */
    if (window.sessionStorage.getItem("userData")) {
        const userRoleId = userLogRoleId();
        const userDetails = DecryptLogInDetails();
        const { data: { userPlanTypeId = 0 } = {} } = userDetails;//logedinUserDetails

        if (userRoleId === 4 && (userPlanTypeId === 4 || userPlanTypeId === 5 || userPlanTypeId === 6) && (isServerConfiguration === 2) && (isEmailVerified !== 0)) {
            setTimeout(() => {
                history.push("/overview");
            }, 1000);
        } else {
            setTimeout(() => {
                const defaultPathName = userRoleId >= 4 ? "/overview" : "/analytics";
                history.push(defaultPathName);
            }, 1000);
        }
    }

    return (
        <React.Fragment>
            {(isLoading || stateOfLoginPage.emailActiveLoader) &&
                <Loader imageLoader={false} type={"fixed overlay"} />
            }
            <span id="CollectedForms-619567"></span>
                <div className="LoginWrapper Custom">
                    <div className='Container'>

                        {stateOfLoginPage.accontActive &&
                            <AccountActiveAlert
                                handleClose={handleClose} />
                        }
                        {/* <LoginBrandInfo/> */}

                        <div className="LoginInner grid">
                            <div className="formWrapper grid-12">
                                <LoginInputField
                                    _onLogin={_onLogin}
                                    ErrorName={ErrorName}
                                    inputValue={inputValue}
                                    passwordEye={passwordEye}
                                    passwordType={passwordType}
                                    ErrorPassword={ErrorPassword}
                                    spaceOnlyError={spaceOnlyError}
                                    onErrinputPass={onErrinputPass}
                                    onChangeHandler={onChangeHandler}
                                    handleEnterPress={handleEnterPress}
                                    errorValidateShow={errorValidateShow}
                                />
                                {/* <Footer CustomClass={"loginFooter"} /> */}
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment >
    );
};
export default React.memo(Login);
