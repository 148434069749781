const superAdmin = "Super Admin";
const releaseManager = "Release Manager";
const superAdminDesc = "All access including billing.";
const developerDesc = "Limited to using the application for development purposes.";
const moderatorDesc = "Will have access to overseeing User and Group interactions and Content."
const adminDesc = "Complete access, including payment and creating the team.";

export const chatData = [
    { date1: "Jan", "Total_invoice": "70%", "collect_statement": 10, "over_due_statement": 0, status: "P" },
    { date1: "Feb", "Total_invoice": "10%", "collect_statement": 15, "over_due_statement": 10, status: "L" },
    { date1: "Mar", "Total_invoice": "40%", "collect_statement": 60, "over_due_statement": 15, status: "P" },
    { date1: "Apr", "Total_invoice": "50%", "collect_statement": 78, "over_due_statement": 20, status: "P" },
    { date1: "May", "Total_invoice": "50%", "collect_statement": 50, "over_due_statement": 28, status: "P" },
    { date1: "Jun", "Total_invoice": "70%", "collect_statement": 65, "over_due_statement": 30, status: "L" },
    { date1: "Jul", "Total_invoice": "60%", "collect_statement": 60, "over_due_statement": 25, status: "P" },
    { date1: "Aug", "Total_invoice": "30%", "collect_statement": 80, "over_due_statement": 30, status: "P" },
    { date1: "Sep", "Total_invoice": "70%", "collect_statement": 58, "over_due_statement": 23, status: "L" },
    { date1: "Oct", "Total_invoice": "60%", "collect_statement": 65, "over_due_statement": 30, status: "L" },
    { date1: "Nov", "Total_invoice": "60%", "collect_statement": 75, "over_due_statement": 20, status: "P" },
    { date1: "Dec", "Total_invoice": "90%", "collect_statement": 85, "over_due_statement": 35, status: "P" },
];

export const chatDataHome = [
    { date: "JAN", usage: 65 },
    { date: "MAR", usage: 50 },
    { date: "MAY", usage: 68 },
    { date: "JUL", usage: 46 },
    { date: "SEP", usage: 90 },
    { date: "OCT", usage: 68 },
    { date: "DEC", usage: 68 },
];

export const planDetails = [
    { planName: "All" },
    { planName: "Custom plan" },
    { planName: "Pro plan" },
    { planName: "Trial" }
];

export const knowledgeBase = [
    { planName: "All" },
    { planName: "Messaging" },
    { planName: "Voice" },
    { planName: "Video" },
    { planName: "Data & Moderation" },
    { planName: "Security" },
    { planName: "Scalability" },
    { planName: "Integration" },
];

export const uType = [
    { id: 0, planName: "All" },
    { id: 1, planName: "Active" },
    { id: 2, planName: "Inactive" }
];

export const ownCustomers = [
    { id: 0, planName: "All", value: '' },
    { id: 1, planName: "With Contus", value: 'withContus' },
    { id: 2, planName: "Without Contus", value: 'withoutContus' }
];
export const teamCustomers = [
    { id: 1, planName: "With Teams", value: 'withTeams' },
    { id: 2, planName: "Without Teams", value: 'withoutTeams' }
];
export const callusageCost = [
    { id: 0, planName: "All", value: "" },
    { id: 1, planName: "USD ($)", value: "non-inr" },
    { id: 2, planName: "INR (₹)", value: "inr" },
];

export const callusageFillter = [
    { id: 0, planName: "All" },
    { id: 1, planName: "Exceed Minutes" },
    { id: 2, planName: "Alert" }
];

export const license = [
    { id: 1, planName: "All" },
    { id: 2, planName: "Active" },
    { id: 0, planName: "Expired" }
];

export const filterByData = [
    { planName: "Verified Customers", id: "verifiedCustomer" },
    { planName: "Paid Customers", id: "paidCustomer" },
    { planName: "Grace Period", id: 'gracePeriod' }
];

export const filterByDate = [
    { planName: "Under Integration", id: "underIntegrated" },
    { planName: "User Integrated", id: "userIntegrated" },
];
export const pagesizeData = [
    { size: 10 },
    { size: 20 },
    { size: 30 },
    { size: 40 },
    { size: 50 },
    { size: 100 },
];

export const PaymentbyOption = [
    { planName: "All" },
    { planName: "Debit Card" },
    { planName: "Net Banking" },
    { planName: "BHIM UPI" }
];

//admin portal roles
export const Role = [
    { planName: "All" },
    { planName: superAdmin },
    { planName: "Admin" },
    { planName: releaseManager }
];

//admin portal roles
export const adminRole = [
    { planName: "All" },
    { planName: "Admin" },
    { planName: releaseManager }
];

//customer portal roles
export const customerTeams = [
    { "planName": "All" },
    { "planName": "Admin" },
    { "planName": "Moderator" },
    { "planName": "Developer" },
];
export const customerAdminTeams = [
    { "planName": "All" },
    { "planName": "Moderator" },
    { "planName": "Developer" },
];
export const cusStatus = [
    { planName: "All" },
    { planName: "Block" },
    { planName: "Deactive" },
];
export const cusActiveInactive = [
    { id: "2", value: "false", name: "Inactive" },
    { id: "1", value: "true", name: "Active" }
];

export const usersExtendData = [
    { id: "0", value: "0", planName: "Select Options" },
    { id: "3", value: "5", planName: "5 Users" },
    { id: "2", value: "10", planName: "10 Users" },
    { id: "1", value: "20", planName: "20 Users" },
];

export const licenceExtendDo = [
    { id: "0", value: "0", planName: "Select Options" },
    { id: "4", value: "7", planName: "7 days" },
    { id: "3", value: "15", planName: "15 days" },
    { id: "2", value: "21", planName: "21 days" },
    { id: "1", value: "30", planName: "30 days" },
];

export const licenceExtendNull = [
    { id: "0", value: "0", planName: "Select Options" },
];
//superAdmin portal roles
export const superAdminRole = [
    { id: "1", value: "portalsuperadmin", planName: superAdmin, desc: superAdminDesc },
    { id: "2", value: "portaladmin", planName: "Admin", desc: adminDesc },
    { id: "3", value: "portaldeveloper", planName: releaseManager, desc: developerDesc },
];

//superAdmin portal roles
export const AdminRoleTeams = [
    { id: "2", value: "portaladmin", planName: "Admin", desc: adminDesc },
    { id: "3", value: "portaldeveloper", planName: releaseManager, desc: developerDesc },
];

//customer portal roles
export const superCustomerRole = [
    { id: "5", value: "customeradmin", planName: "Admin", desc: adminDesc },
    { id: "6", value: "customermoderator", planName: "Moderator", desc: moderatorDesc },
    { id: "7", value: "customerdeveloper", planName: "Developer", desc: developerDesc }
];
export const superCustomerAdminRole = [
    { id: "6", value: "customermoderator", planName: "Moderator", desc: moderatorDesc },
    { id: "7", value: "customerdeveloper", planName: "Developer", desc: developerDesc }
];
//ReleaseType in appversion page
export const releaseTypes = [
    { id: "0", value: "select", planName: "Select Release Type" },
    { id: "1", value: "major", planName: "Major" },
    { id: "2", value: "minor", planName: "Minor" },
    { id: "3", value: "patch", planName: "Patch" }
];

export const planValue = [
    {
        "id": 1,
        "planName": "All"
    },
    {
        "id": 2,
        "planName": "Trial"
    },
    {
        "id": 3,
        "planName": "Pro User"
    },
    {
        "id": 4,
        "planName": "Custom Plan"
    }
];

//AppversionPage add new version app
//this is not useing any where but this demeo
export const platformValue = [
    {
        "id": 1,
        "planName": "Android",
        "android": false,
        "isCheck": false,
        value: "android",
        nameSDK: "",
        nameDOC: "",
        urlSDK: "",
        urlDOC: "",
        "size": 0,
        "docSize": 0
    },
];

//userRoleId
export const userRoleIdDetails = [
    { id: 1, value: "portalsuperadmin", planName: superAdmin, desc: superAdminDesc },
    { id: 2, value: "portaladmin", planName: "Admin", desc: adminDesc },
    { id: 3, value: "portaldeveloper", planName: releaseManager, desc: developerDesc },
    { id: 4, value: "customeradmin", planName: "Admin", desc: superAdminDesc },
    { id: 5, value: "customeradmin", planName: "Admin", desc: adminDesc },
    { id: 6, value: "customermoderator", planName: "Moderator", desc: moderatorDesc },
    { id: 7, value: "customerdeveloper", planName: "Developer", desc: developerDesc },
];

export const ticketPriority = [
    {
        id: 1,
        planName: "All",
    },
    {
        id: 2,
        planName: "Android",
    },
    {
        id: 3,
        planName: "iOS",
    },
    {
        id: 4,
        planName: "Web",
    },
    {
        id: 5,
        planName: "Server",
    }
];

//call usage mock
export const barChartMonckData = [
    {
        id: 1,
        name: 'Voice call',
        extra: 20,
        callMin: 89,
        limit: "cross",
        label: 109,//cross mean is label
    },
    {
        id: 2,
        name: 'Video call',
        extra: 9,
        callMin: 91,
        limit: "nocross",
        label: 91,//nocross mean callMin is label
    },
];

/** Customer Admin Subscription status values */
export const subscriptionStatus = {
    NEW: "0",
    REQUEST_SENT: "1",
    COMPLETED: "2",
    UNSUBSCRIBED: "3"
};

export const mockCancelSubscription = [
    { id: "3", value: subscriptionStatus.UNSUBSCRIBED, planName: "Unsubscribed" },
    { id: "2", value: subscriptionStatus.COMPLETED, planName: "Completed" },
];

export const callUssageInfoMockData = [
    { id: 1, isSelect: false, optionData: "This Month", value: "currentmonth" },
    { id: 2, isSelect: false, optionData: "Last Month", value: "lastmonth" },
];

export const MAUCountValues = [
    { id: "0", value: 5, planName: "5k Users" },
    { id: "1", value: 10, planName: "10k Users" },
    { id: "2", value: 20, planName: "20k Users" },
    { id: "3", value: 30, planName: "30k Users" },
];

export const roleArray = [
    { id: 1, value: "App Developer", planName: "App Developer" },
    { id: 2, value: "Technology Architect", planName: "Technology Architect" },
    { id: 3, value: "Project Manager", planName: "Project Manager" },
    { id: 4, value: "Sales & Marketing", planName: "Sales & Marketing" },
    { id: 5, value: "C-Level Executive", planName: "C-Level Executive" },
];

export const appStatusArray = [
    { id: 1, value: "App Under Developement", planName: "App Under Developement" },
    { id: 2, value: "App launched & Live", planName: "App launched & Live" },
];
export const appCategoryArray = [
    { id: 1, value: "Social", planName: "Social" },
    { id: 2, value: "Productivity", planName: "Productivity" },
    { id: 3, value: "Entertainment", planName: "Entertainment" },
    { id: 4, value: "Educational", planName: "Educational" },
    { id: 5, value: "Shopping", planName: "Shopping" },
];
export const userCountArray = [
    { id: 1, value: "0 - 999", planName: "0 - 999" },
    { id: 2, value: "1000+", planName: "1000+" },
    { id: 3, value: "10K+", planName: "10K+" },
    { id: 4, value: "50K+", planName: "50K+" },
    { id: 5, value: "100K+", planName: "100K+" },
];
export const gracePeriodArray = [
    { id: 1, value: "30", planName: "30 Days" },
    { id: 2, value: "60", planName: "60 Days" },
];
