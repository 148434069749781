import * as loginTypes from '../actionTypes/loginTypes';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { fetchLogin, registerUserService } from "../../services/LoginServices";
import { succToast, serverNotRespond, failToast } from "../../helper/ApiToast";
import { Encrypt, EncryptLogInDetails, internetStatusEncrypt, userDetailsSet } from "../../helper/Encypt";
import store from '..';
import { doLogin } from '../action/loginAction';
import mixpanel from 'mixpanel-browser';
import { getCustDtlsNew } from '../action/customeAction';

export function* doUserLogin(obj = {}) { //Login Action Created
    // const obj = {data:{"email":"email","password": "password"}}
    try {
        const loginData = yield call(fetchLogin, obj);
        const { data = {} } = loginData || {};
        if (data.status === 200) {
            mixpanel.track("Signin success", {
                email: obj.data.email
            });
            mixpanel.identify(data.data.email);
            mixpanel.people.set({
                "$name": data.data.firstName,
                "$email": data.data.email
            });
            Encrypt(obj.data);//login Details Store encrpt username and pass
            succToast(data.message);
            userDetailsSet(data);//if developer store userDetails in sessionStorage for developer purpose
            EncryptLogInDetails(data);//user Data pass
            internetStatusEncrypt(true);//online status true
            yield getCustDtlsNew({ customerId : data.data.userId });
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
                put({ type: "DO_LOGIN_SUCCES", logingData: data, loginStatus: true }),//loginData Store
            ]);
        } else if (data.status >= 201 && data.status <= 400) {
            mixpanel.track("Signin failure", {
                email: obj.data.email,
                errorMessage: data.message
            });
            failToast(data.message);
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
                put({ type: "DO_LOGIN_FAIL", loginStatus: false }),//login status
            ]);
        } else {
            mixpanel.track("Signin failure", {
                email: obj.data.email,
                errorMessage: data.message
            });
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
                put({ type: "DO_LOGIN_FAIL", loginStatus: false }),//login status
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "DO_LOADING_LOGIN_PAGE", loading: false })]);//lodering on / off
    }
}

const passToLogin = async (obj = {}) => {
    const { data: { password = "", emailId = "", ssoType = "" } = {} } = obj;

    const newObj = {
        email: emailId,
        ssoType: ssoType,
        password: password,
    };
    store.dispatch(doLogin(newObj));
};

export function* doRegisterUserSaga(obj = {}) { //Login Action Created
    // const obj = {data:{"email":"email","password": "password"}}
    try {
        const registerResponse = yield call(registerUserService, obj);
        const { data: { status = 0, message = "" } = {} } = registerResponse;
        if (status === 200) {
            mixpanel.track("Signup success", {
                ...obj.data,
                email: obj.emailId
            });
            const { data: { ssoType = "", ssoId = "" } = {} } = obj;

            if (ssoType) {
                const newObj = {
                    ...obj,
                    password: ssoId,
                    ssoType: ssoType,
                };
                passToLogin(newObj);
            } else {
                passToLogin(obj);
            }
            mixpanel.identify(obj.data.emailId);
            mixpanel.people.set({
                "$name": obj.data.firstName,
                "$email": obj.data.emailId
            });
            passToLogin(obj);
            yield all([
                put({ type: "REGISTER_USER_LOADER", loading: false }),
            ]);//lodering on / off
        } else {
            mixpanel.track("Signup failure", {
                ...obj.data,
                email: obj.emailId,
                errorMessage: message
            });
            failToast(message);
            yield all([
                put({ type: "REGISTER_USER_LOADER", loading: false }),
            ]);//lodering on / off
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "REGISTER_USER_LOADER", loading: false }),
        ]);//lodering on / off
    }
}
export default function* rootLoginPostSaga() {
    yield takeLatest(loginTypes.DO_LOGIN, doUserLogin);
    yield takeLatest(loginTypes.REGISTER_NEW_USER, doRegisterUserSaga);
}
