import React, { useState, Fragment } from "react";
import "react-dates/initialize";
import _get from "lodash/get";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import moment from 'moment';

const SingleDatePickers = (props = {}) => {
    const {
        startDate = null,
        onChange = () => { },
        onOpenCalendar = () => { },
        onCalendarHandle = false,
        isOutsideRange = () => { },
    } = props;

    const [initfocused, setFocused] = useState(false);
    const onFocusChange = ({ focused: newFocused }) => {
        setFocused(newFocused);

        if (newFocused == null) {
            onCalendarHandle && onOpenCalendar(false);
            // You can perform any other logic related to losing focus here
        } else {
            onCalendarHandle && onOpenCalendar(true);
            // You can perform any other logic related to gaining focus here
        }
    };
    const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        const year = [];
        for (let i = moment().year(); i <= moment().year() + 100; i++) {
            year.push(<option value={i} key={`year-${i}`}>{i}</option>);
        }
        return (
            <div className="MultiDate">
                <select className="month" value={month.month()} onChange={e => onMonthSelect(month, _get(e, "target.value", ""))}>
                    {moment.months().map((label, value) => (
                        <option value={value} key={`key-${label}`}>{label.substring(0, 3)}</option>
                    ))}
                </select>
                <select className="year" value={month.year()} onChange={e => onYearSelect(month, _get(e, "target.value", ""))}>
                    {year}
                </select>
            </div>
        );
    };
    return (
        <Fragment>
            <SingleDatePicker
                readOnly={true}
                numberOfMonths={1}
                onDateChange={(fromDate) => onChange(fromDate)}
                onFocusChange={onFocusChange}
                id="user_start_date_id"
                focused={initfocused}
                date={startDate ? startDate : null}
                renderMonthElement={renderMonthElement}
                displayFormat="MMM DD,YYYY"
                placeholder={"MM/DD/YYYY"}
                isOutsideRange={isOutsideRange}
            />
        </Fragment>
    );
};
export default SingleDatePickers;
