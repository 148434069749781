export const initialBillingHistory = {
    billingHistoryLoad: false,
    billingHistoryData: {},
};

export const initialBillingHistoryReducer = (state = initialBillingHistory, action = {}) => {
    switch (action.type) {
        case 'DO_LOADING_BILLING_HISTORY_INITIAL': {
            return {
                ...state,
                ...initialBillingHistory,
            };
        }
        case 'DO_LOADING_BILLING_HISTORY_LOAD': {
            return {
                ...state,
                billingHistoryLoad: action.billingHistoryLoad,
            };
        }
        case 'DO_LOADING_BILLING_HISTORY_DATA': {
            return {
                ...state,
                billingHistoryData: action.billingHistoryData,
            };
        }
        default:
            return {
                ...state
            };
    }
};
