import store from "../../../store";
import { Get } from "../../../common/httpRestServices";
import { apiUrl, payMent } from "../../../helper/ApiUrl";
import { getCustDtls } from "../../../store/action/customeAction";
import { getOrgIdBasedCallDurationCreateAction } from "../../../store/action/appversionAction";
import {
    DateTimeFormeterStart,DateTimeFormeterEnd
} from "../../../helper";

/**
 * @param  {number} userId=0
 * userId
 * get customerDetails
 */
export const callCusDetails = (userId = 0) => {
    store.dispatch({ type: "DO_LOADING_EDIT", loading: true }); //loader when editing customerdetails
    store.dispatch(getCustDtls({ customerId: userId })); //userId
};

// after upload profile api call initial
export const afterReloadProfileApiRemoveStoreValue = () => {
    store.dispatch({ type: "PROFILE_UPDATE_RELOAD", profileUpdateStatusReload: false }); //
};

/**
 * page onLeave clear store
 */
export const removeCusDetails = () => {
    store.dispatch({ type: "INITIAL_STAGE" }); //loader when editing customerdetails
};

/**
 * openand close AddSDKPOPup
 */
export const openAddSDKPOPup = (value = false) => {
    store.dispatch({ type: "SDK_POP_UP_OPEN_CLOSE", sdkPopUp: value });
};

/*
*get org based call duration count
*/
export const getOrganisationBasedCallDuration = (orgDetails = {}) => {
    const { userId = 0, filterStartDate, filterEndDate } = orgDetails;
    const newObj = {
        userId: userId,
        filterStartDate: DateTimeFormeterStart(filterStartDate),
        filterEndDate: DateTimeFormeterEnd(filterEndDate),
    };
    store.dispatch(getOrgIdBasedCallDurationCreateAction(newObj));
    store.dispatch({ type: "GET_ORG_BASED_CALL_DURATION_LOADER", callDurationLoader: true });
};
/**
 * invoice view
 */
export const viewBillingInvoiceDetails = (invoiceId = 0) => {
    return Get(`${apiUrl}${payMent.invoiceView}?uniqeId=${invoiceId}`, true);
};

/**
 * Update plan type trial to Easy manuaaly
 */
export const upgradeUserPlanTrialToEasy = async (userId = 0) => {
    await store.dispatch({ type: "UPGRADE_USER_PLAN_TYPE" });
};
