export const cusTeamInitial = {
    cusTeamsData: {},//cus teams data
    Hookloader: false,//loader
    cusTeamLoad: false,//loader
    hookReload: false,// * :this hookReload when upload hoor and domain update pofile
};

export const cusTeamsReducer = (state = cusTeamInitial, action = {}) => {

    switch (action.type) {
        case 'CUS_TEAMS_INITIAL_STAGE': {//inital call
            return {
                ...state,
                ...cusTeamInitial
            };
        }
        case 'CUS_TEAMS_LIST_LOADER': {//loader
            return {
                ...state,
                cusTeamLoad: action.cusTeamLoad
            };
        }
        case 'CUS_TEAMS_LIST_DATA': {//cus teams data
            return {
                ...state,
                cusTeamsData: action.cusTeamsData
            };
        }
        case 'UPDATE_DOMAIN_HOOK_LOADER': {//loader
            return {
                ...state,
                Hookloader: action.Hookloader
            };
        }
        case 'HOOK_UPDATE_RE_LOAD_PROFILE': {//loader
            return {
                ...state,
                hookReload: action.hookReload
            };
        }
        default:
            return {
                ...state
            };
    }
};
