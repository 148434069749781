import { Get, Put } from "../common/httpRestServices";
import { encodeGetParams } from "../helper/Encypt";
import { apiUrl, payMent } from "../helper/ApiUrl";

/**
 * @param  {object} obj={}
 * payment page pass params
 */
export const getPaymentInvoiceList = (obj={}) => {
    const { data } = obj || {};
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${payMent.paymentInvoiceOverview}${queryString}`, true);
};

export const upgradeUserPlanTrialToEasy = () => {
    return Put(`${apiUrl}${payMent.updateUserPlanTrialToEasy}`, true);
};
