export const teamInitState = {
    addMember: true,
    membersData: {},
    memberlistloader: false,
    memberSearchData: null,
    loaderRerender: false,
    roleAccessLoader:false,
    callUsageData : {},
    roleAccessList : {},
    saveAndEditData : {},
    sideMenuDtls: {},
};

export const teamsReducer = (state = teamInitState, action = {}) => {
    switch (action.type) {
        //when page enter all reducer value clear
        case 'TEAM_INITIAL_STAGE': {
            return {
                ...state,
                ...teamInitState
            };
        }
        //all reasult data store here
        case 'GET_MEMBER_LIST_DATA': {
            return {
                ...state,
                membersData: action.MemberData
            };
        }
        case 'GET_CALL_USAGE_LIST_DATA': {
            return {
                ...state,
                callUsageData: action.callUsageData
            };
        }
        case 'TEAM_MEMBER_RELOAD_LOADER': {
            return {
                ...state,
                loaderRerender: action.loading
            };
        }
        //initial load and search load
        case 'GET_MEMBER_LIST_DATA_LOAD': {
            return {
                ...state,
                memberlistloader: action.memberListLoad,
            };
        }
        // userSearch data will store otherwise null
        case 'SEARCH_MEMBER_DATA': {
            return {
                ...state,
                memberSearchData: action.memberSearch,
            };
        }
        case 'GET_ROLE_ACCESS_DATA': {
            return {
                ...state,
                roleAccessList: action.roleAccessList,
            };
        }case 'GET_ROLE_ACCESS_DATA_LOAD': {
            return {
                ...state,
                roleAccessLoader: action.roleAccessLoader,
            };
        }

        case 'SAVE_AND_EDIT_DATA': {
            return {
                ...state,
                saveAndEditData: action.saveAndEditData,
            };
        }
        case 'SIDEMENU_DETAILS': {
            return {
                ...state,
                sideMenuDtls: action.sideMenuDtls
            };
        }
        default:
            return {
                ...state
            };
    }
};
