import _get from "lodash/get";
import store from "../../../../store";
import {
    getUserConfigurationDetails, saveOrEditSuperConfigurationDetails, deleteCertificateDetails
} from "../../../../store/action/profileUpdate";
import i18n from 'i18next';
import { androidBundleValidate } from "../../../../helper/Validation";

export const translate = (key) => {
    return i18n.t(key || '') || '';
};
export const regionOragleCountry = [
    { id: 1, planName: "Australia East (Sydney)", isSelect: false },
    { id: 2, planName: "Australia Southeast (Melbourne)", isSelect: false },
    { id: 3, planName: "Brazil East (Sao Paulo)", isSelect: false },
    { id: 4, planName: "Brazil South East (Vinhedo)", isSelect: false },
    { id: 5, planName: "Canada Southeast (Montreal)", isSelect: false },
    { id: 6, planName: "Canada Southeast (Toronto)", isSelect: false },
    { id: 7, planName: "Chile Central (Santiago)", isSelect: false },
    { id: 8, planName: "France South (Marseille)", isSelect: false },
    { id: 9, planName: "Germany Central (Frankfurt)", isSelect: false },
    { id: 10, planName: "India West (Mumbai)", isSelect: false },
    { id: 11, planName: "India South (Hyderabad)", isSelect: false },
    { id: 12, planName: "Israel Central (Jerusalem)", isSelect: false },
    { id: 13, planName: "Italy Northwest (Milan)", isSelect: false },
    { id: 14, planName: "Japan Central (Osaka)", isSelect: false },
    { id: 15, planName: "Japan East (Tokyo)", isSelect: false },
    { id: 16, planName: "Netherlands Northwest (Amsterdam)", isSelect: false },
    { id: 17, planName: "Saudi Arabia West (Jeddah)", isSelect: false },
    { id: 18, planName: "Singapore (Singapore)", isSelect: false },
    { id: 19, planName: "South Korea Central (Seoul)", isSelect: false },
    { id: 20, planName: "South Korea North (Chuncheon)", isSelect: false },
    { id: 21, planName: "Sweden Central (Stockholm)", isSelect: false },
    { id: 22, planName: "Switzerland North (Zurich)", isSelect: false },
    { id: 23, planName: "UAE Central (Abu Dhabi)", isSelect: false },
    { id: 24, planName: "UAE East (Dubai)", isSelect: false },
    { id: 25, planName: "UK South (London)", isSelect: false },
    { id: 26, planName: "UK West (Newport)", isSelect: false },
    { id: 27, planName: "US East (Ashburn)", isSelect: false },
    { id: 28, planName: "US West (Phoenix)", isSelect: false },
    { id: 29, planName: "US West (San Jose)", isSelect: false },
];

export const validateLength = (value = "") => {
    return _get(value, "length", 0) <= 200;
};

export const validateBundleId = (bundleId = "") => {
    return (/^[A-Za-z0-9_.]+$/).test(bundleId);
};

export const valueCheckAndAppend = (value = "", errorValidate = false) => {
    return value ? !errorValidate : false;
};

/**
 * certificate allow only .p12 formate
 */
export const iosCertificateValidate = (value = "") => {
    const allowFormet = ['p12'];
    const extension = value.split('.').pop();
    return allowFormet.includes(extension);
};
export const firebasekeyCertificateValidate = (value = "") => {
    const allowFormet = ['json'];
    const extension = value.split('.').pop();
    return allowFormet.includes(extension);
};
/**
 * certificate size should only 10KB
 */
export const iosCertificateSizeValidate = (value = 0) => {
    return 10240 >= value;
};

/**
 * get inner HTML value
 * @param {event} event
 */
export const urlGet = (event = {}) => {
    return _get(event, "target.innerText", "").toLowerCase();
};

export const classNameFunction = (value = "", url = "") => {
    if (url) {
        return value ? 'validurl' : 'invalidurl';
    }
    return "";
};

export const lengthCheckUrl = (obj = {}) => {
    let valid = 0;
    Object.values(obj).forEach((val) => {
        if (val !== "") {
            valid = valid + 1;
        }
    });
    return valid;
};

/**
 * @param  {} data={}
 * get server config details
 */
export const getSetverDetailsActionTrigger = (data = {}) => {
    store.dispatch(getUserConfigurationDetails(data));
    store.dispatch({ type: 'SERVER_CONFIG_DATA_GET_LOADER', getServerConfigDetailLoader: true });//loader
    store.dispatch({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: false });//loader
};

//custeomera  admin update
export const apiProcessCallCusAdmin = (data = {}) => {
    const { callCertificateData = "", chatCertificateData = "", } = data;
    const { androidFcmProjectId = "", iosFcmProjectId = "", regionValue = "", userId = 0, androidBundleId = "", iosBundleId = "", voipPassword = "", apnsPassword = "",
        xmppUrl = "", signalUrl = "", janusUrl = "", apiBaseUrl = "", isCalls = "", isChats = "" ,isAndroidFcm = "",isIosFcm = "", firebaseKeyJsonfile = "",
        firebaseKeyIosJsonfile} = data;
    const newObject = {
        userId: userId,
        androidFcmProjectId: androidFcmProjectId,
        iosFcmProjectId: iosFcmProjectId,
        serverRegion: regionValue,
        bundleIdAndroid: androidBundleId,
        bundleIdIos: iosBundleId,
        voipPassword: voipPassword,
        apnsPassword: apnsPassword,
        callCertificateData: callCertificateData,
        chatCertificateData: chatCertificateData,
        firebaseKeyJsonfile: firebaseKeyJsonfile,
        firebaseKeyIosJsonfile: firebaseKeyIosJsonfile,
        isCalls: isCalls === true ? 1 : 0,
        isChats: isChats === true ? 1 : 0,
        isAndroidFcm: isAndroidFcm === true ? 1 : 0,
        isIosFcm: isIosFcm === true ? 1 : 0,
        janusUrl: janusUrl,
        xmppDomain: xmppUrl,
        signalUrl: signalUrl,
        apiBaseUrl: apiBaseUrl,
        userRoleId: data.userRoleId || 0,
    };

    store.dispatch(saveOrEditSuperConfigurationDetails(newObject));
    store.dispatch({ type: 'SERVER_CONFIG_DATA_UPDATE_LOADER', updateServerConfigDetailLoader: true });//loader
};

//super admin update
export const apiProcessCallSuperAdmin = (data = {}) => {
    const { xmppUrl = "", callCertificateData = "", chatCertificateData = "", signalUrl = "", janusUrl = "", apiBaseUrl = "", userId = 0, androidBundleId = "",
        iosBundleId = "", APNSPassword = "", VOIPPassword = "", regionValue = "", androidFcmProjectId = "", iosFcmProjectId = "", isCalls = "", isChats = "",
        isAndroidFcm = "",isIosFcm = "", firebaseKeyJsonfile = "",firebaseKeyIosJsonfile = "" } = data;
    const newObj = {
        userId: userId,
        androidFcmProjectId: androidFcmProjectId,
        iosFcmProjectId: iosFcmProjectId,
        serverRegion: regionValue,
        bundleIdAndroid: androidBundleId,
        bundleIdIos: iosBundleId,
        voipPassword: VOIPPassword,
        apnsPassword: APNSPassword,
        callCertificateData: callCertificateData,
        chatCertificateData: chatCertificateData,
        firebaseKeyJsonfile: firebaseKeyJsonfile,
        firebaseKeyIosJsonfile: firebaseKeyIosJsonfile,
        isCalls: isCalls === true ? 1 : 0,
        isChats: isChats === true ? 1 : 0,
        isAndroidFcm: isAndroidFcm === true ? 1 : 0,
        isIosFcm: isIosFcm === true ? 1 : 0,
        janusUrl: janusUrl,
        xmppDomain: xmppUrl,
        signalUrl: signalUrl,
        apiBaseUrl: apiBaseUrl,
        userRoleId: data.userRoleId || 0,
    };
    store.dispatch(saveOrEditSuperConfigurationDetails(newObj));
    store.dispatch({ type: 'SERVER_CONFIG_DATA_UPDATE_LOADER', updateServerConfigDetailLoader: true });//loader
};

//deleteCertificate
export const deleteCertificateActionTrigger = (data = {}) => {
    store.dispatch(deleteCertificateDetails(data));
    store.dispatch({ type: 'IOS_CERTIFICATE_DELETE_LOADER', iosCertificateDeteleLoader: false });//loader
};

/**
 * @param {*} data
 * after delete ios certificatereload is trigger
 */
export const reloadCertificateTriggerMakeOriginalState = (data = {}) => {
    store.dispatch({ type: 'IOS_CERTIFICATE_DELETE_RELOAD_GET', reloadCertificateDetails: null });//loader
};

/**
 * @param {*} data
 * after delete ios certificatereload is trigger
 */
export const popUpCloseServerCo = (data = {}) => {
    store.dispatch({ type: 'PUT_THE_SUCCESS_CODE_POP_UP', openThePopUp: false });//loader
};

/**
 * @param {*} data
 * click back button remove config details
 */
export const removeConfigDetailsStore = (data = {}) => {
    store.dispatch({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: {} });
};

/**
 * @param {*} data
 * click back button remove config details
 */
export const serverConfigPageNavigateOnClick = (value = false) => {
    store.dispatch({ type: 'SERVER_CONFIG_PAGE_NAVIGATE', serverNavigateConfig: value });
};

/** Validation for common inputs */
export const formInputValidation = (name = '', value = '') => {
    /** Empty check for all inputs */
    if (!value || value === '' || value.trim().length <= 0) return translate("ERROR.BLANKSPACE_ERROR");

    switch (name) {
        case 'serverRegion':
            // Add aditional validation here
            break;
        case 'androidBundleId':
            if (!androidBundleValidate(value)) return "Please enter valid android id";
            if (!validateBundleId(value)) return translate("ERROR.INVALID_INPUT");
            if (value.trim().length > 50) return translate("ERROR.MAX_CHAR_50");
            break;
        case 'androidFcmProjectId':
            if (value.trim().length > 200) return translate("ERROR.MAX_CHAR_200");
            break;
        case 'iosFcmProjectId':
            if (value.trim().length > 200) return translate("ERROR.MAX_CHAR_200");
            break;
        case 'iosBundleId':
            if (!validateBundleId(value)) return translate("ERROR.IOS_BUNDLE_ID_ERROR");
            if (value.trim().length > 200) return translate("ERROR.MAX_CHAR_200");
            break;
        default:
            return null;
    }
};

/** Validation for common inputs */
export const androidBundleVal = (name = '', value = '') => {

    /** Empty check for all inputs */
    if (!value || value === '' || value.trim().length <= 0) return translate("ERROR.BLANKSPACE_ERROR");

    if (!androidBundleValidate(value)) {
        return ("Please enter valid android id");
    } else {
        return false;
    }
};

export const sendMailToDevOps = (userId, redirect = true,) => {
    store.dispatch({ type: 'SERVER_CONFIG_DATA_GET_LOADER', getServerConfigDetailLoader: true });
    store.dispatch({ type: 'SEND_MAIL_TO_DEVOPS_ON_SKIP', userId, redirect });
};

export const getServerConfigurationDetailsArray = (data = {}) => {
    const dt = {
        xmppDomain: data.xmppDomain || '',
        janusUrl: data.janusUrl || '',
        signalUrl: data.signalUrl || '',
        apiBaseUrl: data.apiBaseUrl || '',
        chatFileName: data.chatFileName,
        callFileName: data.callFileName,
        androidFcmFileName: data.androidFcmFileName,
        iosFcmFileName: data.iosFcmFileName,
        apnsPassword: data.apnsPassword || '',
        voipPassword: data.voipPassword || '',
        serverRegion: data.serverRegion || '',
        bundleIdAndroid: data.bundleIdAndroid || '',
        androidFcmProjectId: data.androidFcmProjectId || '',
        iosFcmProjectId: data.iosFcmProjectId || '',
        bundleIdIos: data.bundleIdIos || ''
    };
    return dt;
};
