export const initState = {
    updateEmailId: {},
    popupOpenClose: false,
    sendPasswordLink: {},
};

export const CusProfileReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case "UPGRADE_FAILURE": {
            return {
                ...state,
                popupOpenClose: action.popupOpenClose
            }
        }
        case 'UPDATE_EMAILID_ACTION': {
            return {
                ...state,
                updateEmailId: action.updateEmailId
            };
        }
        case 'GENERATE_PASSWORD_LINK': {
            return {
                ...state,
                sendPasswordLink: action.sendPasswordLink
            };
        }
        default:
            return {
                ...state
            };
    }
};
