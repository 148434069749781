import { encodeGetParams } from "../helper/Encypt";
import { SDK, apiUrl, appRelease } from "../helper/ApiUrl";
import { Get, Post } from "../common/httpRestServices";

/**
 * internal Release new app
 */
export const appInternalRelease = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : "";
    return Post(`${apiUrl}${appRelease.appInternalRelease}${queryString}`, {}, true);
};

/**
 * custom release org details get
 * name and id get
 */
export const orgDetailsFetch = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${appRelease.getOrgDetails}${queryString}`, true);
};

/**
* Get Platform list for release form
*/
export const getPlatformsListService = () => {
    const queryString = encodeGetParams({deleted: false, page: 1, size: 200, status: 1, platformName: "" });
    return Get(`${apiUrl}${SDK.getSDKlist}${queryString}`, false);
};
