import store from "../../../store";
import { internetStatusDecrypt } from "../../../helper/Encypt";
import { releaseInternal } from "../../../store/action/appReleaseAction";
import { getAppVersionlist } from "../../../store/action/appversionAction";
import { checkWhiteSpaces, validateVersion as validateVersions, webDomainValidate } from "../../../helper/Validation";
import { ADD_NEW_VERSION } from "../../../store/actionTypes/appVerionTypes";

/**
 * @param  {string} value=""
 * this function used in ReleasedUpdatePopup
 * help for user search organisation, incase in search input field is empty close dropdown
 */
export const valueCheck = (value = "") => {
    if (value !== "") {
        return true;
    } else {
        store.dispatch({ type: "DO_STORE_CUTOM_RELEASE_ORG_DETAILS", customReleasOrgDtls: {} });
        return false;
    }
};

/**
 * @param  {string} value=""
 */
export const valueTriming = (value = "") => {
    return value ? value.trim() : "";
};

/**
 * add version popUp outSide Click close
 */
export const outSideClickAddVersion = () => {
    store.dispatch({ type: "APP_VERSION_POPUP", statePopup: false }); //whenData Append response place replace StateValue
};

/**
 * Intial and reRender call
 */
export const reRenderCall = (params = {}, type = "") => {
    if (internetStatusDecrypt()) {
        if (type === "initial") {
            store.dispatch(getAppVersionlist(params)); //initail value pass empty
            store.dispatch({ type: "APP_VERSION_API_LOAD", isListLoad: true });
            store.dispatch({ type: "APP_VERSION_API_LOAD_INITIAL", initaialRender: true });
        } else {
            store.dispatch({ type: "APP_VERSION_API_LOAD", isListLoad: true });
            store.dispatch({ type: "APP_VERSION_API_LOAD_INITIAL", initaialRender: true });
            store.dispatch(getAppVersionlist(params)); //pagination get data
        }
    }
};
/**
 * @param  {object} initialAppParams={}
 * @param  {boolean} newListLoad=true
 * create new version and search version reRender
 */
export const newListCall = (initialAppParams = {}, newListLoad = true) => {
    if (newListLoad === true) {
        reRenderCall(initialAppParams, "reRender"); //Re render Type
    }
};

/**
 * @param  {Array} verionHistry=[]
 * @param  {Array} releseHisty=[]
 * when click releseHisty button in customer login ("verionHistry length is empty")
 */
export const releaseHistoryManage = (verionHistry = [], releseHisty = []) => {
    return verionHistry.length !== 0 ? verionHistry : releseHisty;
};

/**
 * @param  {boolean} value=false
 * condition based Heading Changed
 * if False AppVerion Heading
 * if True Release History heading
 */
export const releaseHistorySubHeading = (value = false) => {
    return value ? "PAGE_HEADING.RELEASE_HISTORY" : "PAGE_HEADING.AVERSION";
};

/**
 * @param  {boolean} value=false
 * condition based icon Changed
 * if False AppVerion icon
 * if True Release History icon
 */
export const releaseHistoryIcon = (value = false) => {
    return value ? "ReleaseHistory" : "APPVersions";
};

/**
 * @param  {boolean} value=false
 * comma remove last customer name and dot print
 * @return "," or "."
 */
export const selectedCustomerseparation = (length = 0, index = 0) => {
    return length !== index + 1 ? "," : ".";
};

/**
 * @param  {boolean} value=false
 * className retrun
 */
export const searchClass = (value = false) => {
    return value ? "inputgroup active" : "inputgroup";
};

export const pageOnLeaveReleaseUpdatePop = () => {
    store.dispatch({ type: "DO_APP_RELEASE_INITIAL" }); //resetData
};

export const onAppVersionPageLeave = () => {
    store.dispatch({ type: "DO_APP_VERSION_INITIAL" });//this callempty stpre
};

/*
 *SubTitle component button Handle
 *popUp open
 */
export const _createNewVer = () => {
    store.dispatch({ type: "APP_VERSION_POPUP", statePopup: true }); //open AddVersion popUp
};

/**
 * @param  {object} releaseManage={}
 * releaseType Manage
 */
export const onVersionReleaseChange = (releaseManage = {}) => {
    store.dispatch(releaseInternal(releaseManage));
    store.dispatch({ type: "APP_VERSION_INFO_POPUP", popupStatus: false });
    store.dispatch({ type: "DO_INTERNAL_RELEASE_LOAD", interReleaseLoad: true });
};

export const creteMonckSDKlist = (sdkservice=[]) => {
    const platFormData = sdkservice.map((ele) => {
        return {
            ...ele,
            "size": 0,
            urlSDK: "",
            urlDOC: "",
            nameSDK: "",
            nameDOC: "",
            "docSize": 0,
            "isCheck": false,
            value: ele.sdkService,
            [ele.sdkService]: false,
            "planName": ele.sdkService,
            docValidate: ele.sdkService + "DOC",
            sdkValidate: ele.sdkService + "SDK"
        };
    });
    store.dispatch({ type: "PLATFROM_DATA_NEW_LIST", platFormNewList: platFormData }); //open AddVersion popUp
    return platFormData;
};

export const getPlaformsList = () => {
    store.dispatch({ type : 'LOADER', loader: true });
    store.dispatch({ type : 'PLATFROMS_LIST' });
};

export const validateVersion = (value) => {
    if (!value) return 'Required';
    if (!checkWhiteSpaces(value)) return 'This field cannot be empty';
    if (validateVersions(value)) return 'Not valid version';
    if (value.length > 50) return 'Maximum 50 characters allowed';
};

export const validateUrl = (value) => {
    if (!value) return 'Required';
    if (!checkWhiteSpaces(value)) return 'This field cannot be empty';
    if (!webDomainValidate(value)) return 'Enter valid URL';
    if (value.length > 400) return 'Maximum 400 characters allowed';
};

export const createNewRelease = (data = {}, selectedplatforms = {}) => {
    store.dispatch({ type: "APP_VERSION_API_LOAD", isListLoad: true });
    const requestDataSample = {
        title : data?.title,
        details : data?.details,
        releaseNotes: data?.releaseNotes,
        platformsDetails: [...Object.values(selectedplatforms)]
    };
    store.dispatch({ type : ADD_NEW_VERSION, data : requestDataSample });
};
