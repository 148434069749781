import { combineReducers } from 'redux';
import { CusReducer } from './CusReducer';
import { teamsReducer } from './teamsReducer';
import { loginReducer } from './loginReducer';
import { loderReducer } from './loaderReducer';
import { addCusReducer } from './addCusReducer';
import { addSDKReducer } from './addSDKReducer';
import { teamsAddMember } from './teamsAddMember';
import { cusTeamsReducer } from "./cusTeamsReducer";
import { paymentInvoiceView } from "./paymentsReducer";
import { commonDataReducer } from './commonDataReducer';
import { appReleaseReducer } from "./appReleaseReducer";
import { appVersionReducer } from './appVersionReducer';
import { moderationReducer } from './moderationReducer';
import { addAppVerionReducer } from './addAppVerionReducer';
import { dashBoardQuickView } from './dashBoardQuickReducer';
import { changPasswrdReducer } from "./changePasswordReducer";
import { initialStripeReducer } from './stripePaymentReducer';
import { dashBoardanalyticView } from './dashBoardAnalyticReducer';
import { initialBillingHistoryReducer } from './billingHistoryReducer';
import {notificationReducer} from './notificationReducer'
import { activityLogReducer }  from './activityLogReducer';
import {CusProfileReducer} from './cusProfileReducer'

const rootReducer = combineReducers({
    loader: loderReducer,//loder
    addSDK: addSDKReducer,//addSdk
    CusPage: CusReducer,//add,delete,edit page action manage
    CusProfilePage : CusProfileReducer,
    addCus: addCusReducer,//Do action create addCustomers
    teamsPage: teamsReducer,//teamsPage reducer
    loginPage: loginReducer,//handle by loging,forget,reset page action manage
    cusTeams: cusTeamsReducer,//customer page Teams member reducer
    addMember: teamsAddMember,//Add member
    commonData: commonDataReducer,//common
    appRelease: appReleaseReducer,//app release Reducer
    appVersion: appVersionReducer,//appVersion
    moderation: moderationReducer,//moderation
    stripePage: initialStripeReducer,//stripe Pyment
    addAppVersion: addAppVerionReducer,//AddappVersion
    dasBordQuickView: dashBoardQuickView,//quick over view
    paymentInvoiceView: paymentInvoiceView,//paymentsReducer,
    changPasswrdReducer: changPasswrdReducer,//changePwdReducer
    dashBoardAnalyticView: dashBoardanalyticView,//analytic view
    billingHistory: initialBillingHistoryReducer,//billing history
    notification: notificationReducer,//notification list
    activityLog: activityLogReducer,//activitylog list
});
export default rootReducer;
