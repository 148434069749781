export const addSDKinitail = {
    searchData: {},//serSearh data history
    sdkDataList: {},//data
    sdkPopUp: false,//popUP open and close
    loaderSDK: false,//loadr
    platFormNewList: [],
    platformsList: []
};

export const addSDKReducer = (state = addSDKinitail, action = {}) => {

    switch (action.type) {
        case 'ADD_SDK_INITIAL': {//initial call
            return {
                ...state,
                ...addSDKinitail
            };
        }
        case 'STORE_SDK_DATA_LIST': {//store sdk list and data
            return {
                ...state,
                sdkDataList: action.sdkDataList
            };
        }
        case 'SDK_LOADER': {//loader
            return {
                ...state,
                loaderSDK: action.loaderSDK
            };
        }
        case "SEARCH_SDK_LIST": {// *search data
            return {
                ...state,
                searchData: action.searchData
            };
        }
        case "SDK_POP_UP_OPEN_CLOSE": {//loader
            return {
                ...state,
                sdkPopUp: action.sdkPopUp
            };
        }
        case 'PLATFROM_DATA_NEW_LIST': {
            return {
                ...state,
                platFormNewList: action.platFormNewList
            };
        }
        case 'PLATFROM_LIST': {
            return {
                ...state,
                platformsList: action.platformsList
            };
        }
        default:
            return {
                ...state
            };
    }
};
