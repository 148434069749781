import React, { useEffect, useState } from 'react';
import "./Registration.scss";
import _get from "lodash/get";
import ContactFrom from './ContactFrom';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import History from '../../common/History';
import { isOffline } from '../../common/helper';
import FeatureHighlights from './FeatureHighlights';
import { locationFindUrl } from '../../helper/ApiUrl';
import { Redirect, useHistory } from 'react-router-dom';
import RegisterSucessPopup from './RegisterSucessPopup';
import { storeSSOLoginResponseDetails } from '../Login/SSOLogin/helperSSO';
import { hubSpotApiImplement, onsubmitRegister } from './registerValidate';
import { IconChevronLeft, IconLoader, ImgLogoNew } from '../../assets/images';
import { emptyInputErrorMsgThrow, userLogRoleId } from "../../components/Login/loginPageCommon";
import {
    getCookieByName, getLocate, validateForm,
    emptyCheckAllData, validateIPaddress, nullToObject,
} from '../../helper/Validation';

const publicIp = require("react-public-ip");

const Registration = (props = {}) => {
    const { match: { params: { plantype = "" } = {} } = {}, location: { search = "" } = {} } = props;
    const globalStore = useSelector((states) => states) || {};
    const history = useHistory();
    const { location: { pathname = "", state = {} } = {} } = history;
    const { loginPage: { registerLoad = false } = {} } = globalStore || {};

    const { commonData = {} } = globalStore;
    const { ssoResponseDetails = {} } = nullToObject(commonData);
    const { ssoresponse = false, type = "", unique = "" } = ssoResponseDetails;
    const findIsTrails = plantype === "easy" ? false : true;
    const [localIp, setLoaclIp] = useState("");
    const [stepView, setStepView] = useState(true);
    const hupspotId = getCookieByName("hubspotutk");
    const [locationKeys, setLocationKeys] = useState([]);
    const [stateManage, setStateManage] = useState({
        formValidate: false,
        errData: {},
    });
    const [getLoader, setLoader] = useState(true);
    const [successRegisterPopUp, setSuccessRegisterPopUp] = useState(false);
    const [role, setRole] = useState("");
    const [name, setname] = useState("");
    const [emailID, setemailID] = useState("");
    const [country, setcountry] = useState("");
    const [appType, setappType] = useState("");
    const [appStatus, setAppStatus] = useState("");
    const [userLookingModule, setUserLookingModule] = useState("");
    const [techStack, setTechStack] = useState("");
    const [phoneNum, setphoneNum] = useState("");
    const [password, setpassword] = useState("");
    const [chatCheckBox, setChatCheckBox] = useState(false);
    const [userCount, setuserCount] = useState("");
    const [countryCode, setcountryCode] = useState("IN");
    const [applicationName, setapplicationName] = useState("");
    const [organisationName, setorganisationName] = useState("");

    const inputHandle = {
        role: role,
        name: name,
        emailID: emailID,
        country: country,
        appType: appType,
        appStatus: appStatus,
        techStack: techStack.replaceAll(",", ";"),
        userLookingModule: userLookingModule.replaceAll(",", ";"),
        phoneNum: phoneNum,
        password: password,
        userCount: userCount,
        countryCode: countryCode,
        applicationName: applicationName,
        organisationName: organisationName,
        chatCheckBox: chatCheckBox,
    };

    const [inputHandleEmpty, setInputHandleEmpty] = useState({
        role: false,
        name: false,
        emailID: false,
        appType: false,
        country: false,
        phoneNum: false,
        techStack: false,
        appStatus: false,
        userCount: false,
        userLookingModule: false,
        applicationName: false,
        organisationName: false,
        chatCheckBox: false,
    });

    /**
     * api service handle
    */
    const handleSuccess = async () => {
        setLoader(true);
        if (isOffline()) {
            return;
        }
        const ipv4 = await publicIp.v4() || "";
        const { errData, ...restData } = stateManage;
        if (validateForm(restData) || ssoresponse) {
            const ipAddress = ipv4 ? ipv4 : localIp;

            const newObj = {// make new obj
                ...inputHandle,
                type: type,
                unique: unique,
                localIp: ipAddress,
                hupspotId: hupspotId,
                ssoresponse: ssoresponse,
                trailCustomer: findIsTrails,
                ipAddressValidate: validateIPaddress(ipAddress) ? 1 : 0,
            };
            onsubmitRegister({ ...newObj, captchaToken: "webportaltest" });
            mixpanel.track("Signup page2", {
                email: newObj.emailID,
                ...newObj,
                password: ""
            });
        } else {
            // empty validate off
            // setInputHandleEmpty({
            //     ...inputHandleEmpty,
            //     role: emptyInputErrorMsgThrow(inputHandle.role),
            //     appType: emptyInputErrorMsgThrow(inputHandle.appType),
            //     userCount: emptyInputErrorMsgThrow(inputHandle.userCount),
            //     applicationName: emptyInputErrorMsgThrow(inputHandle.applicationName),
            // });
        }
    };
    /**
     * Input box Handle
    */
    const handleInputValue = (event = {}, err = {}) => {
        const { name: eventName = "", value = "" } = _get(event, "target", {});
        switch (eventName) {
            case "role":
                setRole(value);
                break;
            case "name":
                setname(value);
                break;
            case "emailID":
                setemailID(value);
                break;
            case "country":
                setcountry(value);
                break;
            case "appType":
                setappType(value);
                break;
            case "appStatus":
                setAppStatus(value);
                break;
            case "userLookingModule":
                setUserLookingModule(value);
                break;
            case "techStack":
                setTechStack(value);
                break;
            case "phoneNum":
                setphoneNum(value);
                break;
            case "password":
                setpassword(value);
                break;
            case "chatCheckBox":
                setChatCheckBox(value);
                break;
            case "userCount":
                setuserCount(value);
                break;
            case "countryCode":
                setcountryCode(value);
                break;
            case "applicationName":
                setapplicationName(value);
                break;
            case "organisationName":
                setorganisationName(value);
                break;
            default:
                break;
        }
        setStateManage({
            ...stateManage,
            errData: err,
            formValidate: validateForm(err)
        });
        setInputHandleEmpty({
            ...inputHandleEmpty,
            [name]: emptyInputErrorMsgThrow(value),//error hide
            applicationName: false,
        });
    };

    const moveToRegPage = () => {//afetr click sign in button trigget heere
        History.push({ pathname: "/" });//navigate to login page
    };

    const getLocalIpDatas = async () => {
        fetch(locationFindUrl)//location find api call
            .then(response => response.json()).then(async (localData) => {
                const { IPv4 = "" } = localData || {};
                setLoaclIp(IPv4);
            }).catch(async () => {//have write condition check undefined is string so if error call defult assign undefined
                const response = await getLocate();
                const { IPv4 = "" } = response || {};
                setLoaclIp(IPv4);
            });
    };

    const getStepViewState = async (stateView) => {
        const newObjCheck = {//step one validate
            name: inputHandle.name,
            emailID: inputHandle.emailID,
            password: inputHandle.password,
            phoneNum: inputHandle.phoneNum,
            organisationName: inputHandle.organisationName,
        };
        const { errData = {} } = stateManage;
        const errorLength = Object.keys(errData).length ? true : false;

        const {
            nameError = false, namewhitespace = false, phoneNumError = false,
            emailError = false, emailLenErr = false, emailWhiteSpace = false,
            passwordWhiteSpace = false, passwordError = false, nameHtmlError,
            bussinessNameHtmlError,
            publicEmailError = true, orgwhitespace = false, orgNameError = false,
        } = errData;

        const newObjData = {
            nameError, namewhitespace, phoneNumError, orgwhitespace, orgNameError,
            emailError, emailLenErr, emailWhiteSpace, publicEmailError,
            passwordWhiteSpace, passwordError, nameHtmlError, bussinessNameHtmlError,
        };

        const stepOneValidate = emptyCheckAllData(newObjCheck);

        if (stepView) {//step one is verified moved to step2
            const newObj = {
                ...inputHandleEmpty,
                name: emptyInputErrorMsgThrow(inputHandle.name),
                emailID: emptyInputErrorMsgThrow(inputHandle.emailID),
                country: emptyInputErrorMsgThrow(inputHandle.country),
                password: emptyInputErrorMsgThrow(inputHandle.password),
                phoneNum: emptyInputErrorMsgThrow(inputHandle.phoneNum),
                organisationName: emptyInputErrorMsgThrow(inputHandle.organisationName),
                applicationName: false,
            };
            setInputHandleEmpty(newObj);
            if (stepOneValidate && errorLength && validateForm(newObjData)) {
                setStepView(stateView);
                const ipv4 = await publicIp.v4() || "";
                const ipAddress = ipv4 ? ipv4 : localIp;
                const newObjs = {// make new obj
                    ...inputHandle,
                    localIp: ipAddress,
                    hupspotId: hupspotId,
                    ipAddressValidate: validateIPaddress(ipAddress) ? 1 : 0,
                };
                hubSpotApiImplement(newObjs);
                mixpanel.track("Signup page1", {
                    email: newObjs.emailID,
                    ...newObjs,
                    password: ""
                });
            }
        } else {
            setStepView(stateView);
        }
    };

    const handleMobileBackBtntoStep1 = () => {
        setStepView(true);
    };

    const handleResetSso = () => {
        storeSSOLoginResponseDetails({});
    };

    useEffect(() => {
        if (pathname === "/thank-you") {//after success register enablePopUp
            setSuccessRegisterPopUp(true);
        }
    }, [pathname]);

    // local ip Address get
    useEffect(() => {
        getLocalIpDatas();
        mixpanel.track('Signup');
    }, []);

    useEffect(() => {
        if (ssoresponse) {
            setStepView(true);
        }
    }, [ssoresponse]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                    storeSSOLoginResponseDetails({});
                } else {
                    storeSSOLoginResponseDetails({});
                    setLocationKeys((keys) => [location.key, ...keys]);
                    History.push({ pathname: "/" });//navigate to login page
                }
            }
        });
    }, [locationKeys]);

    useEffect(() => {
        setLoader(registerLoad);
    }, [registerLoad]);

    /**
    * User login successfully redirect To DashBoard Page or dynamic redirectPage
    */
    if (window.sessionStorage.getItem("userData")) {
        const userRoleId = userLogRoleId();
        const defaultPathName = userRoleId >= 4 ? "/overview" : "/analytics";
        return <Redirect to={`${defaultPathName}`} />;
    }

    return (
        <React.Fragment>
            {registerLoad || getLoader ? <div className={`pageLoader fixed overlay`}> <i><IconLoader /></i></div> : null}
            <div
                id="RegistrationWraper"
                className={`RegistrationWraper RegistrationPage Custom`}
            >
                <div className='header_sticky'>
                    <div className='header_sticky_container'>
                        <div className='header_sticky_inner'>
                            <a className="logo_link" href='https://www.mirrorfly.com/' onClick={() => mixpanel.track("Sign Up Logo back to home")}>
                                <img src={ImgLogoNew} onClick={() => mixpanel.track("Sign Up Logo back to home")}
                                    alt="mirrorfly logo" width="209px" height="44px" />
                            </a>
                            {!stepView ?
                                <div className='btn_back_home_wraper'>
                                    <button onClick={() => { mixpanel.track("back to Signup"); handleMobileBackBtntoStep1(); }}
                                        className={stepView ? " btn_back_home  " : " btn_back_home sm"}>
                                        <i><IconChevronLeft /></i>
                                        <span>Back</span>
                                    </button>
                                    {/* <button title='close' onClick={() => handleMobileBackBtn()} className='btn_back_home_close'><IconClose /></button> */}
                                </div> : null
                            }
                            {stepView && ssoresponse ?
                                <div className='btn_back_home_wraper'>
                                    <button onClick={() => { mixpanel.track("back to Signup"); handleResetSso(); }}
                                        className={"btn_back_home sm"}>
                                        <i><IconChevronLeft /></i>
                                        <span>Back</span>
                                    </button>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
                <div className='Container_lg'>
                    {/* <LoginBrandInfo /> */}
                    <div id="Registration_wraper" className={`Registration_wraper `}>
                        <div id="RegistrationForm" className="RegistrationForm">
                            {!successRegisterPopUp ?
                                <ContactFrom
                                    search={search}
                                    errData={stateManage.errData}
                                    stepView={stepView}
                                    inputHandle={inputHandle}
                                    globalStore={globalStore}
                                    handleSuccess={handleSuccess}
                                    getStepViewState={getStepViewState}
                                    inputHandleEmpty={inputHandleEmpty}
                                    handleInputValue={handleInputValue}
                                    formValidate={stateManage.formValidate}
                                />
                                :
                                <>
                                    {/* Reistration popup start */}
                                    <RegisterSucessPopup
                                        state={state}
                                        moveToRegPage={moveToRegPage}
                                        successRegisterPopUp={successRegisterPopUp}
                                    />
                                    {/* Reistration popup end */}
                                </>
                            }
                        </div>
                        <FeatureHighlights
                            name={name}
                            stepView={stepView}
                            ssoresponse={ssoresponse}
                        />
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};
export default React.memo(Registration);
