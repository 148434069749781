export const ADD_NEW_VERSION = 'ADD_NEW_VERSION'; /*DOC upload*/
export const DOWNLOAD_SDK_COC = "DOWNLOAD_SDK_COC"; /*download SDK and DOC files */
export const ADD_APP_VERSION_NEW = 'ADD_APP_VERSION_NEW'; /*file upload*/
export const APP_INTERNAL_RELEASE = "APP_INTERNAL_RELEASE"; /*get app verion full info*/
export const GET_APP_VERSION_DETAILS = 'GET_APP_VERSION_DETAILS'; /*get app version list*/
export const ADD_APP_VERSION_NEW_DCOC = 'ADD_APP_VERSION_NEW_DCOC'; /*DOC upload*/
export const APP_CUSTOM_RELEASE_ORG_DETAILS = "APP_CUSTOM_RELEASE_ORG_DETAILS"; /*app custom release*/
export const GET_LATEST_VERSION_SDK_DOWNLOAD = "GET_LATEST_VERSION_SDK_DOWNLOAD";/*GET LATEST SDK*/
export const DOWNLOAD_LATEST_VERSION_SDK_DOWNLOAD = "DOWNLOAD_LATEST_VERSION_SDK_DOWNLOAD";/*DOWNLOAD LATEST SDK*/
export const GET_CALL_DURATION_PASSED_ORG_ID_BASED = "GET_CALL_DURATION_PASSED_ORG_ID_BASED";/*org id based ged call duration*/
export const DOWNLOAD_LATEST_VERSION_SERVER_CONFIG_DOWNLOAD = "DOWNLOAD_LATEST_VERSION_SERVER_CONFIG_DOWNLOAD";/*DOWNLOAD LATEST SDK*/
export const PLATFROMS_LIST = "PLATFROMS_LIST"; /*Get platforms list */
