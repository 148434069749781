import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as dashBoardTypes from '../actionTypes/dashBoardTypes';
import { failToast, serverNotRespond } from '../../helper/ApiToast';
import { quickViewService, analyticViewService, cloudUsageService, recentActiveCustomersService, recentActiveCustomersListService } from '../../services/dashbordServices';

/**
 * @param  {object} obj={}
 * quickViewSaga
 */
export function* quickViewSaga(obj = {}) { //quickViewSaga Method
    try {
        const quickViewServiceDetails = yield call(quickViewService, obj);
        const { data: { data = {}, status = 0, message = "" } = {} } = quickViewServiceDetails;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "QUICKVIEW_DATA", quickViewData: data }),//Get planType Api call and store
                put({ type: "QUICKVIEW_LOAD", quickViewLoad: false }), //Loader false
            ]);
        } else {
            serverNotRespond(message); //serverIs Not Respont Call this Toast
            yield all([
                put({ type: "QUICKVIEW_LOAD", quickViewLoad: false }), //Loader false
            ]);
        }
    } catch (error) {
        yield all([
            put({ type: "QUICKVIEW_LOAD", quickViewLoad: false }), //Loader false
        ]);
    }
}

/**
 * @param  {object} obj={}
 * analyticViewSaga
 */
export function* analyticViewSaga(obj = {}) { //analyticViewSaga Method
    try {
        const analiticServiceDetails = yield call(analyticViewService, obj);
        const { data: { data = {}, status = 0, message = "" } = {} } = analiticServiceDetails;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "ANALYTIC_DATA", analyticData: data }),//Get planType Api call and store
            ]);
        } else if (status === 400) {
            failToast(message);
            yield all([
                put({ type: "ANALYTIC_DATA", analyticData: {} }),//Get planType Api call and store
            ]);
        }
        else {
            serverNotRespond(message); //serverIs Not Respont Call this Toast
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
    }
    yield put({ type: "ANALYTIC_LOAD", analyticLoad: false });
}

/**
 * @param  {object} obj={}
 * cloudUsage details get
 */
export function* cloudusagesSaga(obj = {}) { //analyticViewSaga Method
    try {
        const analiticServiceDetails = yield call(cloudUsageService, obj);
        const { data: { status = 0, message = "" } = {} } = analiticServiceDetails;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "CLOUD_USAGE_LOAD", cloudUasgeLoader: false }), //Loader false
            ]);
        } else {
            serverNotRespond(message); //serverIs Not Respont Call this Toast
            yield all([
                put({ type: "CLOUD_USAGE_LOAD", cloudUasgeLoader: false }), //Loader false
            ]);
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "CLOUD_USAGE_LOAD", cloudUasgeLoader: false }), //Loader false
        ]);
    }
}

/** Get top 10 recent customers only by this Saga */
export function* getRecentCustomersSaga(obj = {}) {
    try {
        const response = yield call(recentActiveCustomersService, obj?.data);
        const { data: { status = 0, data = [] } = {} } = response;

        if (status === 200) {
            yield put({ type: "RECENT_ACTIVE_CUSTOMERS", recentCustomers: data });
        } else {
            serverNotRespond();
        }
    } catch (err) {
        serverNotRespond();
    }
    yield put({ type: "ANALYTIC_LOAD", analyticLoad: false });
}

/** Get recent customers full list by this Saga */
export function* getRecentCustomersListSaga(obj = {}) {
    try {
        const response = yield call(recentActiveCustomersListService, obj);

        if (response?.data?.status === 200) {
            yield put({ type: "RECENT_ACTIVE_CUSTOMERS_LIST", recentCustomersList: response?.data || {} });
        } else {
            serverNotRespond();
        }
    } catch (err) {
        serverNotRespond();
    }
    yield put({ type: "DO_LOADING", loading: false });
}

export default function* rootDashBoardPostSaga() {
    yield takeLatest(dashBoardTypes.QUICK_VIEW_GET, quickViewSaga);
    yield takeLatest(dashBoardTypes.CLOUD_USAGE_CREATE, cloudusagesSaga);
    yield takeLatest(dashBoardTypes.ANALTTICAL_OVER_VIEW, analyticViewSaga);
    yield takeLatest(dashBoardTypes.GET_RECENT_CUSTOMERS_LIST, getRecentCustomersSaga);
    yield takeLatest(dashBoardTypes.GET_RECENT_CUSTOMERS_ALL_LIST, getRecentCustomersListSaga);
}
