import { serverNotRespond} from "../../helper/ApiToast";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as ModerationTypes from '../actionTypes/moderationTypes';
import * as moderationService from "../../services/moderationService";

export function* getUserModertionListSaga(obj = {}) { //SDK get list
    try {
        const response = yield call(moderationService.getUserModerationService, obj);
        const { data = {} } = response || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_MODERATION_LOADER", loaderUserMod: false }),//inidial loader
                put({ type: "USER_MODERATION_LIST", userModertionList: data }),
                put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: true }),
            ]);
        } else {
            serverNotRespond(message);
            yield all([
                put({ type: "USER_MODERATION_LIST", userModertionList: data }),
                put({ type: "USER_MODERATION_LOADER", loaderUserMod: false }),//inidial loader
                put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: false }),
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "USER_MODERATION_LIST", userModertionList: {} }),
            put({ type: "USER_MODERATION_LOADER", loaderUserMod: false }),//inidial loader
            put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: false }),
        ]);
    }
}

/**
 * group
 * @param {*} obj
 */
export function* getGroupModertionListSaga(obj = {}) { //SDK get list
    try {
        const response = yield call(moderationService.getGroupModerationService, obj);
        const { data = {} } = response || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "GROUP_MODERATION_LOADER", loaderGroupMod: false }),//inidial loader
                put({ type: "GROUP_MODERATION_LIST", groupModertionList: data }),
                put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: true }),
            ]);
        } else {
            serverNotRespond(message);
            yield all([
                put({ type: "GROUP_MODERATION_LIST", groupModertionList: data }),
                put({ type: "GROUP_MODERATION_LOADER", loaderGroupMod: false }),//inidial loader
                put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: false }),
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "GROUP_MODERATION_LIST", groupModertionList: {} }),
            put({ type: "GROUP_MODERATION_LOADER", loaderGroupMod: false }),//inidial loader
            put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: false }),
        ]);
    }
}

/**
 * report
 * @param {*} obj
 */
export function* getReportModertionListSaga(obj = {}) { //SDK get list
    try {
        const response = yield call(moderationService.getReportModerationService, obj);
        const { data = {} } = response || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "REPORT_MODERATION_LOADER", loaderreportMod: false }),//inidial loader
                put({ type: "REPORT_MODERATION_LIST", reportModertionList: data }),
                put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: true }),
            ]);
        } else {
            serverNotRespond(message);
            yield all([
                put({ type: "REPORT_MODERATION_LIST", reportModertionList: data }),
                put({ type: "REPORT_MODERATION_LOADER", loaderreportMod: false }),//inidial loader
                put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: false }),
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "REPORT_MODERATION_LIST", reportModertionList: {} }),
            put({ type: "REPORT_MODERATION_LOADER", loaderreportMod: false }),//inidial loader
            put({ type: "VALUE_APPENT_IN_ARRAY", valueAddInList: false }),
        ]);
    }
}

export function* getUserCredentialModertionSaga(obj = {}) { //get user Credential
    yield put({ type: "DO_LOADING", loading: true });
    try {
        /**  user credential start  */
        yield put({ type: "SET_API_PROGRESS_CREDENTIAL", userCredentialProgress: 'in-progress' });
        const response = yield call(moderationService.getUserCredentialModerationService, obj);
        const { data = {} } = response || {};
        const { status: credentialStatus = 0, message = "" } = data;
        yield put({ type: "SET_API_PROGRESS_CREDENTIAL", userCredentialProgress: (credentialStatus === 200) ? 'success' : 'failure' });
        if (credentialStatus == 200) {
            yield all([
                put({ type: "USER_CREDENTIAL_MODERATION_DETAILS", userCredentialModertionDetails: data }),
            ]);
            /**  login start  */
            const apiBaseUrl = data.data.apiBaseUrl;
            const username = data.data.apiUsername;
            const password = data.data.apiPassword;
            const licenseKey = data.data.licenseKey;
            const loginPayload = { data: { apiBaseUrl, username, password } };
            yield put({ type: "SET_API_PROGRESS_LOGIN", userLoginProgress: 'in-progress' });
            const loginResponse = yield call(moderationService.getUserLoginModerationService, loginPayload);
            const { data: loginData = {} } = loginResponse || {};
            const { status: LoginStatus = 0 } = loginData;
            yield put({ type: "SET_API_PROGRESS_LOGIN", userLoginProgress: (LoginStatus === 200) ? 'success' : 'failure' });
            if (LoginStatus === 200 || LoginStatus != 200) {
                yield all([
                    put({ type: "USER_LOGIN_MODERATION_DETAILS", userLoginModertionDetails: loginData }),
                ]);
            }
            /**  create user start  */
            let token = loginData.data.token;
            const createUser1Payload = { data: { apiBaseUrl, licenseKey, token } };
            const createUserResponse1 = yield call(moderationService.createUserModerationService, createUser1Payload);
            const { data: createUserData1 = {} } = createUserResponse1 || {};
            const { status: createUserStatus1 = 0 } = createUserData1;
            const createUser2Payload = { data: { apiBaseUrl, licenseKey, token } };
            yield put({ type: "SET_API_PROGRESS_CREATEUSER", userCreationProgress: 'in-progress' });
            const createUserResponse = yield call(moderationService.createUserModerationService, createUser2Payload);
            const { data: createUserData2 = {} } = createUserResponse || {};
            const { status: createUserStatus = 0 } = createUserData2;
            yield put({ type: "SET_API_PROGRESS_CREATEUSER", userCreationProgress: ((createUserStatus === 200) && (createUserStatus1 === 200)) ? 'success' : 'failure' });
            if ((createUserStatus === 200 || createUserStatus !== 200) || (createUserStatus1 === 200 || createUserStatus1 !== 200)) {
                yield all([
                    put({ type: "USER_CREATION_MODERATION_DETAILS", userCreationModertionDetails: (createUserData1 && createUserData2) }),
                ]);
            }

            /**  send message start  */
            const toUser = createUserData2?.data.userId;
            const sendMessagePayload = { data: { apiBaseUrl, toUser, token } };
            yield put({ type: "SET_API_PROGRESS_SENDMESSAGE", sendMessageProgress: 'in-progress' });
            const sendMessageResponse = yield call(moderationService.sendMessageModerationService, sendMessagePayload);
            const { data: sendMessageData = {} } = sendMessageResponse || {};
            const { status: sendMessageStatus = 0 } = sendMessageData;
            yield put({ type: "SET_API_PROGRESS_SENDMESSAGE", sendMessageProgress: (sendMessageStatus === 200) ? 'success' : 'failure' });
            if (sendMessageStatus === 200) {
                yield all([
                    put({ type: "SEND_MESSAGE_MODERATION_DETAILS", sendMessageModertionDetails: sendMessageData }),
                ]);
                /**  send message end  */
                const participant = createUserData2.data.userId;
                const messageId = sendMessageData.data.messageId;
                const chatHistoryPayload = { data: { apiBaseUrl, token, participant, messageId } };
                yield put({ type: "SET_API_PROGRESS_CHATHISTORY", chatHistoryProgress: 'in-progress' });
                const chatHistoryResponse = yield call(moderationService.chatHistoryModerationService, chatHistoryPayload);
                const { data: chatHistoryData = {} } = chatHistoryResponse || {};
                const { status: chatHistoryStatus = 0 } = chatHistoryData;
                yield put({ type: "SET_API_PROGRESS_CHATHISTORY", chatHistoryProgress: (chatHistoryStatus === 200) ? 'success' : 'failure' });
                if (chatHistoryStatus === 200 || chatHistoryStatus !== 200) {
                    yield all([
                        put({ type: "CHAT_HISTORY_MODERATION_DETAILS", chatHistoryModertionDetails: chatHistoryData }),
                    ]);
                }
            } else if (sendMessageStatus != 200) {
                yield all([
                    put({ type: "SEND_MESSAGE_MODERATION_DETAILS", sendMessageModertionDetails: sendMessageData }),
                ]);
            }
            let userIdentifier = createUserData1?.data.userId;
            const deleteUser1Payload = { data: { apiBaseUrl, userIdentifier, token } };
            yield put({ type: "SET_API_PROGRESS_DELETEACCOUNT", deleteAccountProgress: 'in-progress' });
            yield call(moderationService.deleteAccountModerationService, deleteUser1Payload);
            userIdentifier = createUserData2?.data.userId;
            const deleteUser2Payload = { data: { apiBaseUrl, userIdentifier, token } };
            const deleteUserResponse = yield call(moderationService.deleteAccountModerationService, deleteUser2Payload);
            const { data: deleteUserData = {} } = deleteUserResponse || {};
            const { status: deleteUserStatus = 0 } = deleteUserData;
            yield put({ type: "SET_API_PROGRESS_DELETEACCOUNT", deleteAccountProgress: (deleteUserStatus === 200) ? 'success' : 'failure' });
            if (deleteUserStatus === 200 || deleteUserStatus !== 200) {
                yield all([
                    put({ type: "DELETE_ACCOUNT_MODERATION_DETAILS", deleteAccountModertionDetails: deleteUserData }),
                ]);
            }
        } else if (credentialStatus != 200){
            yield all([
                put({ type: "USER_CREDENTIAL_MODERATION_DETAILS", userCredentialModertionDetails: data }),
            ]);
        }
        else {
            serverNotRespond(message);
            yield all([
                put({ type: "USER_CREDENTIAL_MODERATION_DETAILS", userCredentialModertionDetails: {} }),
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            yield put({ type: "DO_LOADING", loading: false }),
        ]);
    }
    yield put({ type: "DO_LOADING", loading: false });
}

export default function* moderationSaga() {
    yield takeLatest(ModerationTypes.GET_USER_MODERATION_LIST, getUserModertionListSaga);
    yield takeLatest(ModerationTypes.GET_GROUP_MODERATION_LIST, getGroupModertionListSaga);
    yield takeLatest(ModerationTypes.GET_REPORT_MODERATION_LIST, getReportModertionListSaga);
    yield takeLatest(ModerationTypes.GET_USER_CREDENTIAL_MODERATION_DETAILS, getUserCredentialModertionSaga);
}
