import store from "../../store";
import { Post } from "../../common/httpRestServices";
import { leadingAndTrailingspaceAvoid, nullCheckString } from '../../helper';
import { getCountryCallingCode } from 'react-phone-number-input';
import { envPath, apiUrl, registerApiUrl } from "../../helper/ApiUrl";
import { doRegisterNewUserAction } from "../../store/action/loginAction";
import { convertToLowerCase, convertToUpperCase, passwordEncrypt, validateForm } from "../../helper/Validation";
import { translate } from "../MainContent/Settings/ServerConfigPage/commonServerCongig";

//customer page and memberPage  name validate
export const nameValidate = (value = "") => {
    return value.length <= 50 && value.length >= 1;
};

/**
 * @param  {string} value=""
 * @returns boolean
 * validate 100 char
 */
export const orgValidate = (value = "") => {
    return value.length <= 100 && value.length >= 1;
};

/**
 * @param  {object} data={}
 * onSubmit api call
 */
export const onsubmitRegister = (data = {}) => {
    const {
        ssoresponse = false,
        type = "", unique = "",
        organisationName = "", ipAddressValidate = 0,
        name = "", emailID = "", localIp = "", hupspotId = "",
        role = "", userCount = "", password = "", appType = "", trailCustomer = true,
        captchaToken = "", country = "", phoneNum = "", countryCode = "IN",
        applicationName = "", appStatus = "", techStack = "", userLookingModule = "",
    } = data;
    const passwordConvert = ssoresponse ? unique : passwordEncrypt(password);
    const logValue = {
        ...(ssoresponse && { "ssoId": leadingAndTrailingspaceAvoid(nullCheckString(unique)) }),
        ...(ssoresponse && { "ssoType": leadingAndTrailingspaceAvoid(nullCheckString(type)) }),
        "lastName": "",
        "ipAddress": localIp,
        "phoneNumber": phoneNum,
        "captchaToken": captchaToken,
        "trailCustomer": trailCustomer,
        "countryCodeShort": countryCode,
        "ipAddressValidate": ipAddressValidate,
        "userRole": leadingAndTrailingspaceAvoid(role),
        "firstName": leadingAndTrailingspaceAvoid(name),
        "emailId": leadingAndTrailingspaceAvoid(emailID),
        "country": leadingAndTrailingspaceAvoid(country),
        "countryCode": getCountryCallingCode(countryCode),
        "hubspotutk": envPath === "prod" ? hupspotId : null,
        "applicationType": leadingAndTrailingspaceAvoid(appType),
        "appStatus": leadingAndTrailingspaceAvoid(appStatus),
        "techStack": leadingAndTrailingspaceAvoid(techStack),
        "userLookingModule": leadingAndTrailingspaceAvoid(userLookingModule),
        "estimatedUsers": leadingAndTrailingspaceAvoid(userCount),
        "password": leadingAndTrailingspaceAvoid(passwordConvert),
        "applicationName": leadingAndTrailingspaceAvoid(applicationName),
        "organisationName": leadingAndTrailingspaceAvoid(organisationName),
    };
    // "buisnessAddress": leadingAndTrailingspaceAvoid(bussinessAddress),
    store.dispatch(doRegisterNewUserAction(logValue)); //loader when editing customerdetails
    store.dispatch({ type: "REGISTER_USER_LOADER", loading: true }); //loader when editing customerdetails
};

/**
 * success register popUp to default
 */
export const registerPopUpDefaultState = (data = {}) => {
    store.dispatch({ type: "ENABLE_REGISTER_SUCCESS_POP_UP", registerPopUp: null }); //loader when editing customerdetails
};

export const hubSpotApiImplement = async (inputDetails = {}) => {
    const {
        organisationName = "", ipAddressValidate = 0,
        name = "", emailID = "", localIp = "", hupspotId = "",
        country = "", phoneNum = "", countryCode = "IN",
    } = inputDetails;
    const newObj = {
        country: country,
        emailId: emailID,
        firstName: name,
        ipAddress: localIp,
        hubspotutk: hupspotId,
        phoneNumber: phoneNum,
        countryCodeShort: countryCode,
        organisationName: organisationName,
        ipAddressValidate: ipAddressValidate,
        countryCode: getCountryCallingCode(countryCode),
    };
    const response = await Post(`${apiUrl}${registerApiUrl.signupStepOne}`, newObj, true);
    // eslint-disable-next-line no-console
    console.log(response, "response");//don't remove console,
};

const containsNumber = (str = "") => {
    return /\d/.test(str);
};

/**
 * check contain have spl char added
 * @param {string} string
 */
export const containsSpecialCharCheck = (string = "") => {
    const reg = /^((?=.*\W)|(?=.*_))/;
    return reg.test(string) ? true : false;
};

/**
 * one num
 * one lowerCase
 * one upperCase
 * one spl char
 * eight digit
 * @param {string} string
 */
export const passwordPolicyUpdate = (string = "") => {
    const numberCase = containsNumber(string);
    const specialCase = containsSpecialCharCheck(string);
    const lowerCase = convertToUpperCase(string) !== string ? true : false;
    const upperCase = convertToLowerCase(string) !== string ? true : false;
    const eightDigitCase = convertToLowerCase(string).length >= 8 ? true : false;
    return {
        upperCase,
        lowerCase,
        numberCase,
        specialCase,
        eightDigitCase,
    };
};

/**
 * check all key value are true
 * @param {object} errorHandleObj
 */
export const continueButtonhideHandle = (errorHandleObj = {}, ssoresponse = false) => {
    const {
        nameError = false,//full name
        emailError = false,//email
        emailLenErr = false,//email
        orgNameError = false,//bussiness name
        orgwhitespace = false,//bussiness name
        nameHtmlError = false,
        phoneNumError = false,//phone number
        passwordError = false,//phone number
        namewhitespace = false,//full name
        emailWhiteSpace = false,//email
        passwordWhiteSpace = false,//password
        bussinessNameHtmlError = false,
        termsAndConditionsError = false
    } = errorHandleObj;
    const newObj = {
        nameError,
        emailError,
        emailLenErr,
        termsAndConditionsError,
        orgNameError,
        orgwhitespace,
        phoneNumError,
        ...(!ssoresponse && { passwordError: passwordError }), //false only validate
        ...(!ssoresponse && { passwordWhiteSpace: passwordWhiteSpace }),//false only validate
        nameHtmlError,
        namewhitespace,
        emailWhiteSpace,
        bussinessNameHtmlError,
    };
    return validateForm(newObj);
};

export const handleInputError = (inputName = '', errors = {}) => {
    const errorMappings = {
        name: {
            err1: "ERROR.FIRST_NAME_ERR_EMPTY",
            err2: "ERROR.BLANKSPACE_ERROR",
            err3: "ERROR.NAME",
            err4: "ERROR.HTML_ERROR",
        },
        organisationName: {
            err1: "ERROR.ORG_NEME_ERR_EMPTY",
            err2: "ERROR.BLANKSPACE_ERROR",
            err3: "ERROR.B_ADDRESS",
            err4: "ERROR.HTML_ERROR",
        },
        emailID: {
            err1: "ERROR.PUBLIC_EMAIL_ERROR",
            err2: "ERROR.BLANKSPACE_ERROR",
            err3: "ERROR.EMAIL_ERR",
            err4: "ERROR.NAME",
        },
        password: {
            err1: "ERROR.PASS_ERR_EMPTY",
            err2: "ERROR.BLANKSPACE_ERROR",
            err3: "ERROR.PASS_ERR",
        },
    };

    const errorMapping = errorMappings[inputName];
    if (errorMapping) {
        for (const [key, value] of Object.entries(errors)) {
        if (value && errorMapping[key]) {
            return translate(errorMapping[key]);
        }
        }
    }
    return "";
};
