export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";//USER PROFILE UPDATE
export const USER_ACCOUNT_DELETE_REQUEST = "USER_ACCOUNT_DELETE_REQUES";//USER ACCOUNT DELETE
export const USER_ACCOUNT_CANCEL_SUBSCRIPTION = "USER_ACCOUNT_CANCEL_SUBSCRIPTION";//USER SUBSCRIPTION CANCEL
export const GET_SERVER_CONFIGURATION = "GET_SERVER_CONFIGURATION";//get server details
export const DELETE_CERTIFICATE_DETAILS = "DELETE_CERTIFICATE_DETAILS";//delete certificate details
export const UPDATE_SERVER_CONFIGURATION = "UPDATE_SERVER_CONFIGURATION";//update server details
export const UPDATE_SERVER_SUPER_ADMIN_CONFIGURATION = "UPDATE_SERVER_SUPER_ADMIN_CONFIGURATION";//update server details FOR SUPER ADMIN
export const SAVEOREDIT_SERVER_SUPER_ADMIN_CONFIGURATION = "SAVEOREDIT_SERVER_SUPER_ADMIN_CONFIGURATION";//save and edit the server configuraiton for super and customer admin
export const REVOKE_PROFILE = "REVOKE_PROFILE";//REVOKE PROFILE
export const CANCEL_SUBSCRIPTION_REQUEST_STATUS = "CANCEL_SUBSCRIPTION_REQUEST_STATUS";//REVOKE PROFILE
export const SUBSCRIPTION_CANCELLATION = "SUBSCRIPTION_CANCELLATION"; // Upddate cancellation status
export const SEND_MAIL_TO_DEVOPS_ON_SKIP = 'SEND_MAIL_TO_DEVOPS_ON_SKIP';
