import { Post } from "../common/httpRestServices";
import { apiUrl, loginApi, registerApiUrl } from "../helper/ApiUrl";

export const fetchLogin = (obj = {}) => {
    const { data: { email = "", password = "", ssoType = "" } = {} } = obj;
    const logValue = {
        "email": email,
        "ssoType": ssoType,
        "password": password,
    };
    return Post(`${apiUrl}${loginApi.logIn}`, logValue);
};

export const registerUserService = (obj = {}) => {
    const { data = {} } = obj;
    return Post(`${apiUrl}${registerApiUrl.trialUser}`, data);
};
