import store from "../../store";
import * as appVerionTypes from '../actionTypes/appVerionTypes';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { failToast, serverNotRespond, succToast } from "../../helper/ApiToast";
import {
    appVersionList,
    addnewAppversions,
    downloadSDKandDOCservice,
    addnewAppversionsSDKandDOC,
    getLatestVersionSDKservice,
    getCallDurationBasedOrgIdService,
} from "../../services/appVerionServices";
import { reRenderCall } from "../../components/MainContent/APPVersions/AppVersionCommon";

let platformValues = [];

/**
 * add sdk or doc 500 and catch error handle,
 */
const serverRespondNotCall = () => {
    serverNotRespond(); //serverIs Not Respont Call this Toast
    store.dispatch({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false });
};

/**
 * add sdk or doc 200 above response handle,
 */
const uploadErrorResponse = (message = "") => {
    failToast(message);
    store.dispatch({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false });
};
/**
 * if 401 error  create new version
*/

const serverNotRespondCall = () => {
    serverNotRespond(); //serverIs Not Respont Call this Toast
    store.dispatch({ type: "APP_VERSION_API_LOAD", isListLoad: false }); //Loader false
    store.dispatch({ type: "APP_VERSION_API_LOAD_INITIAL", initaialRender: false }); //Loader false
};

/**
 * @param  {object} obj={}
 * this function helps to get all version list and search and pagination
 * status "200" Data retrived success
 * status "204" search data miss and pagination end
 */
function* getAppVerionsList(obj = {}) { //getPlanType Call
    try {
        const appVersionLists = yield call(appVersionList, obj);
        const { data = {} } = appVersionLists || {};
        if (data.status >= 200 && data.status <= 400) {
            yield all([
                put({ type: "APP_VERSION_LIST", appVersionData: data }), //Get planType Api call and store
                put({ type: "APP_VERSION_API_LOAD", isListLoad: false }), //Loader false
                put({ type: "APP_VERSION_API_LOAD_INITIAL", initaialRender: false }), //Loader false
            ]);
        } else if (data.status >= 400) {
            failToast(data.message);
            serverNotRespondCall();
        }
        else {
            serverNotRespondCall();
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "APP_VERSION_API_LOAD", isListLoad: false }), //Loader false
            put({ type: "APP_VERSION_API_LOAD_INITIAL", initaialRender: false }), //Loader false
        ]);
    }
}

/**
 * @param  {string} platformId=""
 * uploaded file is android or any platform find
 * appAddVersionData.length !==0 not true platFormValue append
 * appAddVersionData.length !==0 if false already some value in store so get and append from stor
 */
export const addDocSdkFileUrl = (platformId = "") => {
    const globalStore = store.getState() || {};
    const { addAppVersion: { appAddVersionData = [] } = {},
        addSDK: { platFormNewList = [] } = {} } = globalStore;
    if (appAddVersionData.length !== 0) {
        platformValues = appAddVersionData;
    } else {
        platformValues = platFormNewList;
    }
    return platformValues.findIndex((element) => "" + element.id === "" + platformId);
};

/**
 * @param  {object} obj={}
 * upload new sdk file
 * find which platform and append Url
 */
function* addNewVersionSDK(obj = {}) { //getPlanType Call
    try {
        const appVersionLists = yield call(addnewAppversionsSDKandDOC, obj);
        const { data: { status = 0, data = "", message = "" } = {} } = appVersionLists || {};
        if (status === 200) {
            const { data: { inputFileName: { platForm = "", size = "" } = {} } = {} } = obj || {};
            const elementsIndex = addDocSdkFileUrl(platForm); //get index from which platFrom upload SDK or DOC
            const newArray = [...platformValues]; //
            newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                size: size,
                urlSDK: data,
                isCheck: true //url store in SDK path
            };
            yield all([
                put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader
                put({ type: "APP_ADD_VERSION_LIST", appAddVersionData: newArray }), //store upload SDK file
            ]);
        } else if (status >= 201 && status <= 400) {
            uploadErrorResponse(message);
        } else {
            serverRespondNotCall();
        }
    } catch (error) {
        serverRespondNotCall();
    }
}
/**
 * @param  {object} obj={}
 * upload new sdk file
 * find which platform and append Url
 */
function* addNewVersionDOC(obj = {}) { //getPlanType Call
    try {
        const docUpload = yield call(addnewAppversionsSDKandDOC, obj);
        const { data: { status = 0, data = "", message = "" } = {} } = docUpload || {};
        if (status === 200) {
            const { data: { inputFileName: { platForm = "", docSize = "" } = {} } = {} } = obj || {};
            const elementsIndex = addDocSdkFileUrl(platForm); //get index from which platFrom upload SDK or DOC
            const newArray = [...platformValues];
            newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                docSize: docSize,
                urlDOC: data,
                isCheck: true //url store in SDK path
            };
            yield all([
                put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader
                put({ type: "APP_ADD_VERSION_LIST", appAddVersionData: newArray }), //store upload DOC file
            ]);
        } else if (status >= 201 && status <= 400) {
            uploadErrorResponse(message);
        } else {
            serverRespondNotCall();
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }),
        ]);
    }
}

/**
 * @param  {object} obj={}
 * upload new vertion to list
 */
function* newVersionUpload(obj = {}) { //getPlanType Call
    try {
        const appUpdate = yield call(addnewAppversions, obj);
        const { data: { status = 0, message = "" } = {} } = appUpdate || {};
        if (status === 200) {
            succToast(message);
            yield all([
                put({ type: "APP_VERSION_POPUP", statePopup: false }),
                put({ type: "ADD_NEW_VERSION_APP", isNewListLoad: true }),
            ]);
            reRenderCall({ page: 1, size: 11, searchTerm:''}, 'initial');
        } else {
            failToast(message);
            yield all([
                put({ type: "APP_VERSION_POPUP", isNewListLoad: true })
            ]);
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
    }
    yield put({ type: "APP_VERSION_API_LOAD", isListLoad: false }); // Disable loader not based on response
}

/**
 * @param  {object} obj={}
 * fileToken is pass
 */
function* downLoadAppSDKandDOC(obj = {}) {
    const { data: { name = "" } = {} } = obj || {};
    try {
        const downloadSdkandDoc = yield call(downloadSDKandDOCservice, obj);
        const { data = {}, status = 0 } = downloadSdkandDoc || {};
        if (status === 200) {
            const headerval = downloadSdkandDoc.headers['content-disposition'];
            const filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name ? name : filename);
            document.body.appendChild(link);
            link.click();
            yield all([
                put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader false
            ]);
        } else {
            yield all([
                put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader false
            ]);
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader false
        ]);
    }
}

/**
 * getlatest verion release in all platform
 */
function* getLatestVersionSDk(obj = {}) {
    try {
        const getLatestSDKdata = yield call(getLatestVersionSDKservice, obj);
        const { data = {} } = getLatestSDKdata || {};
        const { status = 0 } = data || {};
        if (status === 200) {
            yield all([
                put({ type: "getLatestVersionSDKLoader", getLatestVersionSDKLoader: false }), //loader false
                put({ type: "getLatestVersionSDKData", getLatestVersionSDKData: data }), //data append
            ]);
        } else {
            serverNotRespond();
            yield all([
                put({ type: "getLatestVersionSDKLoader", getLatestVersionSDKLoader: false }), //loader false
                put({ type: "getLatestVersionSDKData", getLatestVersionSDKData: {} }), //data append
            ]);
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "getLatestVersionSDKLoader", getLatestVersionSDKLoader: false }), //loader false
        ]);
    }
}

/**
 * getlatest verion release in all platform
 */
function* getOrgIdBasedCallDuration(obj = {}) {
    try {
        const response = yield call(getCallDurationBasedOrgIdService, obj);
        const { data: { status = 0, data = {}, message = "" } = {} } = response || {};
        if (status === 200) {
            yield all([
                put({ type: "GET_ORG_BASED_CALL_DURATION_DATA", callBasedOrgDurationData: data }), //data
                put({ type: "GET_ORG_BASED_CALL_DURATION_LOADER", callDurationLoader: false }), //loader false
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: "GET_ORG_BASED_CALL_DURATION_DATA", callBasedOrgDurationData: {} }), //data
                put({ type: "GET_ORG_BASED_CALL_DURATION_LOADER", callDurationLoader: false }), //loader false
            ]);
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "GET_ORG_BASED_CALL_DURATION_DATA", callBasedOrgDurationData: {} }), //data
            put({ type: "GET_ORG_BASED_CALL_DURATION_LOADER", callDurationLoader: false }), //loader false
        ]);
    }
}

function* downLoadLatestVersionSDk(obj = []) {
    const { data = [] } = obj || {};
    try {
        for (let i = 0; i <= data.length; i++) {
            yield all([
                call(downLoadAppSDKandDOC, { data: { fileToken: data[i].fileUrl } }),
                call(downLoadAppSDKandDOC, { data: { fileToken: data[i].documentUrl } })
            ]);
        }
    } catch (e) {
        yield all([
            // put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader false
        ]);
    }
}

function* downLoadLatestVersionserverConfig(obj = {}) {
    try {
        yield all([
            call(downLoadAppSDKandDOC, obj)
        ]);
    } catch (e) {
        yield all([
            // put({ type: "ADD_SDK_DOC_FILE", addSdkDocFile: false }), //loader false
        ]);
    }
}

export default function* rootAppVersionPostSaga() {
    yield takeLatest(appVerionTypes.ADD_NEW_VERSION, newVersionUpload);
    yield takeLatest(appVerionTypes.ADD_APP_VERSION_NEW, addNewVersionSDK);
    yield takeLatest(appVerionTypes.DOWNLOAD_SDK_COC, downLoadAppSDKandDOC);
    yield takeLatest(appVerionTypes.ADD_APP_VERSION_NEW_DCOC, addNewVersionDOC);
    yield takeLatest(appVerionTypes.GET_APP_VERSION_DETAILS, getAppVerionsList);
    yield takeLatest(appVerionTypes.GET_LATEST_VERSION_SDK_DOWNLOAD, getLatestVersionSDk);
    yield takeLatest(appVerionTypes.DOWNLOAD_LATEST_VERSION_SDK_DOWNLOAD, downLoadLatestVersionSDk);
    yield takeLatest(appVerionTypes.GET_CALL_DURATION_PASSED_ORG_ID_BASED, getOrgIdBasedCallDuration);
    yield takeLatest(appVerionTypes.DOWNLOAD_LATEST_VERSION_SERVER_CONFIG_DOWNLOAD, downLoadLatestVersionserverConfig);
}
