import { encodeGetParams } from "../helper/Encypt";
import { Get, Put } from "../common/httpRestServices";
import { apiUrl, customerApi } from "../helper/ApiUrl";

/**
 * @param  {object} obj;
 * view single user Details,
 * userId is endPoint
 */
export const getcusTeamsList = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : null;
    return Get(`${apiUrl}${customerApi.getCusList}${queryString}`, true);
};

/**
 * @param  {object} obj;
 * cus domain and hooks url update
 */
export const updateCusLicenseService = (obj = {}) => {
    const { data = {} } = obj;
    return Put(`${apiUrl}${customerApi.updateCusDomainHook}`, data, true);
};
