import * as appVerionTypes from '../actionTypes/appVerionTypes';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { succToast, serverNotRespond } from "../../helper/ApiToast";
import { appInternalRelease, getPlatformsListService, orgDetailsFetch } from '../../services/appReleaseServices';

/**
 * @param  {object} obj={}
 * internal Release
 */
export function* internalRelease(obj = {}) { //Release Method
    try {
        const appinternalRelease = yield call(appInternalRelease, obj);
        const { data: { status = 0, message = "" } = {} } = appinternalRelease || {};
        if (status === 200) { succToast(message); } else serverNotRespond();
        yield put({ type: "DO_INTERNAL_RELEASE_LOAD", interReleaseLoad: false });
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield put({ type: "DO_INTERNAL_RELEASE_LOAD", interReleaseLoad: false });
    }
}

export function* customReleaseGetOrgDetails(obj = {}) { //getorganization name
    try {
        const orgDetails = yield call(orgDetailsFetch, obj);
        const { data = {} } = orgDetails || {};
        if (data.status >= 200 && data.status <= 300) {
            yield all([
                put({ type: "DO_SEARCH_ORG_DETAILS_LOAD", orgDetailsSearchLoad: false }), //STORE ORG DETAILS
                put({ type: "DO_STORE_CUTOM_RELEASE_ORG_DETAILS", customReleasOrgDtls: data }), //STORE ORG DETAILS
            ]);
        } else {
            yield all([
                put({ type: "DO_SEARCH_ORG_DETAILS_LOAD", orgDetailsSearchLoad: false }), //STORE ORG DETAILS
            ]);
        }
    } catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "DO_SEARCH_ORG_DETAILS_LOAD", orgDetailsSearchLoad: false }), //STORE ORG DETAILS
        ]);
    }
}

export function* getPlatformsList() {
    try {
        const platformsList = yield call(getPlatformsListService, {});
        const { data : { status = 0, data = {} } } = platformsList || {};

        if (status === 200) {
            yield all([
                put({ type: "PLATFROM_LIST", platformsList: data?.platformList || [] }),
                put({ type: "LOADER", loader: false }),
            ]);
        }
        yield put({ type: "LOADER", loader: false });
    } catch (error) {
        serverNotRespond();
        yield put({ type: "LOADER", loader: false });
    }
}

export default function* rootAppReleasePostSaga() {
    yield takeLatest(appVerionTypes.APP_INTERNAL_RELEASE, internalRelease);
    yield takeLatest(appVerionTypes.APP_CUSTOM_RELEASE_ORG_DETAILS, customReleaseGetOrgDetails);
    yield takeLatest(appVerionTypes.PLATFROMS_LIST, getPlatformsList);
}
