import store from "../../../../store";
import { getCustDtls } from "../../../../store/action/customeAction";

/**
 * @param  {Number} customerId=0
 * pass cus Id and open side popUp
 */
export const profileEditCommonCall = (customerId = 0) => {
    const state = store.getState();
    const { CusPage: { customerDtls = {} } = {} } = state;
    if ("fullName" in customerDtls) {
        store.dispatch({ type: "CUSTOMER_PAGE_OPEN_CLOSE_EDIT", cusPageEditOpen: true }); //side popUp open
    } else {
        store.dispatch({ type: "CUSTOMER_PAGE_OPEN_CLOSE_EDIT", cusPageEditOpen: true }); //side popUp open
        store.dispatch({ type: "DO_LOADING_EDIT", loading: true }); //for getCusList Loader for
        store.dispatch({ type: "DO_LOADING", loading: true }); // for after update cusDetails Rerender
        store.dispatch(getCustDtls({ customerId: customerId })); //userId
    }
};

/**
 * @param  {Number} customerId=0
 * getCusDetails
 */
export const getCusDetails = (customerId = 0) => {
    store.dispatch({ type: "DO_LOADING", loading: true }); //loads ustomer details in customerlist
    store.dispatch({ type: "DO_LOADING_EDIT", loading: true }); //loader when editing customerdetails
    store.dispatch(getCustDtls({ customerId: customerId })); //get details
};
