import React, { Suspense } from "react";
import '../assets/scss/initial.scss';
import history from ".././common/History";
import {
    Route,
    Switch,
    Redirect,
    BrowserRouter as Router,
} from 'react-router-dom';
import {
    HelpPage,
    PopupPage,
    SettingPage,
    TeamsPage,
    DashboardPage,
    CustomersPage,
    callusageinfo,
    Allpermission,
    APPVersionsPage,
    paymentPagePermission,
} from ".././helper/RoleConfig";//permission
import Loader from "../common/Loader";
import Login from '.././components/Login';
import Registration from '.././components/Registration';

//linkedin redirect;
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

const Popup = React.lazy(() => import(".././components/PopupWrapper"));
const PrivatePage = React.lazy(() => import("../components/PrivatePage"));
const Help = React.lazy(() => import('.././components/MainContent/Help'));
const NotFoundPage = React.lazy(() => import(".././components/NotFound"));
const Teams = React.lazy(() => import('.././components/MainContent/Teams'));
const NetworkDetector = React.lazy(() => import("../common/NetworkDetector"));
const Setting = React.lazy(() => import('.././components/MainContent/Settings'));
const ToastContainerJSX = React.lazy(() => import("../common/ToastContainerJSX"));
const Payments = React.lazy(() => import('.././components/MainContent/Payments'));
const RecentActivity = React.lazy(() => import('.././components/MainContent/RecentActivity'));
const Overview = React.lazy(() => import('.././components/MainContent/Overview'));
const Dashboard = React.lazy(() => import('.././components/MainContent/Dashboard'));
const CallUsage = React.lazy(() => import('.././components/MainContent/CallUsage'));
const Customers = React.lazy(() => import('.././components/MainContent/Customers'));
const StripeStatus = React.lazy(() => import('.././components/PaymentStatusPopup'));
const Moderation = React.lazy(() => import('.././components/MainContent/Moderation'));
const ResetPassword = React.lazy(() => import('.././components/Login/ResetPassword'));
const APPVersions = React.lazy(() => import('.././components/MainContent/APPVersions'));
const RaiseTicket = React.lazy(() => import('.././components/MainContent/RaiseTicket'));
const ForgotPassword = React.lazy(() => import('.././components/Login/ForgotPassword'));
const Paymentprocess = React.lazy(() => import('.././components/MainContent/Settings/CustomerPlans'));
const CustomerCallUsageInfo = React.lazy(() => import('../components/MainContent/CustomerCallUsageInfo/index.jsx'));
const Notifications = React.lazy(() => import('.././components/MainContent/Notifications'));
const ActivityLogin = React.lazy(() => import('.././components/MainContent/ActivityLogin'));

const ScreenRouter = () => {

    return (
        <>
            <Router history={history} >
                <Suspense fallback={<Loader type={"fixed overlay"} />}>
                    <Switch>
                        <PrivatePage allowedRoles={HelpPage} path="/help" component={Help} />
                        <PrivatePage allowedRoles={TeamsPage} path="/teams" component={Teams} />
                        <PrivatePage allowedRoles={PopupPage} path="/popup" component={Popup} />
                        <PrivatePage allowedRoles={Allpermission} path="/payments" component={Payments} />
                        <PrivatePage allowedRoles={CustomersPage} path="/notifications" component={Notifications} />
                        <PrivatePage allowedRoles={Allpermission} path="/activity-log" component={ActivityLogin} />
                        <PrivatePage allowedRoles={APPVersionsPage} path="/recent-activity" component={RecentActivity} />
                        <PrivatePage allowedRoles={SettingPage} path="/moderation" component={Moderation} />
                        <PrivatePage allowedRoles={DashboardPage} path="/overview" component={Overview} />
                        <PrivatePage allowedRoles={CustomersPage} path="/profile" component={Customers} />
                        <PrivatePage allowedRoles={HelpPage} path="/raise-ticket" component={RaiseTicket} />
                        <PrivatePage allowedRoles={DashboardPage} path="/analytics" component={Dashboard} />
                        <PrivatePage allowedRoles={APPVersionsPage} path="/customers" component={Customers} />
                        <PrivatePage allowedRoles={CustomersPage} path="/customers/:token" render={(props) => <Customers {...props} />} />
                        <PrivatePage allowedRoles={APPVersionsPage} path="/call-usage" component={CallUsage} />
                        <PrivatePage allowedRoles={APPVersionsPage} path="/downloads" component={APPVersions} />
                        <PrivatePage allowedRoles={callusageinfo} path="/call-usage-info" component={CustomerCallUsageInfo} />
                        <PrivatePage allowedRoles={paymentPagePermission} path="/subscription" component={Paymentprocess} />
                        <PrivatePage allowedRoles={SettingPage} path="/Settings/:token?" render={(props) => <Setting {...props} />}
                            component={Setting} />
                        <PrivatePage allowedRoles={SettingPage} path="/payment-succeeded/:token?"
                            render={(props) => <Setting {...props} />}
                            component={Setting} />
                        <PrivatePage allowedRoles={SettingPage} path="/update-server-config/:token?"
                            render={(props) => <Setting {...props} />}
                            component={Setting} />

                        <Route path="/login/:token?" render={(props) => <Login {...props} />} />
                        <Route exact path="/" > <Login /></Route>
                        {/* linked in redirect router */}
                        <Route exact path="/linkedin"><LinkedInCallback /></Route>
                        <Route path="/forgetpassword"> <ForgotPassword /></Route>
                        {/* <Route path="/register/:token?"> <Registration /></Route> */}
                        <Route path="/register/:plantype?" render={(props) => <Login {...props} />} />

                        <Route path="/thank-you/:token?"> <Registration /></Route>

                        {/*stripe msgPopUp */}
                        <Route allowedRoles={Allpermission} path="/payment-fail/:token?" render={(props) => <StripeStatus {...props} />} />

                        {/* password reset and create both have Same Page */}
                        <Route path="/reset-password/:token" render={(props) => <ResetPassword {...props} />} />
                        <Route path="/create-password/:token" render={(props) => <ResetPassword {...props} />} />

                        {/* notFound page redirection */}
                        {/* <Route path="/templates"><DeveloperTemplates /></Route> */}
                        <Route path="/not-found"><NotFoundPage /></Route>
                        <Redirect to="/not-found" />
                    </Switch>
                </Suspense>
            </Router >
            {/* Toast msg container*/}
            <Suspense fallback={<></>}>
                < ToastContainerJSX />
            </Suspense>
            {/* network detector */}
            <Suspense fallback={<></>}>
                < NetworkDetector />
            </Suspense>
            {/*router Navigation */}
        </>
    );
};
export default React.memo(ScreenRouter);
