export const quickViewInitial = {
    quickViewData: {},//data
    quickViewLoad: false,//loadr
    callDurationLoader: false,//loader
    callBasedOrgDurationData: {},//call duration
};

export const dashBoardQuickView = (state = quickViewInitial, action = {}) => {

    switch (action.type) {
        case 'QUICKVIEW_INITIAL': {//initial call
            return {
                ...state,
                ...quickViewInitial
            };
        }

        case 'QUICKVIEW_DATA': {//dashBoard quickover view data
            return {
                ...state,
                quickViewData: action.quickViewData
            };
        }

        case 'QUICKVIEW_LOAD': {//loader
            return {
                ...state,
                quickViewLoad: action.quickViewLoad
            };
        }

        case 'GET_ORG_BASED_CALL_DURATION_DATA': {//callDuration data
            return {
                ...state,
                callBasedOrgDurationData: action.callBasedOrgDurationData
            };
        }

        case 'GET_ORG_BASED_CALL_DURATION_LOADER': {//loader
            return {
                ...state,
                callDurationLoader: action.callDurationLoader
            };
        }

        default:
            return {
                ...state
            };
    }
};
