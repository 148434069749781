import * as addSDKTypes from '../actionTypes/addSDKTypes';

/**
 * @param  {object} data={}
 * get sdkList
*/
export const getSDKlistData = (data = {}) => {
    return {
        type: addSDKTypes.GET_SDK_LIST,
        data,
    };
};

/**
 * @param  {object} data={}
 * delete sdk
*/
export const deleteSDKAction = (data = {}) => {
    return {
        type: addSDKTypes.DELETE_SDK_SERVICE,
        data,
    };
};

/**
 * @param  {object} data={}
 * edit sdk
*/
export const editSDKAction = (data = {}) => {
    return {
        type: addSDKTypes.EDIT_SDK_SERVICE,
        data,
    };
};

/**
 * @param  {object} data={}
 * add sdk
*/
export const addSDKAction = (data = {}) => {
    return {
        type: addSDKTypes.ADD_SDK_SERVICE,
        data,
    };
};
