import { Get,Put } from "../common/httpRestServices";
import { encodeGetParams } from "../helper/Encypt";
import { apiUrl, notificationApi} from "../helper/ApiUrl";

/**
 * @param  {} obj={}
 * get group list
 */
export const getNotificationService = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : null;
    return Get(`${apiUrl}${notificationApi.notificationHistory}?${queryString}`, true);
};
export const getnotificationRequestCount = () => {
    return Get(`${apiUrl}${notificationApi.notificationCount}`, true);
}
export const getnotificationFilter = () => {
    return Get(`${apiUrl}${notificationApi.notificationFilter}`, true);
}
export const getnotificationHistoryCount = () => {
    return Get(`${apiUrl}${notificationApi.notificationHistoryCount}`, true);
}
export const getnotificationUpdateCountService = (obj= {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : null;
    return Put (`${apiUrl}${notificationApi.notificationUpdateCount}?${queryString}`, true);
}
