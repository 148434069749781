import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as profileUpdateTypes from '../actionTypes/profileUpdateTypes';
import { succToast, failToast, serverNotRespond } from "../../helper/ApiToast";

import {
    deleteCertificateIOSservice,
    getServerDetailsServeics,
    updateProfleService,
    updateServerConfigDetailsServeics,
    updateServerConfigDetailssuperAdminServeics,
    saveOrEditServerConfigService,
    cancelSubscriptionRequestService,
    deleteRequestService,
    cancelSubscriptionRequestStatusService,
    subscriptionCancellationService,
    revokeServerDetailsService,
    sendEmptyMailToDevOpsService
} from '../../services/profileUpadteServices';
import { getCancelRequestStatus } from '../../components/MainContent/Customers/CustomerList/cusPageCommon';
import History from '../../common/History';

/**
 * @param  {} obj={}
 * profile update and create
 */
function* profileUpdateCreate(obj = {}) {
    try {
        const profileUpdate = yield call(updateProfleService, obj);
        const { data = {} } = profileUpdate;
        const { status = 0, message = "" } = data;
        if (status === 200) {
            succToast(message);
            yield all([
                put({ type: 'PROFILE_UPDATE_DONE', profileUpdateStatus: true }),//
                put({ type: 'PROFILE_UPDATE_LOADER', profileUpdateLoader: false }),//loader false
                put({ type: 'PROFILE_UPDATE_RELOAD', profileUpdateStatusReload: true }),//reload api
                put({ type: 'PROFILE_UPDATE_DATA', profileUpdateData: data }),//status data
            ]);
        } else if (status === 400) {
            failToast(message);
            yield all([
                put({ type: 'PROFILE_UPDATE_DONE', profileUpdateStatus: false }),
                put({ type: 'PROFILE_UPDATE_LOADER', profileUpdateLoader: false }),//loader false
                put({ type: 'PROFILE_UPDATE_RELOAD', profileUpdateStatusReload: false }),//reload api
                put({ type: 'PROFILE_UPDATE_DATA', profileUpdateData: {} }),//status data
            ]);
        } else {
            serverNotRespond(message);//server is not responding call this toast
            yield all([
                put({ type: 'PROFILE_UPDATE_DONE', profileUpdateStatus: false }),
                put({ type: 'PROFILE_UPDATE_LOADER', profileUpdateLoader: false }),//loader false
                put({ type: 'PROFILE_UPDATE_RELOAD', profileUpdateStatusReload: false }),//reload api
                put({ type: 'PROFILE_UPDATE_DATA', profileUpdateData: {} }),//status data
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: 'PROFILE_UPDATE_DONE', profileUpdateStatus: false }),
            put({ type: 'PROFILE_UPDATE_LOADER', profileUpdateLoader: false }),//loader false
        ]);
    }
}

/**
 * @param  {} obj={}
 * get customer configuration details
 */
function* getServerConfigSaga(obj = {}) {
    try {
        const profileUpdate = yield call(getServerDetailsServeics, obj);
        const { data = {} } = profileUpdate;
        const { data: { status = 0 } = {} } = profileUpdate;

        if (status === 200) {
            yield all([
                put({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: data }),//loader false
            ]);
        } else {
            yield all([
                put({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: {} }),//loader false
            ]);
        }
    }
    catch (error) {
        serverNotRespond();//server is not responding call this toast
        yield all([
            put({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: {} }),//loader false
        ]);
    }
    yield put({ type: 'SERVER_CONFIG_DATA_GET_LOADER', getServerConfigDetailLoader: false });
}

/**
 * @param  {} obj={}
 * update cus server configuration details
 */
function* updateCustomerServerConfigSaga(obj = {}) {
    try {
        const profileUpdate = yield call(updateServerConfigDetailsServeics, obj);
        const { data: { status = 0, message = "" } = {} } = profileUpdate;
        if (status === 200) {
            yield all([
                put({ type: 'PUT_THE_SUCCESS_CODE_POP_UP', openThePopUp: true }),//
                put({ type: 'IOS_CERTIFICATE_DELETE_RELOAD_GET', reloadCertificateDetails: true }),//
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: 'PUT_THE_SUCCESS_CODE_POP_UP', openThePopUp: null }),//
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: 'PUT_THE_SUCCESS_CODE_POP_UP', openThePopUp: null }),//
        ]);
    }
    yield put({ type: 'SERVER_CONFIG_DATA_UPDATE_LOADER', updateServerConfigDetailLoader: false });
}

/**
 * @param  {} obj={}
 * update cus server configuration details
 */
function* updateSuperAdminServerConfigSaga(obj = {}) {
    try {
        const configUpdate = yield call(updateServerConfigDetailssuperAdminServeics, obj);
        const { data: { status = 0, message = "" } = {} } = configUpdate;
        if (status === 200) {
            succToast(message);
            yield all([
                put({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: true }),//server config reload
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: true }),//server config reload
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: true }),//server config reload
        ]);
    }
    yield put({ type: 'SERVER_CONFIG_DATA_UPDATE_LOADER', updateServerConfigDetailLoader: false });
}

/**
 * @param  {} obj={}
 * update cus server configuration details
 */
function* saveOrEditServerConfigSaga(obj = {}) {
    try {
        const configUpdate = yield call(saveOrEditServerConfigService, obj);
        const { data: { status = 0, message = "" } = {} } = configUpdate;
        if (status === 200) {
            if(obj.data.userRoleId === 1) succToast(message);
            yield all([
                put({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: true }),//server config reload
                put({ type: 'PUT_THE_SUCCESS_CODE_POP_UP', openThePopUp: (obj.data.userRoleId === 4  || obj.data.userRoleId === 5) ? true : false }),
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: true }),//server config reload
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: 'SERVER_CONFIG_DATA_UPDATE_RELOAD', updateServerConfigDetailReload: true }),//server config reload
        ]);
    }
    yield put({ type: 'SERVER_CONFIG_DATA_UPDATE_LOADER', updateServerConfigDetailLoader: false });
}

/**
 * @param  {} obj={}
 * delete ios certificate
 */
function* deleteIosCertificateSaga(obj = {}) {
    try {
        const profileUpdate = yield call(deleteCertificateIOSservice, obj);
        const { data: { status = 0, message = "" } = {} } = profileUpdate;
        if (status === 200) {
            yield all([
                put({ type: 'IOS_CERTIFICATE_DELETE_LOADER', iosCertificateDeteleLoader: false }),
                put({ type: 'IOS_CERTIFICATE_DELETE_RELOAD_GET', reloadCertificateDetails: true }),
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: 'IOS_CERTIFICATE_DELETE_LOADER', iosCertificateDeteleLoader: false }),
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: 'IOS_CERTIFICATE_DELETE_LOADER', iosCertificateDeteleLoader: false }),
        ]);
    }
}

/**
 * @param  {} obj={}
 * delete ios certificate
 */
function* revokeApiCredentialSaga(obj = {}) {
    try {
        const profileUpdate = yield call(revokeServerDetailsService, obj);
        const { data = {} } = profileUpdate;
        const { data: { status = 0 } = {} } = profileUpdate;

        if (status === 200) {
            yield put({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: data });
        } else {
            yield put({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: {} });
        }
    }
    catch (error) {
        serverNotRespond();//server is not responding call this toast
        yield put({ type: 'SERVER_CONFIG_DATA_GET_DATA', getServerConfigDetailData: {} });
    }
    yield put({ type: 'SERVER_CONFIG_DATA_GET_LOADER', getServerConfigDetailLoader: false });
}

/**
 * @param  {} obj={}
 * profile update and create
 */
function* deleteAccountRequest(obj = {}) {
    try {
        const profileDeleteRequest = yield call(deleteRequestService, obj);
        const { data = {} } = profileDeleteRequest;
        const { status = 0, message = "" } = data;
        if (status === 200) {
            succToast(message);
            getCancelRequestStatus(obj?.data?.userId);
            yield put({ type: "DELETE_REQUEST_STATUS", deleteRequestStatus: true });
        } else {
            failToast(message);
            yield put({ type: "DELETE_REQUEST_STATUS", deleteRequestStatus: false });
        }
    }
    catch (error) {
        serverNotRespond();
    }
    yield put({ type: 'PROFILE_UPDATE_LOADER', profileUpdateLoader: false });
}

/**
 * @param  {} obj={}
 * Cancel subscription request
 */
function* accountCancelSubscriptionRequestSaga(obj = {}) {
    try {
        const response = yield call(cancelSubscriptionRequestService, obj);
        const { data: { status = 0, message = "" } } = response;

        if (status === 200) {
            succToast(message);
        } else {
            failToast(message);
        }
    }
    catch (error) {
        serverNotRespond();
    }
    yield put({ type: 'PROFILE_UPDATE_LOADER', profileUpdateLoader: false });
}

/**
 * @param  {} obj={}
 * Cancel subscription status check
 */
function* accountCancelSubscriptionRequestStatusSaga(obj = {}) {
    try {
        const response = yield call(cancelSubscriptionRequestStatusService, obj);
        const { data: { status = 0, data: { subscriptionCancelStatusDetails = {} } } } = response;

        if (status === 200) {
            yield put({ type: 'CANCEL_SUBSCRIPTION_STATUS', subscriptionCancelStatusDetails });
        } else {
            yield put({ type: 'CANCEL_SUBSCRIPTION_STATUS', subscriptionCancelStatusDetails: {} });
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: 'CANCEL_SUBSCRIPTION_STATUS', subscriptionCancelStatusDetails: {} });
    }
}

/**
 * @param {}
 * Update customer subscription status via Admin
 */
function* subscriptionCalncellationSaga(obj = {}) {
    const response = yield call(subscriptionCancellationService, obj);
    const { data: { status = 0, message = "" } } = response;

    try {
        status === 200 ? succToast(message) : failToast(message);
        getCancelRequestStatus(obj?.data?.userId);
        yield put({ type: 'ADD_CUS_LOADER', addcusLoader: true });

    } catch (error) {
        serverNotRespond();
        yield put({ type: 'ADD_CUS_LOADER', addcusLoader: true });
    }
}

/**
 * @param {}
 * Update customer subscription status via Admin
 */
function* sendEmptyMailToDevOps(obj) {
    const {  redirect = true ,userId = 0 } = obj;
    const res = yield call(sendEmptyMailToDevOpsService,userId);
    try {
        yield put({ type: 'SERVER_CONFIG_DATA_GET_LOADER', getServerConfigDetailLoader: false });
        if (redirect && res.data.status === 200) History.push({ pathname: "/overview" });
    } catch (error) {
        serverNotRespond();
        yield put({ type: 'SERVER_CONFIG_DATA_GET_LOADER', getServerConfigDetailLoader: false });
    }
}

export default function* profileUpdateSaga() {
    yield takeLatest(profileUpdateTypes.USER_PROFILE_UPDATE, profileUpdateCreate);
    yield takeLatest(profileUpdateTypes.USER_ACCOUNT_DELETE_REQUEST, deleteAccountRequest);
    yield takeLatest(profileUpdateTypes.GET_SERVER_CONFIGURATION, getServerConfigSaga);
    yield takeLatest(profileUpdateTypes.DELETE_CERTIFICATE_DETAILS, deleteIosCertificateSaga);
    yield takeLatest(profileUpdateTypes.USER_ACCOUNT_CANCEL_SUBSCRIPTION, accountCancelSubscriptionRequestSaga);
    yield takeLatest(profileUpdateTypes.UPDATE_SERVER_CONFIGURATION, updateCustomerServerConfigSaga);
    yield takeLatest(profileUpdateTypes.UPDATE_SERVER_SUPER_ADMIN_CONFIGURATION, updateSuperAdminServerConfigSaga);
    yield takeLatest(profileUpdateTypes.SAVEOREDIT_SERVER_SUPER_ADMIN_CONFIGURATION, saveOrEditServerConfigSaga);
    yield takeLatest(profileUpdateTypes.CANCEL_SUBSCRIPTION_REQUEST_STATUS, accountCancelSubscriptionRequestStatusSaga);
    yield takeLatest(profileUpdateTypes.REVOKE_PROFILE, revokeApiCredentialSaga);
    yield takeLatest(profileUpdateTypes.SUBSCRIPTION_CANCELLATION, subscriptionCalncellationSaga);
    yield takeLatest(profileUpdateTypes.SEND_MAIL_TO_DEVOPS_ON_SKIP, sendEmptyMailToDevOps);
}
