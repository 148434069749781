import * as teamsTypes from '../actionTypes/teamsTypes';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
    failToast,
    infoToast,
    succToast,
    serverNotRespond,
} from "../../helper/ApiToast";
import {
    addTeamMember,
    deleteMemberList,
    updateUserDetail,
    getMemberListData,
    getSingleUserDetails,
    getRoleAccessData,
    getSaveorEditData,
} from '../../services/teamsServices';
import { getCalluseageDetailsApiCall } from '../../components/MainContent/Dashboard/DashBoardAnalyticsOverview/AnalyticsCommon';

/**
 * @param  {object} obj
 * add new member
 */
function* addMemberSaga(obj = {}) {
    try {
        const response = yield call(addTeamMember, obj);
        const { data: { status = 0, message = "" } = {} } = response || {};
        if (status === 200) {
            succToast(message);
            yield all([
                put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }), //load teams page on success of api call
                put({ type: "MEMBER_POPUP_OPEN", memberPopupOpen: false })//poPop  close afetr success
            ]);
        } else {
            failToast(message);
            yield put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }); //load teams page on success of api call
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }); //load teams page on success of api call

    }
}

/**
 * @param  {object} obj
 * update
 */
function* updateUserDetails(obj = {}) { //Edit or Update Call
    try {
        const updateCusData = yield call(updateUserDetail, obj);
        const { data: { status = 0, message = "" } = {} } = updateCusData || {};
        if (status === 200) {
            succToast(message);
            yield all([
                put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }), //load teams page on success of api call
                put({ type: "MEMBER_POPUP_OPEN", memberPopupOpen: false })
            ]);
        } else {
            failToast(message);
            yield put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }); //load teams page on success of api call
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }); //load teams page on success of api call
    }
}

/**
 * @param  {object} obj={}
 * getMemberList
 */
function* getMemberList(obj = {}) { //GetMember Data
    try {
        const getMemberData = yield call(getMemberListData, obj);
        const { data = {} } = getMemberData || {};
        const { status = 0 } = data || {};
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "GET_MEMBER_LIST_DATA", MemberData: data }),//GetMember List and store
                put({ type: "GET_MEMBER_LIST_DATA_LOAD", memberListLoad: false }),
            ]);
        } else {
            serverNotRespond(); //serverIs Not Respont Call this Toast
            yield all([
                put({ type: "GET_MEMBER_LIST_DATA_LOAD", memberListLoad: false }),
            ]);
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "GET_MEMBER_LIST_DATA_LOAD", memberListLoad: false }),
        ]);
    }
}

/**
 * @param  {object} obj;
 * inside object have array is contain
 * have user deleted id
 */
function* deleteMember(obj = {}) {
    try {
        const delCusData = yield call(deleteMemberList, obj.customerIds);
        const { data: { status = 0, message = "" } = {} } = delCusData || {};
        if (status === 200) {
            succToast(message);//
            yield all([
                put({ type: "MEMBER_POPUP_OPEN", memberPopupOpen: false }),//close side menu popUp
                put({ type: "TEAM_MEMBER_RELOAD_LOADER", loading: false }),//deleted seccuessfull list reRender
            ]);
        } else {
            infoToast(message); //200 status missing
            yield all([
                put({ type: "TEAM_MEMBER_RELOAD_LOADER", memberListLoad: false }),//deleted seccuessfull list reRender
            ]);
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "TEAM_MEMBER_RELOAD_LOADER", memberListLoad: false }),//deleted seccuessfull list reRender
        ]);
    }
}

function* getUserDetails(obj = {}) {
    try {
        const delCusData = yield call(getSingleUserDetails, obj);
        const { data: { status = 0, message = "", data: { userDetails = {} } = {} } = {} } = delCusData || {};
        if (status === 200) {
            yield all([
                put({ type: "MEMBER_CUSTOMER_DETAILS", userDtls: userDetails }),//Result Store
                put({ type: "EDIT_MEMBER_LOADER", editLoader: false }),//Loader open/close
            ]);
        } else {
            failToast(message);
            yield all([
                put({ type: "MEMBER_CUSTOMER_DETAILS", userDtls: {} }),//Result Store
                put({ type: "EDIT_MEMBER_LOADER", editLoader: false }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "EDIT_MEMBER_LOADER", editLoader: false }),// Loader open/close
        ]);
        serverNotRespond(); //serverIs Not Respont Call this Toast
    }
}

/**
 * @param  {object} obj={}
 * getMemberList
 */
function* getCallUasgeListSaga(obj = {}) { //GetMember Data
    yield put({ type: "GET_MEMBER_LIST_DATA_LOAD", memberListLoad: true });
    try {
        const response = yield call(getCalluseageDetailsApiCall, obj.data);
        const { data: { status = 0 } } = response || {};
        if (status === 200) {
            yield put({ type: "GET_CALL_USAGE_LIST_DATA", callUsageData: response.data || {} });
        } else {
            serverNotRespond(); //serverIs Not Respont Call this Toast
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
    }
    yield put({ type: "GET_MEMBER_LIST_DATA_LOAD", memberListLoad: false });
}
function* getRoleAccessList(obj = {}) { //GetMember Data
    try {
        const getRoleAccess = yield call(getRoleAccessData, obj);
        const { data = {} } = getRoleAccess || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "GET_ROLE_ACCESS_DATA", roleAccessList: data }),
                put({ type: "GET_ROLE_ACCESS_DATA_LOAD", roleAccessLoader: false }),
            ]);
        } else {
            serverNotRespond(message); //serverIs Not Respont Call this Toast
            yield all([
                put({ type: "GET_ROLE_ACCESS_DATA_LOAD", roleAccessLoader: false }),
            ]);
        }
    }
    catch (error) {
        serverNotRespond(); //serverIs Not Respont Call this Toast
        yield all([
            put({ type: "GET_ROLE_ACCESS_DATA_LOAD", roleAccessLoader: false }),
        ]);
    }
}
function* saveAndEditSaga(obj = {}) {
    try {
        const response = yield call(getSaveorEditData, obj);
        const { data: { status = 0, message = "" } = {} } = response || {};
        if (status === 200) {
            succToast(message);
            yield all([
                put({ type: "GET_ROLE_ACCESS_DATA_LOAD", roleAccessLoader: false }),
                put({ type: "SAVE_AND_EDIT_DATA", saveAndEditData: data })//poPop  close afetr success
            ]);
        } else {
            failToast(message);
            yield put({ type: "GET_ROLE_ACCESS_DATA_LOAD", roleAccessLoader: false }) //load teams page on success of api call
        }
    }
    catch (error) {
        serverNotRespond();
        yield put({ type: "GET_ROLE_ACCESS_DATA_LOAD", roleAccessLoader: false }) //load teams page on success of api call

    }
}
function* getSideMenuDetailsSaga(obj) { //Singele Profile view
    try {
        const getRoleAccess = yield call(getRoleAccessData, obj);
        const { data = {} } = getRoleAccess || {};
        const { status = 0} = data;
        if (status === 200) {
            yield all([
                put({ type: "SIDEMENU_DETAILS", sideMenuDtls: data }),//Result Store
                // put({ type: "DO_LOADING_EDIT", loading: false }),//Loader open/close
            ]);
        } else {
            yield all([
                put({ type: "SIDEMENU_DETAILS", sideMenuDtls: {} }),//Result Store
                // put({ type: "DO_LOADING_EDIT", loading: false }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "SIDEMENU_DETAILS", sideMenuDtls: {} }),//Result Store
            // put({ type: "DO_LOADING_EDIT", loading: false }),//Loader open/close
        ]);
    }
}
export default function* teamsSaga() {
    yield takeLatest(teamsTypes.MEMBER_DELETE, deleteMember);
    yield takeLatest(teamsTypes.TYPE_ADD_MEMBER, addMemberSaga);
    yield takeLatest(teamsTypes.GET_MEMBER_LIST, getMemberList);
    yield takeLatest(teamsTypes.GET_MEMBER_DETAILS, getUserDetails);
    yield takeLatest(teamsTypes.UPDATE_MEMBER_DETAILS, updateUserDetails);
    yield takeLatest(teamsTypes.GET_CALL_UASGE_LIST, getCallUasgeListSaga);
    yield takeLatest(teamsTypes.GET_ROLE_ACCESS_LIST, getRoleAccessList);
    yield takeLatest(teamsTypes.GET_SIDEMENU_DETAILS, getSideMenuDetailsSaga);
    yield takeLatest(teamsTypes.GET_SAVE_AND_EDIT_DATA, saveAndEditSaga);
}
