import { Get, Post } from "../common/httpRestServices";
import { encodeGetParams } from "../helper/Encypt";
import { apiUrl, moderationApi } from "../helper/ApiUrl";

/**
 * @param  {} obj={}
 * get group list
 */
export const getGroupModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${moderationApi.group}?${queryString}`, true);
};

/**
 * @param  {} obj={}
 * get report list
 */
export const getReportModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${moderationApi.report}?${queryString}`, true);
};

/**
 * @param  {} obj={}
 * get group list
 */
export const getUserModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${moderationApi.user}?${queryString}`, true);
};

/**
 * @param  {} obj={}
 * get user Credential
 */
export const getUserCredentialModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${moderationApi.userCredential}?${queryString}`, true);
};

/**
 * @param  {} obj={}
 * get user Login
 */
export const getUserLoginModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Post(`${apiUrl}${moderationApi.login}?${queryString}`, true);
};

/**
 * @param  {} obj={}
 * user creation
 */
export const createUserModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Post(`${apiUrl}${moderationApi.createUser}?${queryString}`, true);
};

/**
 * @param  {} obj={}
 * send message
 */
export const sendMessageModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Post(`${apiUrl}${moderationApi.sendMessage}?${queryString}`, true);
};

export const chatHistoryModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${moderationApi.chatHistory}?${queryString}`, true);
};
/**
 * @param  {} obj={}
 * delete account
 */
export const deleteAccountModerationService = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Post(`${apiUrl}${moderationApi.deleteUserAccount}?${queryString}`, true);
};
