import { all, fork } from 'redux-saga/effects';
import TeamsSaga from './teamsSaga';
import LoginSaga from './loginSaga';
import addSDKSaga from './addSDKSaga';
import PaymentSaga from "./paymentSaga";
import CustomerSaga from './customerSaga';
import CusTeamsSaga from './cusTeamsSaga';
import ResetPassSaga from './resetPassSaga';
import dashBoardSaga from './dashBoardSaga';
import ForgetPassSaga from './forgetPassSaga';
import AppVersionSaga from './appVersionSaga';
import AppReleaseSaga from './appReleaseSaga';
import moderationSaga from './moderationSaga';
import profileUpdateSaga from './profileUpdateSaga';
import stripePaymentSaga from './stripePaymentSaga';
import ChangePasswordSaga from './ChangePasswordSaga';
import notificationSaga from './notificationSaga';
import activityLogSaga from './activityLogSaga';

export default function* rootSaga() {
    yield all([
        fork(TeamsSaga),//TeamsPage(list.addMember)
        fork(LoginSaga),//login
        fork(addSDKSaga),//addSDK Data
        fork(PaymentSaga),//paymentInvoice
        fork(CustomerSaga),//customer(list,addCustomer)
        fork(CusTeamsSaga),//app release
        fork(ResetPassSaga),//Reset
        fork(dashBoardSaga),//dashBoard release
        fork(ForgetPassSaga),//Forget
        fork(AppVersionSaga),//AppVersion
        fork(moderationSaga),//moderation list
        fork(AppReleaseSaga),//app release
        fork(stripePaymentSaga),//payMent Process
        fork(profileUpdateSaga),//profileUpdate
        fork(ChangePasswordSaga),//change password
        fork(notificationSaga),//notification list
        fork(activityLogSaga),//activitylog list
    ]);
}
