import Cryptlib from "cryptlib";
import CryptoJS from "crypto-js";
import { secretKEY} from "./ApiUrl";
import { moveToLoginPage } from "./RoleConfig";

let userToken = "";

const logOutUnAuthCall = () => {
    moveToLoginPage();
};

/**
 * @param  {object} value;
 * user input encrypt Here
 */
export const customerIdEncrypt = (value = 0) => {
    try {
        const cipherEncrypt = CryptoJS.AES.encrypt(JSON.stringify(value), secretKEY);
        sessionStorage.setItem("cryptoJScustomerId", cipherEncrypt);
        return true;
    } catch (e) {
        return null;
    }
};

export const decryptProfileDetails = (data = "", decrptKey = "", licenseKey = "") => {
    try {
        const key = Cryptlib.getHashSha256(decrptKey, 32);
        return decodeURIComponent(Cryptlib.decrypt(data, key, licenseKey));
    } catch (error) {
        return data;
    }
};

export const customerIdDecrypt = () => {
    if (sessionStorage.getItem("cryptoJScustomerId") !== null) {
        try {
            const cipherDecrypt = sessionStorage.getItem("cryptoJScustomerId");
            const bytes = CryptoJS.AES.decrypt(cipherDecrypt.toString(), secretKEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (e) {
            return null;
        }
    } else {
        logOutUnAuthCall();
    }
};

/**
 * @param  {object} value;
 * user input encrypt Here
 */
export const Encrypt = (value = {}) => {
    try {
        const cipherlogUser = CryptoJS.AES.encrypt(JSON.stringify(value), secretKEY);
        sessionStorage.setItem("cipherlogUser", cipherlogUser);
        return true;
    } catch (e) {
        return null;
    }
};

/**
 * encrypt value decrypt Here
 */
export const Decrypt = () => {
    if (sessionStorage.getItem("cipherlogUser") !== null) {
        try {
            const ciphertext = sessionStorage.getItem("cipherlogUser");
            const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (e) {
            return null;
        }
    } else {
        logOutUnAuthCall();
    }
};

/**
 * @param  {object} value;
 * user input encrypt Here
 */
export const EncryptLogInDetails = (value = {}) => {
    const { data: { jwt = "" } = {} } = value;
    userToken = jwt;
    try {
        const isCustomerAdmin = sessionStorage.getItem('isCustomerAdmin');
        // Check if isCustomerAdmin is equal to 1
        if (isCustomerAdmin === "1") {
            const userDetailsString = sessionStorage.getItem('userDetails');
            const userDetails = JSON.parse(userDetailsString);
            const cipherlogUser = CryptoJS.AES.encrypt(JSON.stringify(userDetails), secretKEY);
            sessionStorage.setItem("userData", cipherlogUser);
            return true;
        } else {
            // Set default value if isCustomerAdmin is not equal to 1
            const cipherlogUser = CryptoJS.AES.encrypt(JSON.stringify(value), secretKEY);
            sessionStorage.setItem("userData", cipherlogUser);
            return true;
        }
    } catch (e) {
        logOutUnAuthCall();
    }
};

/**
 * encrypt value decrypt Here
 */
export const DecryptLogInDetails = () => {
    try {
        const ciphertext = sessionStorage.getItem("userData");
        const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) || {};
    } catch (e) {
        logOutUnAuthCall();
    }
};

export const userDetailsSet = (data = {}) => {
    const { data: { jwt = "" } = {} } = data;
    userToken = jwt;
    const isCustomerAdmin = sessionStorage.getItem('isCustomerAdmin');
    if (isCustomerAdmin === "1") {
        const userdetails = JSON.parse(sessionStorage.getItem('customerAdmin'))
        sessionStorage.setItem("userDetails", JSON.stringify(userdetails));
    }else{
        sessionStorage.setItem("userDetails", JSON.stringify(data));
        sessionStorage.setItem("superadminDetails", JSON.stringify(data));
   }
};

/**
 * @param  {object} params;
 * Api request params convert to queryParams
 */
export const encodeGetParams = (params) => {
    return Object.entries(params).map(key => key.map(encodeURIComponent).join("=")).join("&");
};

/**
 *userToken
 */
export const userDataTokenReturn = () => {
    return userToken;
};

/**
 *internet  online offline stauts
 *encryption
 */
export const internetStatusEncrypt = (stauts = false) => {
    try {
        const cipherlogUser = CryptoJS.AES.encrypt(JSON.stringify(stauts), secretKEY);
        sessionStorage.setItem("onlineStatus", cipherlogUser);
        return true;
    } catch (e) {
        logOutUnAuthCall();
    }
};

/**
 *internet  online offline stauts
 *Decrypt
 */
export const internetStatusDecrypt = () => {
    try {
        const ciphertext = sessionStorage.getItem("onlineStatus");
        const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
        logOutUnAuthCall();
    }
};

/**
 * @param  {object} value;
 */
export const payMentEncrypt = (value = {}) => {
    try {
        const payment = CryptoJS.AES.encrypt(JSON.stringify(value), secretKEY);
        sessionStorage.setItem("payment_succeeded_fail", payment);
        return true;
    } catch (e) {
        return null;
    }
};

/**
 * encrypt value decrypt Here
 */
export const payMentDecrypt = () => {
    if (sessionStorage.getItem("payment_succeeded_fail") !== null) {
        try {
            const ciphertext = sessionStorage.getItem("payment_succeeded_fail");
            const bytes = CryptoJS.AES.decrypt(ciphertext.toString(), secretKEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (e) {
            return null;
        }
    } else {
        return {};
    }
};
