import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as forgetPassTypes from '../actionTypes/forgetPassTypes';
import { commonDeleteAPIService, forgetPassReques } from "../../services/forgetPassServices";
import { succToast, failToast, serverNotRespond } from "../../helper/ApiToast";

function* doForget(obj = {}) {
    // const obj = { data:{"email": "email"}} obj Data;
    try {
        const loginData = yield call(forgetPassReques, obj);
        const { data = {} } = loginData || {};
        if (data.status === 200) {
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }), //Loader open/close
            ]);
            succToast(data.message);
        } else if (data.status >= 201 && data.status <= 400) {
            failToast(data.message);
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//Loader open/close
            ]);
        } else {
            serverNotRespond(data.message);
            yield all([
                put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),//lodering on / off
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "DO_LOADING_LOGIN_PAGE", loading: false }),
        ]);
    }
}

function* commonDeleteAPISaga(obj = {}) {
    yield put({ type: "DO_LOADING", loading: true });

    try {
        // Call the API service to perform the delete operation
        const response = yield call(commonDeleteAPIService, obj?.data);

        // Check if the response status is 200 (Success)
        if (response?.data?.status === 200) {
            // Display a success toast message with the response message if available
            succToast(response?.data?.message || '');
            yield put({ type: "RESET_SUBSCRIPTION_AND_DELETE_REQUEST_LIST", doResetList: true });
        } else {
            // Display a failure toast message with the response message if available
            failToast(response?.data?.message || '');
        }
    } catch (error) {
        // If an error occurs during the API call, show a server not responding message
        serverNotRespond();
    }
    yield put({ type: "DO_LOADING", loading: false });
}

export default function* forgetPassSaga() {
    yield takeLatest(forgetPassTypes.DO_FORGET_ACTION, doForget);
    yield takeLatest(forgetPassTypes.COMMON_DELETE_API, commonDeleteAPISaga);
}
