import { Get } from "../common/httpRestServices";
import { apiUrl, dashBoard } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";

/**
 * get quick view details.
 * second Params,Header Need or Not
 */
export const quickViewService = (obj = {}) => {
    const { data: { organisationId = "" } = {} } = obj;
    return Get(`${apiUrl}${dashBoard.quickView}organisationId=${organisationId}`, true);
};

/**
 * analytic service
 * second Params,Header Need or Not
 */
export const analyticViewService = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : null;
    return Get(`${apiUrl}${dashBoard.analyticView}${queryString}`, true);
};

/**
 * cloudUasge get list
*/
export const cloudUsageService = (obj = {}) => {
    return analyticViewService(obj);
};

/**
 * Get Recent Active Customers List API
*/
export const recentActiveCustomersService = (data = {}) => {
    const queryString = data ? encodeGetParams(data) : null;
    return Get(`${apiUrl}${dashBoard.recentActivityCustomers}${queryString}`, true);
};

/**
 * Get Recent Active Customers List API
*/
export const recentActiveCustomersListService = (obj = {}) => {
    const { data = {} } = obj || {};
    const queryString = data ? encodeGetParams(data) : null;
    return Get(`${apiUrl}${dashBoard.recentActivityCustomers}${queryString}`, true);
};
