const cusPlan = "custom plan";
const proPlan = "Pro plan";
const createdOn = "July 12, 2019";
const trailPlan = "";
const Dataformat = [
    {
        plantype: cusPlan,
        mobilenum: "989898988447",
        organisation: "Fingertip Freedom",
        name: "naveen",
        email: "naveen@gmail.com",
        status: "Pending",
        createdon: 155,
        selectAll: false,
        userStatus: "active",
        app: "Android"
    },
    {
        plantype: trailPlan, mobilenum: "747676364555",
        organisation: "Trained For Tech", name: "ram",
        email: "ram@yahoo.com", createdOn: createdOn, status: "Approved", visits: 1785, selectAll: false, app: "iOS"
    },
    {
        plantype: proPlan, mobilenum: "764829773875", organisation: "App Natural",
        name: "kumar", email: "kumar@gmail.com", createdOn: createdOn, status: "Approved", visits: 175, selectAll: false, app: "Web"
    },
    {
        plantype: cusPlan, mobilenum: "83826824828", organisation: "Pocket Pro",
        name: "raj", email: "raj@gmail.com", createdOn: createdOn, status: "Cancelled", visits: 165, selectAll: false, app: "Java Script"
    },
    {
        plantype: proPlan, mobilenum: "764726726669", organisation:
            "Download Digital", name: "pandi123", email: "pandi@gmail.com", createdOn: createdOn, status: "Pending", visits: 15, selectAll: false, app: "Unity"
    },
    {
        plantype: trailPlan, mobilenum: "747676364555", organisation:
            "Trained For Tech", name: "ram12", email: "ram12@yahoo.com", createdOn: createdOn, status: "Approved", visits: 178, selectAll: false, app: ".Net"
    },
    {
        plantype: proPlan, mobilenum: "764829773875", organisation:
            "App Natural", name: "kumar123", email: "kumar12@gmail.com", createdOn: "July 12, 2015", status: "Approved", visits: 17, selectAll: false, app: "Platform Api"
    },
    {
        plantype: cusPlan, mobilenum: "83826824828", organisation:
            "Pocket Pro", name: "raj12", email: "raj1232@gmail.com", createdOn: "July 12, 2014", status: "Cancelled", visits: 1555, selectAll: false, app: "XYZ"
    },
];
export default Dataformat;
