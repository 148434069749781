import _get from "lodash/get";
import { apiUrl, SDK } from "../helper/ApiUrl";
import { encodeGetParams } from "../helper/Encypt";
import { Get, Delete, Put, Post } from "../common/httpRestServices";

/**
 * @param  {} obj={}
 * get SDK list data,
 */
export const fetchSDKList = (obj = {}) => {
    const { data = {} } = obj;
    const queryString = data ? encodeGetParams(data) : "";
    return Get(`${apiUrl}${SDK.getSDKlist}${queryString}`, true);
};

/**
 * @param  {} obj={}
 * deleteData,
 */
export const deleteSDKList = (obj = {}) => {
    const { data = {} } = obj;
    return Delete(`${apiUrl}${SDK.deleteSDK}`, { platformIds: _get(data, "sdkIds", "") }, true);
};

/**
 * @param  {} obj={}
 * edit sdk service,
 */
export const editSDkservice = (obj = {}) => {
    const { data = {} } = obj;
    const newObj = {
        platformName: data.platformName,
        status: data.status === 0 ? 1 : 0,
    };
    const queryString = data ? encodeGetParams(newObj) : "";
    return Put(`${apiUrl}${SDK.editSDKlist}${queryString}`, true);
};

/**
 * @param  {} obj={}
 * create sdk service,
 */
export const createSDkservice = (obj = {}) => {
    const { data: { newObj = {}, formData = {} } = {} } = obj;
    const queryString = newObj ? encodeGetParams(newObj) : "";
    return Post(`${apiUrl}${SDK.createSDK}${queryString}`, formData, true);
};
