import { serverNotRespond } from "../../helper/ApiToast";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as activityLogTypes from '../actionTypes/activityLogTypes';
import { getActivitylogService,getActivitylogFilterService} from "../../services/activityLogServices";

export function* getActivitylogSaga(obj = {}) { //SDK get list
    try {
        const response = yield call(getActivitylogService, obj);
        const { data = {} } = response || {};
        const { status = 0, message = "" } = data;
        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_ACTIVITY_LOG_LOADER", activityLogLoader: false }),//inidial loader
                put({ type: "USER_ACTIVITY_LOG_LIST", activityLogList: data }),
            ]);
        } else {
            serverNotRespond(message);
            yield all([
                put({ type: "USER_ACTIVITY_LOG_LIST", activityLogList: data }),
                put({ type: "USER_ACTIVITY_LOG_LOADER", activityLogLoader: false }),//inidial loader
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "USER_ACTIVITY_LOG_LIST", activityLogList: {} }),
            put({ type: "USER_ACTIVITY_LOG_LOADER", activityLogLoader: false }),//inidial loader
        ]);
    }
}
function* getActivityLogFilterSaga() {
    try {
        const response = yield call(getActivitylogFilterService, {});
        const { data = {} } = response || {};
        const { status = 0} = data;

        if (status >= 200 && status <= 300) {
            yield all([
                put({ type: "USER_ACTIVITY_LOG_FILTER", activityLogFilter: data }),//Result Store
                put({ type: "USER_ACTIVITY_LOG_LOADER", activityLogLoader: false }),//Loader open/close
            ]);
        } else {
            yield all([
                put({ type: "USER_ACTIVITY_LOG_LOADER", activityLogLoader: false }),//Loader open/close
            ]);
        }
    }
    catch (error) {
        yield all([
            put({ type: "USER_ACTIVITY_LOG_LOADER", activityLogLoader: false }),//Loader open/close
        ]);
    }
}
export default function* activityLogSaga() {
    yield takeLatest(activityLogTypes.GET_ADMIN_ACTIVITY_LIST, getActivitylogSaga);
    yield takeLatest(activityLogTypes.GET_ADMIN_ACTIVITY_FILTER, getActivityLogFilterSaga);
}
