export const initialStripeState = {
    stripeInPutForm: null,
    stripeLoad: false,
};

export const initialStripeReducer = (state = initialStripeState, action = {}) => {
    switch (action.type) {
        case 'DO_LOADING_PAYMENT_DEFAULT': {
            return {
                ...state,
                ...initialStripeState,
            };
        }
        case 'DO_LOADING_PAYMENT_INITIAL_LOADER': {
            return {
                ...state,
                stripeLoad: action.stripeLoad,
            };
        }
        case 'DO_SAVE_ENTER_FORM_DATA': {
            return {
                ...state,
                stripeInPutForm: action.stripeInPutForm,
            };
        }
        default:
            return {
                ...state
            };
    }
};
