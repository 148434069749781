import { encodeGetParams } from "../helper/Encypt";
import { apiUrl, appVersion, dashBoard } from "../helper/ApiUrl";
import { Get, mediaGet, Post } from "../common/httpRestServices";

/**
 * Get Paln Type
 * second Params,Header Need or Not
 * releaseHistory: default value is false
 * when user click release history button in customer portal value will change True
 */
export const appVersionList = (obj = {}) => {
    const { data: { page = 0, size = 0, searchTerm = "", releaseHtry = false } = {} } = obj || {};
    const releaseHistory = releaseHtry;
    const paramsData = {
        page: page,
        size: size,
        searchTerm: searchTerm,
    };
    const queryString = encodeGetParams(paramsData);
    return Get(`${apiUrl}${!releaseHistory ? appVersion.getAppVerionList : appVersion.getAppversionReleseHistory}${queryString}`, true);
};

/**
 * @param  {object} obj-Get Data from Add Customer Page
 * obj.data Filed Add New CUstomer Details
 * Third Params Mean Header Need or Not
 */
export const addnewAppversionsSDKandDOC = (obj = {}) => {
    const { data: { inputData = {} } } = obj || {};
    return Post(`${apiUrl}${appVersion.addNewAppVersionMedia}`, inputData, true);
};

/**
 * @param  {object} obj-Get Data from Add Customer Page
 * obj.data Filed Add New CUstomer Details
 * Third Params Mean Header Need or Not
 */
export const addnewAppversions = (obj = {}) => {
    const { data = {} } = obj || {};
    return Post(`${apiUrl}${appVersion.addNewVersion}`, data, true);
};

/**
 * @param  {object} obj,
 * inside params pass file url
 */
export const downloadSDKandDOCservice = (obj = {}) => {
    const { data: { fileToken = "" } = {} } = obj || {};
    return mediaGet(`${apiUrl}${appVersion.mediaDownloadUrl}${fileToken}`, true);
};

/**
 * @param  {object} obj,
 * inside params pass file url
 */
export const getLatestVersionSDKservice = () => {
    return Get(`${apiUrl}${appVersion.getLatestVersion}`, true);
};

/**
 * pass orgid get call duration
 */
export const getCallDurationBasedOrgIdService = (data = {}) => {
    const { orgId: callUasgeDetails = {} } = data;
    const queryString = encodeGetParams(callUasgeDetails);
    return Get(`${apiUrl}${dashBoard.getCallDuration}?${queryString}`, true);
};
