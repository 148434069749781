export const initialState = {
    //group
    loaderGroupMod: false,
    groupModertionList: {},
    //user
    loaderUserMod: false,
    userModertionList: {},
    //report
    loaderreportMod: false,
    reportModertionList: {},
    //value appent
    valueAddInList: false,
    //user Credential
    loaderUserCredentialMod: false,
    userCredentialModertionDetails: {},
    //user login
    loaderUserLoginMod: false,
    userLoginModertionDetails: {},
    //user creation
    loaderUserCreationMod: false,
    userCreationModertionDetails: {},
    //send message
    loaderSendMessageMod: false,
    sendMessageModertionDetails: {},
    chatHistoryModertionDetails: {},
    //delete account
    loaderDeleteAccountMod: false,
    deleteAccountModertionDetails: {},
    userCredentialProgress: 'pending',
    userLoginProgress: 'pending',
    userCreationProgress: 'pending',
    sendMessageProgress: 'pending',
    chatHistoryProgress: 'pending',
    deleteAccountProgress: 'pending',
};

export const moderationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'DO_MODERATION_INITIAL': {
            return {
                ...state,
                ...initialState,
            };
        }
        //appent value
        case 'VALUE_APPENT_IN_ARRAY': {
            return {
                ...state,
                valueAddInList: action.valueAddInList
            };
        }

        //group
        case 'GROUP_MODERATION_LOADER': {
            return {
                ...state,
                loaderGroupMod: action.loaderGroupMod
            };
        }
        //group
        case 'GROUP_MODERATION_LIST': {
            return {
                ...state,
                groupModertionList: action.groupModertionList
            };
        }

        //user
        case 'USER_MODERATION_LOADER': {
            return {
                ...state,
                loaderUserMod: action.loaderUserMod
            };
        }
        //user
        case 'USER_MODERATION_LIST': {
            return {
                ...state,
                userModertionList: action.userModertionList
            };
        }

        //report
        case 'REPORT_MODERATION_LOADER': {
            return {
                ...state,
                loaderreportMod: action.loaderreportMod
            };
        }
        //report
        case 'REPORT_MODERATION_LIST': {
            return {
                ...state,
                reportModertionList: action.reportModertionList
            };
        }

        //user Credential
        case 'USER_CREDENTIAL_MODERATION_LOADER': {
            return {
                ...state,
                loaderUserCredentialMod: action.loaderUserCredentialMod
            };
        }
        //user Credential
        case 'USER_CREDENTIAL_MODERATION_DETAILS': {
            return {
                ...state,
                userCredentialModertionDetails: action.userCredentialModertionDetails
            };
        }

        //user Login
        case 'USER_LOGIN_MODERATION_LOADER': {
            return {
                ...state,
                loaderUserLoginMod: action.loaderUserLoginMod
            };
        }
        //user Login
        case 'USER_LOGIN_MODERATION_DETAILS': {
            return {
                ...state,
                userLoginModertionDetails: action.userLoginModertionDetails
            };
        }

        //user creation
        case 'USER_CREATION_MODERATION_LOADER': {
            return {
                ...state,
                loaderUserCreationMod: action.loaderUserCreationMod
            };
        }
        //user creation
        case 'USER_CREATION_MODERATION_DETAILS': {
            return {
                ...state,
                userCreationModertionDetails: action.userCreationModertionDetails
            };
        }

        //send message
        case 'SEND_MESSAGE_MODERATION_LOADER': {
            return {
                ...state,
                loaderSendMessageMod: action.loaderSendMessageMod
            };
        }
        //send message
        case 'SEND_MESSAGE_MODERATION_DETAILS': {
            return {
                ...state,
                sendMessageModertionDetails: action.sendMessageModertionDetails
            };
        }
        //chat history
        case 'CHAT_HISTORY_MODERATION_DETAILS': {
            return {
                ...state,
                chatHistoryModertionDetails: action.chatHistoryModertionDetails
            };
        }
        //delete account
        case 'DELETE_ACCOUNT_MODERATION_LOADER': {
            return {
                ...state,
                loaderDeleteAccountMod: action.loaderDeleteAccountMod
            };
        }
        //delete account
        case 'DELETE_ACCOUNT_MODERATION_DETAILS': {
            return {
                ...state,
                deleteAccountModertionDetails: action.deleteAccountModertionDetails
            };
        }
        case 'ADMIN_LOGIN_MODERATION_DETAILS': {
            return {
                ...state,
                adminLoginModeration: action.adminLoginModeration
            };
        }
        case 'CREATE_USER_MODERATION_DETAILS': {
            return {
                ...state,
                createUserModeration: action.createUserModeration
            };
        }
        case 'SEND_MESSAGE_MODERATION_DETAILS': {
            return {
                ...state,
                sendMessageModeration: action.sendMessageModeration
            };
        }
        case 'DELETE_USER_MODERATION_DETAILS': {
            return {
                ...state,
                deleteuserModeration: action.deleteuserModeration
            };
        }
        case 'SET_API_PROGRESS_CREDENTIAL': {
            return {
                ...state,
                userCredentialProgress : action.userCredentialProgress
            };
        }
        case 'SET_API_PROGRESS_LOGIN': {
            return {
                ...state,
                userLoginProgress : action.userLoginProgress
            };
        }
        case 'SET_API_PROGRESS_CREATEUSER': {
            return {
                ...state,
                userCreationProgress : action.userCreationProgress
            };
        }
        case 'SET_API_PROGRESS_SENDMESSAGE': {
            return {
                ...state,
                sendMessageProgress : action.sendMessageProgress
            };
        }
        case 'SET_API_PROGRESS_CHATHISTORY': {
            return {
                ...state,
                chatHistoryProgress : action.chatHistoryProgress
            };
        }
        case 'SET_API_PROGRESS_DELETEACCOUNT': {
            return {
                ...state,
                deleteAccountProgress : action.deleteAccountProgress
            };
        }
        default:
            return {
                ...state
            };
    }
};
