import store from "../../../store";
import { dataFetch } from "../../../store/action/customeAction";
import { GET_RECENT_CUSTOMERS_LIST } from "../../../store/actionTypes/dashBoardTypes";

/**
 * addCus sidePopUp Handle
 */
export const addcuspopUpHandle = (value = false) => {
    store.dispatch({ type: "CUSTOMER_PAGE_OPEN_CLOSE", cusPageOpen: value });
};

/**
 * addMember sidePopUp Handle
 */
export const addMemberpopUpHandle = (value = false) => {
    store.dispatch({ type: "MEMBER_CUSTOMER_DETAILS", userDtls: {} });//empty user details otherwise conflict
    store.dispatch({ type: "MEMBER_POPUP_OPEN", memberPopupOpen: value });//open popUp
};

/**
 * customerPlan type apiHit
 */
export const cusPlanTypeHitApi = (value = false) => {
    store.dispatch(dataFetch({}));
    store.dispatch({ type: "CUS_PAGE_PLAN_TYPE_API", PlanTypeApiHit: true });
};

/**
 * @param  {} percentageData=0
 * percentage is morethen or lowerThen 100 return +100 or -100
 */
export const percendateRender = (percentageData = 0) => {
    if (Math.floor(percentageData) > 100 || Math.floor(percentageData) < -100) {
        return 100;
    }
    return Math.floor(percentageData);
};

/**
 * Recent Customers list and graph data API
*/
export const getRecentActiveCustomersList = (data = {}) => {
    // API trgger to get data
    store.dispatch({ type: GET_RECENT_CUSTOMERS_LIST, data});
    store.dispatch({ type: "ANALYTIC_LOAD", analyticLoad: true });
};

export const isPaidPlan = (planTypeId = 0) => {
    const globalStore = store.getState();
    const planTypes = globalStore?.CusPage?.planType || [];
    const planDtls = planTypes.find(p => parseInt(p.id) === planTypeId);
    return planDtls?.isPayment ? true : false;
};
