import moment from 'moment';
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import store from "../../../../store";
import {
    getCusList,
    getCustDtls,
} from "../../../../store/action/customeAction";
import {
    DateTimeFormeterEnd,
    DateTimeFormeterStart,
    leadingAndTrailingspaceAvoid,
} from "../../../../helper";
import {
    GET_CANCEL_SUBSCRIPTION_REQUEST_COUNT,
    GET_CANCEL_SUBSCRIPTION_REQUEST_LIST,
    GET_CANCELLED_SUBSCRIPTION_LIST,
    GET_CUSTOMER_DELETED_LIST,
    GET_CUSTOMER_DELETE_REQUEST_LIST,
    GET_CUSTOMER_DELETE_REQUEST_COUNT,
    GET_DELETE_REQUEST_STATUS
} from '../../../../store/actionTypes/customerTypes';
import { CANCEL_SUBSCRIPTION_REQUEST_STATUS } from '../../../../store/actionTypes/profileUpdateTypes';
import { ownCustomers,teamCustomers } from '../../../../common/MonckDataJson';

/**
 * @param  {string} value=""
 * phone number filed check and convert string to number
 */
export const numberFiled = (value = "") => {
    if (value) {
        return leadingAndTrailingspaceAvoid(value);
    } return "";
};

/**
 * @param  {string} value=""
 * planType
 */
export const planTypeFiled = (value = "") => {
    return value ? value.id : value;
};

export const licensekeyManage = (value = "") => {
    const valueMap = {
        'All': '',
        'Expired': false,
        'Active': true
    };

    return valueMap.hasOwnProperty(value) ? valueMap[value] : value;
};

export const getStatusFilterValue = (status = '') => {
    return status === "Active";
};

export const getStatusFilteredValue = (status = '') => {
    return !status || status === 'All' ? false : status === 'Success';
};
/**
 * @param  {object} value=""
 * planType
 */
export const userSearchAction = (searchInputData = {}, planTypeId = '') => {
    const selectedOption = ownCustomers.find((c) => c.planName === searchInputData.filterByContus);
    const selectedTeamOption = teamCustomers.find((c) => c.planName === searchInputData.sortTeams);

    const earchInputData = {
        ...searchInputData,
        userType: "customer",
        size: searchInputData.size,
        page: +searchInputData.page,
        sortByTotalUsers: searchInputData.sortByTotalUsers || '',
        sortByCreatedAt: searchInputData.sortByCreatedAt || '',
        sortType: searchInputData.sortType ?? '',
        sortTypeAt: searchInputData.sortTypeAt ?? '',
        name: leadingAndTrailingspaceAvoid(searchInputData.name),
        country: leadingAndTrailingspaceAvoid(searchInputData.country),
        filterBy: leadingAndTrailingspaceAvoid(searchInputData.filterBy) === 'gracePeriod' ? '' : leadingAndTrailingspaceAvoid(searchInputData.filterBy),
        filterByDateType: leadingAndTrailingspaceAvoid(searchInputData.filterByDateType),
        filterByDateStart: DateTimeFormeterStart(searchInputData?.filterByDateStart),
        filterByDateEnd: DateTimeFormeterEnd(searchInputData?.filterByDateEnd),
        searchTerm: leadingAndTrailingspaceAvoid(searchInputData.searchTerm),
        licensekeyExpire: licensekeyManage(searchInputData.licensekeyExpire),
        createdatStart: DateTimeFormeterStart(searchInputData.createdatStart),
        createdatEnd: DateTimeFormeterEnd(searchInputData.createdatEnd),
        planType: +planTypeFiled(planTypeId) ? +planTypeFiled(planTypeId) : searchInputData?.planType || "",
        email: leadingAndTrailingspaceAvoid((searchInputData.email || "").toLowerCase()),
        organisationName: leadingAndTrailingspaceAvoid(searchInputData.organisationName),
        status: (searchInputData.status === '' || searchInputData.status === 'All') ? '' : getStatusFilterValue(searchInputData.status),
        mobileNumber: +numberFiled(searchInputData.mobileNumber) ? +numberFiled(searchInputData.mobileNumber) : "",
        filterByContus : selectedOption?.value,
        sortTeams: selectedTeamOption?.value,
        licenseStatus: leadingAndTrailingspaceAvoid(searchInputData.filterBy) === 'gracePeriod' ? 'gracePeriod' : ''
    };

    const newSearchData = {
        ...earchInputData,
        filterByContus : searchInputData.filterByContus,
        sortTeams: searchInputData.sortTeams,
        filterBy : searchInputData.filterBy
    };

    store.dispatch({ type: "DO_LOADING_CUSTOMER_PAGE", cusPageloading: true });//when reRender loder On
    store.dispatch({ type: "INITAIL_RENDER_DATA", dataRenderCusPage: false }); // Reset customer list when delete any user
    store.dispatch({ type: "SEARCH_DATA", searchData: newSearchData });
    store.dispatch({ type: "DO_LOADING", loading: true });
    store.dispatch(getCusList(earchInputData));
};
/**
 customer Page popUp open and close
 Mange From Store
 */
export const cuspopupOpenClose = () => {
    store.dispatch({ type: "CUSTOMER_PAGE_OPEN_CLOSE_EDIT", cusPageEditOpen: false });
    store.dispatch({ type: "CUSTOMER_DETAILS", customerDtls: {} });
};

/**
 customer Page popUp open and close
 Mange From Store
 */
export const cusEditAction = (editRow = {}) => {
    store.dispatch({ type: "CUSTOMER_PAGE_OPEN_CLOSE_EDIT", cusPageEditOpen: true });//side popUp open
    store.dispatch({ type: "DO_LOADING_EDIT", loading: true }); //for  Loder
    store.dispatch({ type: "DO_LOADING", loading: true });// for after update cusDetails Rerender
    store.dispatch(getCustDtls({ customerId: _get(editRow, "id", "") })); //userId
};

/**
 * @param  {object} InitialParams={}
 * customerpage all search input field empty and call initial params
 */
export const resetAllCusParentData = (InitialParams = {}) => {
    const selectedOption = ownCustomers.find((c) => c.planName === InitialParams.filterByContus);
    const selectedTeamOption = teamCustomers.find((c) => c.planName === InitialParams.sortTeams);
    const newSearchData = {
        ...InitialParams,
        filterByContus : selectedOption?.value,
        sortTeams:selectedTeamOption?.value,
        filterBy: leadingAndTrailingspaceAvoid(InitialParams.filterBy) === 'gracePeriod' ? '' : leadingAndTrailingspaceAvoid(InitialParams.filterBy),
        licenseStatus: leadingAndTrailingspaceAvoid(InitialParams.filterBy) === 'gracePeriod' ? 'gracePeriod' : ''
    };
    store.dispatch(getCusList(newSearchData)); //initail param value
    store.dispatch({ type: "SEARCH_DATA", searchData: InitialParams });
    store.dispatch({ type: "DO_LOADING_CUSTOMER_PAGE", cusPageloading: true });//when reRender loder On
    store.dispatch({ type: "DO_LOADING", loading: true });//when reRender loder On
    store.dispatch({ type: "CUS_PAGE_API_LOAD", apiHit: false });
};

/**
 * initialParams for customer input filed
 */
export const InitialParamsValue = {
    page: 1,
    size: 10,
    name: "",
    email: "",
    status: "",
    country: "",
    planType: "",
    userType: "customer",
    filterBy: "",
    mobileSort: "",
    searchTerm: "",
    mobileNumber: "",
    createdatEnd: '',
    createdatStart: '',
    sortByCreatedAt: "desc",
    filterByDateEnd: "",
    sortByTotalUsers: "",
    organisationName: "",
    sortType: "",
    sortTypeAt: "",
    licensekeyExpire: "",
    filterByDateStart: "",
    filterByContus : 'Without Contus',
    sortTeams: 'Without Teams',
    filterByDateType: "",
    licenseStatus: ""
};

/**
 *reset Button enable and disable
 */
export const cusPageRestButtonShowHide = (searchInputData = {}) => {
    return _isEqual(searchInputData, InitialParamsValue);
};

/**
 * @param  {string} loadType=""
 * @param  {null} searchData=null
 * @param  {object} InitialParams={}
 * page initial render and reRender manage
 */
export const cusSearchRerenderPage = (loadType = "", searchData = null, InitialParams = {}) => {
    if (searchData !== null && loadType === "reRender") {
        const selectedOption = ownCustomers.find((c) => c.planName === InitialParams.filterByContus);
        const selectedTeamOption = teamCustomers.find((c) => c.planName === InitialParams.sortTeams);
        store.dispatch({ type: "SEARCH_DATA", searchData });
        store.dispatch(getCusList({
            ...InitialParams,
            filterByContus   : selectedOption?.value || "withoutContus",
            sortTeams   : selectedTeamOption?.value || "withoutTeams",
            filterByDateStart: DateTimeFormeterStart(InitialParams.filterByDateStart),
            filterByDateEnd  : DateTimeFormeterEnd(InitialParams.filterByDateEnd),
        }));
    } else {
        const selectedOption = ownCustomers.find((c) => c.planName === InitialParams.filterByContus);
        const selectedTeamOption = teamCustomers.find((c) => c.planName === InitialParams.sortTeams);
        const newParams = {
            ...InitialParams,
            createdatStart   : DateTimeFormeterStart(InitialParams.createdatStart),
            filterByDateStart: DateTimeFormeterStart(InitialParams.filterByDateStart),
            filterByDateEnd  : DateTimeFormeterEnd(InitialParams.filterByDateEnd),
            createdatEnd     : DateTimeFormeterEnd(InitialParams.createdatEnd),
            filterByContus   : selectedOption?.value || "withoutContus",
            sortTeams        : selectedTeamOption?.value || "withoutTeams",
            filterBy         : leadingAndTrailingspaceAvoid(InitialParams.filterBy) === 'gracePeriod' ? '' : leadingAndTrailingspaceAvoid(InitialParams.filterBy),
            licenseStatus    : leadingAndTrailingspaceAvoid(InitialParams.filterBy) === 'gracePeriod' ? 'gracePeriod' : ''
        };

        const newSearchData  = {
            ...newParams,
            filterByContus   : InitialParams.filterByContus,
            sortTeams        : InitialParams.sortTeams,
            filterByDateStart: newParams.filterByDateStart ? moment(newParams.filterByDateStart) : '',
            filterByDateEnd  : newParams.filterByDateEnd ? moment(newParams.filterByDateEnd) : '',
            createdatStart   : newParams.createdatStart ? moment(newParams.createdatStart) : '',
            createdatEnd     : newParams.createdatEnd ? moment(newParams.createdatEnd) : '',
        };
        store.dispatch(getCusList(newParams)); //initail param value
        store.dispatch({ type: "SEARCH_DATA", searchData: newSearchData });
    }
    store.dispatch({ type: "INITAIL_RENDER_DATA", dataRenderCusPage: false });
    store.dispatch({ type: "DO_LOADING_CUSTOMER_PAGE", cusPageloading: true });//when reRender loder On
    store.dispatch({ type: "DO_LOADING", loading: true });//when reRender loder On
    store.dispatch({ type: "CUS_PAGE_API_LOAD", apiHit: false });
};

/**
 * @param  {string} loadType=""
 * @param  {null} searchData=null
 * @param  {object} InitialParams={}
 * Customer list filter function
 */
export const customerListFilter = (searchData = {}) => {
    store.dispatch({ type: "SEARCH_DATA", searchData });
    store.dispatch(getCusList(searchData));
    store.dispatch({ type: "DO_LOADING", loading: true });
};

/**
 * @param  {Array} planTypes=[]
 * @param  {Number} planType=0
 * @returns selected plan name,
 */
export const planNameFind = (planTypes = [], planType = 0) => {
    const dataFind = planTypes.find((ele) => ele.id === planType);
    return dataFind ? _get(dataFind, "planName", "") : "";
};

/**
 * @param  {string} date=""
 * @returns moment format
 */
export const selectedDateCusPage = (date = "") => {
    return date ? moment(date) : null;
};

/**
 * @param  {boolean} value=true
 */
export const statusReturnCusPage = (value = true) => {
    if (value === true) {
        return "Active";
    } else if (value === false) {
        return "Inactive";
    } else {
        return "All";
    }
};

export const licenseReturnCusPage = (value = true) => {
    // eslint-disable-next-line eqeqeq
    if (!value == "") {
        return "All";
    }
    return value === true ? "Active" : "Expired";
};
/**
 * @param  {boolean} value=true
 */
export const searchedResultShow = (searchDatas = null, InitialParams = {}) => {
    return searchDatas ? searchDatas : InitialParams;
};

/**
 * @param  {boolean} value=true
 */
export const userDataEmptyCheck = (userData = []) => {
    return userData.length <= 1 ? false : true;
};

/**
 * @param  {boolean} value=true
 */
export const cusInitialParamsReturn = (searchDatas = null, InitialParams = {}) => {
    if (searchDatas && Object.keys(searchDatas).length) {
        return searchDatas;
    }
    return InitialParams;
};

/**
 * @param  {boolean} value=false
 * if search calender open class name added
 */
export const CalendarOpenClass = (value = false) => {
    return value ? "openCalendar" : "";
};

/**
 * @param  {boolean} value=false
 * if search calender open class name added
 */
export const searchValueAppend = (searchDatas = {}, planTypes = []) => {
    return {
        ...searchDatas,
        "name": searchDatas.name,
        "page": searchDatas.page,
        "size": searchDatas.size,
        "email": searchDatas.email,
        "country": searchDatas.country,
        "mobileNumber": searchDatas.mobileNumber,
        "sortByCreatedAt": searchDatas.sortByCreatedAt,
        "sortByTotalUsers": searchDatas.sortByTotalUsers,
        "organisationName": searchDatas.organisationName,
        "status": searchDatas.status,
        "planType": planNameFind(planTypes, searchDatas.planType),
        "createdatEnd": selectedDateCusPage(searchDatas.createdatEnd),
        "createdatStart": selectedDateCusPage(searchDatas.createdatStart),
        "licensekeyExpire": licenseReturnCusPage(searchDatas.licensekeyExpire),
    };
};

/**
 * @param  {array} array=[]
 * for delete purpose seletect item id find and remove undefined
 */
export const cusTableSelectedDeleteItem = (array = []) => {
    return (array || []).map(ele => {
        if (ele.isCheck) {
            return _get(ele, "userId", "");
        } return undefined;
    }).filter(notUndefined => notUndefined !== undefined);
};

/**
 * delete customer loader
 */
export const deleteCusLoader = () => {
    store.dispatch({ type: "INITAIL_RENDER_DATA", dataRenderCusPage: false });
    store.dispatch({ type: "DO_LOADING", loading: true }); //loader On
};

/**
 * Get cancelled subscription request customers list
 */
export const getBadgeCounts = () => {
    store.dispatch({ type: GET_CUSTOMER_DELETE_REQUEST_COUNT });
    store.dispatch({ type: GET_CANCEL_SUBSCRIPTION_REQUEST_COUNT });
};

/**
 * get request customer list
 */
export const getCancelRequestList = (data = {}) => {
    store.dispatch({ type: "DO_LOADING", loading: true });
    store.dispatch({ type: "RESET_SUBSCRIPTION_AND_DELETE_REQUEST_LIST", doResetList : false });
    const newData = { ...data, searchTerm: leadingAndTrailingspaceAvoid(data.searchTerm) };
    store.dispatch({ type: GET_CANCEL_SUBSCRIPTION_REQUEST_LIST, data: newData });
    getBadgeCounts();
};

/**
 * Get subscription cancelled list
*/
export const getCancelledList = (data = {}) => {
    store.dispatch({ type: "DO_LOADING", loading: true });
    const newData = { ...data, searchTerm: leadingAndTrailingspaceAvoid(data.searchTerm) };
    store.dispatch({ type: GET_CANCELLED_SUBSCRIPTION_LIST, data: newData });
    getBadgeCounts();
};

/**
 * Get cancelled subscription request customers list
*/
export const getDeleteRequestList = (data = {}) => {
    store.dispatch({ type: "DO_LOADING", loading: true });
    store.dispatch({ type: "RESET_SUBSCRIPTION_AND_DELETE_REQUEST_LIST", doResetList : false });
    const newData = { ...data, searchTerm: leadingAndTrailingspaceAvoid(data.searchTerm) };
    store.dispatch({ type: GET_CUSTOMER_DELETE_REQUEST_LIST, data: newData });
    getBadgeCounts();
};

/**
 * Get cancelled subscription request customers list
*/
export const getDeletedList = (data = {}) => {
    store.dispatch({ type: "DO_LOADING", loading: true });
    const newData = { ...data, searchTerm: leadingAndTrailingspaceAvoid(data.searchTerm) };
    store.dispatch({ type: GET_CUSTOMER_DELETED_LIST, data: newData });
    getBadgeCounts();
};

/**
 * get request customer list
 */
export const getCancelRequestStatus = (userId = "") => {
    store.dispatch({ type: GET_DELETE_REQUEST_STATUS, data: {userId}});
    store.dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST_STATUS, data: { userId } });
    store.dispatch({ type: "DO_LOADING", loading: false });
};
