import store from "../../store";
import _isEqual from "lodash/isEqual";
import * as addSDKTypes from '../actionTypes/addSDKTypes';
import { put, takeLatest, call, all } from 'redux-saga/effects';
import { failToast, serverNotRespond, succToast } from "../../helper/ApiToast";
import { openAddSDKPOPup } from "../../components/MainContent/Settings/settingPageCommon";
import { deleteSDKList, editSDkservice, fetchSDKList, createSDkservice } from '../../services/sdkService';
import { getSDKlist, initialStateSDK } from "../../components/MainContent/Settings/SDKServices/SDKtable/SDKPageCommon";

export function* doGetSDKList(obj = {}) { //SDK get list
    try {
        const getPlatforms = yield call(fetchSDKList, obj);
        const { data : { status = 0, message = "" } } = getPlatforms|| {};

        if (status === 200) {
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
                put({ type: "STORE_SDK_DATA_LIST", sdkDataList: getPlatforms?.data }),
            ]);
        } else {
            serverNotRespond(message);
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
        ]);//lodering on / off
    }
}

export function* doDeleteSDKList(obj = {}) { //deleteSDK
    try {
        const deleteSDK = yield call(deleteSDKList, obj);
        const { data = {} } = deleteSDK || {};
        const { status = 0, message = "" } = data;
        const state = store.getState();//get search
        const { addSDK: { searchData = {} } = {} } = state || {};//store
        const initStateSDK = initialStateSDK();//initial state
        const checkEqual = _isEqual(searchData, initStateSDK);//initial and search state check
        if (status === 200) {
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
            getSDKlist(checkEqual ? initStateSDK : searchData);//check
            succToast(message);//delete toastMake
        } else if (status >= 201 && status <= 400) {
            failToast(message);
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
        }
        else {
            serverNotRespond(message);
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
        }
    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
        ]);//lodering on / off
    }
}

export function* doEditSDKList(obj = {}) { //editSDK
    try {
        const editSDK = yield call(editSDkservice, obj);
        const { data = {} } = editSDK || {};
        const { status = 0, message = "" } = data;
        const state = store.getState();//get search
        const { addSDK: { searchData = {} } = {} } = state || {};//store
        const initStateSDKValue = initialStateSDK();//initial state
        const checkEqualValue = _isEqual(searchData, initStateSDKValue);//initial and search state check
        if (status === 200) {
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
            getSDKlist(checkEqualValue ? initStateSDKValue : searchData);//check
            succToast(message);//content msg
        } else {
            failToast(message);
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
        }

    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "SDK_LOADER", loaderSDK: false }),//initial loader
        ]);//lodering on / off
    }
}
const addSDKFailToast = () => {
    store.dispatch({ type: "SDK_LOADER", loaderSDK: false });//loader will close and open either its failed or pass
};

export function* doCreateNewSDK(obj = {}) { //createSDK
    try {
        const createSDK = yield call(createSDkservice, obj);
        const { data = {} } = createSDK || {};
        const { status = 0, message = "" } = data;
        const state = store.getState();//get search
        const { addSDK: { searchData = {} } = {} } = state || {};//store
        const initStateSDKValue = initialStateSDK();//initial state
        const checkEqualValue = _isEqual(searchData, initStateSDKValue);//initial and search state check
        if (status === 200) {
            succToast(message);//content msg
            yield all([
                put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
            ]);
            openAddSDKPOPup(false);//sdk popUp close
            getSDKlist(checkEqualValue ? initStateSDKValue : searchData);//check
        } else if (status >= 201 && status <= 400) {
            failToast(message);
            addSDKFailToast();
        } else {
            serverNotRespond(message);//server is not responding callthis toast
            addSDKFailToast();
        }

    }
    catch (error) {
        serverNotRespond();
        yield all([
            put({ type: "SDK_LOADER", loaderSDK: false }),//inidial loader
        ]);//lodering on / off
    }
}

export default function* rootSDKPostSaga() {
    yield takeLatest(addSDKTypes.GET_SDK_LIST, doGetSDKList);
    yield takeLatest(addSDKTypes.EDIT_SDK_SERVICE, doEditSDKList);
    yield takeLatest(addSDKTypes.DELETE_SDK_SERVICE, doDeleteSDKList);
    yield takeLatest(addSDKTypes.ADD_SDK_SERVICE, doCreateNewSDK);
}
